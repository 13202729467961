import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Input, Row} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { storeMenuDressing, getAllRestaurants, storePrinter, getPrinterById, getRestaurantMenuCategoriesWithItems } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { hexColorToInt } from "src/helpers/general";
import { getUserRestaurantId } from "src/helpers/backend_helper";

const AddPrinter = (props: any) => {
  const dispatch = useDispatch();

  const { items, success, loading, error } = useSelector((state: any) => state.printers);
  const { categories } = useSelector( (state: any) => state.menu);
  const [selectedCategory, setSelCategory] = useState(-1);
  const [menuItems, setMenuItems] = useState<any>([]);
  const [selectedItemIds, setSelectedItemIds] = useState<any>([]);
  const [printerType, setPrinterType] = useState("Local printer");

  useEffect(() => {
    if (props.open) {
      const userRestId = getUserRestaurantId()
      if (userRestId > 0) {
        dispatch(getRestaurantMenuCategoriesWithItems(userRestId));
      } else {
        if (props.selectedRestId != '') {
          dispatch(getRestaurantMenuCategoriesWithItems(props.selectedRestId));
        }
      }
    }
  }, [props.open, props.selectedRestId])

  useEffect(() => {    
    if (props.open && categories.length > 0) {
      if (categories.length > 0) {
        setSelCategory(parseInt(categories[0].id.toString()));
        setMenuItems(categories[0].items);
      } else {
        setSelCategory(-1);
        setMenuItems([]);
      }
    }
  }, [props.open, categories]);

  useEffect(() => {
    if (success) {
      props.onDrawerClose(false);
    }
  }, [success]);

  const handleAddPrinter = (values: any) => {
    dispatch(storePrinter({
      ...values,      
      RestaurantId: getUserRestaurantId() < 0 ? parseInt(props.selectedRestId || '1') : getUserRestaurantId(),
      items: selectedItemIds.join(","),
      ipAddress: !!values.ipAddress ? values.ipAddress : null,
      connectType: !!values.connectType ? values.connectType : null,
      port: !!values.port ? values.port : null
    }));
  };

  const onCategoryChange = (e: any) => {
    setSelCategory(e.target.value);
    setMenuItems(categories.length>0 ? (categories.filter((cat: any) => cat.id.toString() === e.target.value)[0]?.items || []):[]);
  }

  const onItemChange = (id: any) => {
    setSelectedItemIds((ids: any) => ids.indexOf(id) >= 0 ? ids.filter((id1: any) => id1 != id) : [...ids, id]);
  }

  const onItemChangeAll = (value: boolean) => {
    const selIds = menuItems.map((mi: any) => mi.id);
    setSelectedItemIds((ids: any) => !!value ? [...ids, ...selIds.filter((id: any) => ids.indexOf(id) < 0)] : ids.filter((id: any) => selIds.indexOf(id) < 0));
  }

  const onChangeItem = (value: string) => {
    setPrinterType(value)
  }

  return (
    <React.Fragment>
      <div className="add-license">
        <MetaTags>
          <title>Add New Printer</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-license-form" style={{ padding: 20, width: 'fit-content' }}>
          <AvForm
              onValidSubmit={(e: any, values: any) => {
                handleAddPrinter(values);
              }}
            >
              <Row className="align-items-center">
                  <Col md="12">
                    <div className="mb-3">                      
                      <AvField type="select" label="Printer type" name="printerType" value={printerType} onChange={(e: any) => onChangeItem(e.target.value)} >
                        <option value="Local printer"> Local printer </option>
                        <option value="Sunmi"> Sunmi </option>
                        <option value="xPrinter XP-80C"> xPrinter XP-80C </option>
                        <option value="Star mcPrint"> Star mcPrint </option>
                        <option value="Star TSP143"> Star TSP143 </option>
                      </AvField>
                    </div>
                  </Col>
              </Row>
              <Row className="align-items-center">
                  <Col md="12">
                    {printerType != "Local printer" && (
                      <div className="mb-3">
                        <AvField type="select" name="connectType" label="Connect type" >
                          <option value="" hidden></option>
                          <option value="LAN"> LAN </option>
                          <option value="USB"> USB </option>
                        </AvField>
                      </div>
                    )}
                  </Col>
              </Row>
              <Row className="align-items-center">
                  <Col md="12">
                    {printerType != "Local printer" && (
                      <div className="mb-3">
                        <AvField
                          name="ipAddress"
                          label="IP address"
                          type="text"
                          errorMessage="IP address required"
                          validate={{
                            required: { value: printerType != "Local printer" },
                          }}
                          defaultValue={""}
                        />
                      </div>
                    )}
                  </Col>
              </Row>
              <Row className="align-items-center">
                  <Col md="12">
                    {printerType != "Local printer" && (
                      <div className="mb-3">
                        <AvField
                          name="port"
                          label="port"
                          type="number"
                          errorMessage="port required"
                          validate={{
                            required: { value: printerType != "Local printer" },
                          }}
                          defaultValue={""}
                        />
                      </div>
                    )}
                  </Col>
              </Row>
              <Row className="align-items-center">
                  <Col md="12">
                    <div className="mb-3">
                      {printerType != "Local printer" ? (
                        <AvField type="select" label="Designation" name="designation" >
                          <option value="" hidden></option>
                          <option value="bill printer"> bill printer </option>
                          <option value="sub receipt printer"> sub receipt printer </option>
                        </AvField>
                      ) : (
                        <AvField type="text" label="Designation" name="designation"/>
                      )}
                    </div>
                  </Col>
              </Row>
              <Row className="align-items-center">
                  <Col md="12">
                    {printerType === "Local printer" && (
                      <div className="mb-3">
                        <AvField type="text" label="Actual name" name="ActualName"/>
                      </div>
                    )}
                  </Col>
              </Row>

              {printerType != "Local printer" && (
                <div className="d-flex flex-column mb-2 mt-4">
                <div className="d-flex flex-column">
                  <label>Select menu category</label>
                  <select value={selectedCategory} onChange={onCategoryChange}>
                    {categories.map((cat: any) => (
                      <option key={cat.id} value={cat.id}>{cat.Name}</option>
                    ))}
                  </select>
                </div>
                
                  <div className="d-flex flex-column mt-2">
                    <label>Menu items</label>
                    <div className="d-flex justify-content-between w-[100%]">
                      <span>Select/unselect all</span>
                      <input type="checkbox" onChange={(e) => onItemChangeAll(e.target.checked)} checked={menuItems.reduce((prev: boolean, item: any) => selectedItemIds.indexOf(item.id) >= 0 && prev, true)} />
                    </div>
                    {menuItems.map((item: any) => (
                      <div key={item.id} className="d-flex justify-content-between w-[100%]">
                        <span>{item.Name}</span>
                        <input type="checkbox" checked={selectedItemIds.indexOf(item.id) >= 0} onChange={() => onItemChange(item.id)} />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user" disabled={loading}>
                      {loading ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : (
                        <>Save</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddPrinter);
