import { MenuItemsActionTypes } from "./actionTypes";

//get all dmin by sitecode
export const getAllMenuItems = () => ({
  type: MenuItemsActionTypes.GET_ALL_MENU_ITEMS,
});

export const getAllMenuItemsSuccess = (categories: any) => ({
  type: MenuItemsActionTypes.GET_ALL_MENU_ITEMS_SUCCESS,
  payload: categories,
});


export const getCategoryMenuItems = (id: string) => ({
  type: MenuItemsActionTypes.GET_CATEGORY_MENU_ITEMS,
  payload: id
})

export const getCategoryMenuItemsSuccess = (items: any) => ({
  type: MenuItemsActionTypes.GET_CATEGORY_MENU_ITEMS_SUCCESS,
  payload: items,
});

export const getSupplierMenuItems = (id: string) => ({
  type: MenuItemsActionTypes.GET_SUPPLIER_MENU_ITEMS,
  payload: id
})

export const getSupplierMenuItemsSuccess = (items: any) => ({
  type: MenuItemsActionTypes.GET_SUPPLIER_MENU_ITEMS_SUCCESS,
  payload: items,
});

export const updateMenuItemById = (id: string, item: any, history: any) => ({
  type: MenuItemsActionTypes.UPDATE_MENU_ITEM_BY_ID,
  payload: {id, item, history}
})

export const updateMenuItemByIdSuccess = (item: any) => ({
  type: MenuItemsActionTypes.UPDATE_MENU_ITEM_BY_ID_SUCCESS,
  payload: item,
});


export const storeMenuItem = (item: any) => ({
  type: MenuItemsActionTypes.ADD_MENU_ITEM,
  payload: item,
});

export const storeMenuItemSuccess = (item: any) => ({
  type: MenuItemsActionTypes.ADD_MENU_ITEM_SUCCESS,
  payload: item,
});

export const deleteMenuItem = (id: string) => ({
  type: MenuItemsActionTypes.DELETE_MENU_ITEM_BY_ID,
  payload: id
});

export const deleteMenuItemSuccess = (id: string) => ({
  type: MenuItemsActionTypes.DELETE_MENU_ITEM_BY_ID_SUCCESS,
  payload: id
})

export const getMenuItemById = (id: string) => ({
  type: MenuItemsActionTypes.GET_MENU_ITEM_BY_ID,
  payload: id
})

export const getMenuItemByIdSuccess = (category: any) => ({
  type: MenuItemsActionTypes.GET_MENU_ITEM_BY_ID_SUCCESS,
  payload: category
})

export const menuItemApiCallError = (error: any) => ({
  type: MenuItemsActionTypes.API_RETURN_ERROR,
  payload: error
})

