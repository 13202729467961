import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { deleteMenuItem, deleteSubMenuItem, getMenuItemSubMenuItems, storeMenuItem, storeSubMenuItem } from 'src/store/actions';
import { hexColorToInt } from 'src/helpers/general';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { SpinnerCircular } from "spinners-react";
import { getMenuImageUrl } from '.';

export default function({row, selectedCategory, isHospitality, searchItemKey}: any) {
    const dispatch = useDispatch();
    const { items: menuItems, loading: loadingItems } = useSelector( (state: any) => state.menuItems);
    const [ selectedMenuItem, setSelMenuItem ] = useState(0);
    const { items: subMenuItems, loading: loadingSubMenuItems } = useSelector( (state: any) => state.subMenuItems);
    const menuItemAddFormRef = useRef();
    
    useEffect(() => {
        if (selectedMenuItem > 0) {
            dispatch(getMenuItemSubMenuItems(selectedMenuItem.toString()));
        }
    }, [selectedMenuItem]);

    const handleDeleteSubMenuItem = (item: any) => {
        dispatch(deleteSubMenuItem(item.id));
    }    

    const handleAddSubMenuItem = (values: any) => {
        if (selectedMenuItem > 0 && values.Name) {
            dispatch(storeSubMenuItem({
                ...values,
                Menu_Item_Id: selectedMenuItem,
                Collection_Price: parseFloat(values.Collection_Price || "0"),
                Delivery_Price: parseFloat(values.Delivery_Price || "0"),
                EatIn_Price: parseFloat(values.EatIn_Price || "0"),
                ForeColor: hexColorToInt('#ffffff'),
                BackColor: hexColorToInt('#333333'),
                BaseColor: hexColorToInt('#333333')
            }));
        }
    }

    const handleDeleteMenuItem = (item: any) => {
        dispatch(deleteMenuItem(item.id));
    }    

    const handleAddMenuItem = (values: any) => {
        if (selectedCategory && values.Name) {
            dispatch(storeMenuItem({
                ...values,
                Topping_Price: parseFloat(values.Topping_Price || "0"),
                Topping_Price2: parseFloat(values.Topping_Price2 || "0"),
                Topping_Price3: parseFloat(values.Topping_Price3 || "0"),
                Topping_Price4: parseFloat(values.Topping_Price4 || "0"),
                Topping_Price5: parseFloat(values.Topping_Price5 || "0"),
                Topping_Price6: parseFloat(values.Topping_Price6 || "0"),
                Topping_Price7: parseFloat(values.Topping_Price7 || "0"),
                Topping_Price8: parseFloat(values.Topping_Price8 || "0"),
                Topping_Price9: parseFloat(values.Topping_Price9 || "0"),
                Topping_Price610: parseFloat(values.Topping_Price10 || "0"),
                Category_Id: selectedCategory.id,
                Collection_Price: parseFloat(values.Collection_Price || "0"),
                Delivery_Price: parseFloat(values.Delivery_Price || "0"),
                EatIn_Price: parseFloat(values.EatIn_Price || "0"),
                ForeColor: hexColorToInt('#ffffff'),
                BackColor: hexColorToInt('#333333'),
                BaseColor: hexColorToInt('#333333'),
                item_type: parseInt(values.item_type),
                // PricePerKg: values.PricePerKg ? parseInt(values.PricePerKg) : 0,
                Stock: values.Stock ? parseInt(values.Stock) : 0,
                WeightDefinedProduct: values.PricePerKg && parseFloat(values.PricePerKg) > 0 ? true : false
            }));
        }
    }
    
    const handleMenuItemClick = (item: any) => {
        if (selectedMenuItem === item.id ) {
            setSelMenuItem(0)
        } else if (item.SubMenu) {
            setSelMenuItem(item.id);
        }
    }

    const renderSubMenuItems = (menuItem: any) => {
        if (selectedMenuItem !== menuItem.id) {
            return <></>
        }
        if (loadingSubMenuItems) {
            return (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100px" }}
                    >
                    <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
                </div>
            )
        }
        return (
            <div style={{paddingLeft: 10, backgroundColor: 'rgba(50,50,50,0.2)'}}>
                {subMenuItems.map((subItem: any) => (
                    <Row key={subItem.id} className="menu-item">
                        <Col md="2" className="menu-item-name">
                            {subItem.Name}
                        </Col>
                        <Col md="1" className="menu-item-name">
                            Position: {subItem.Position}
                        </Col>
                        <Col md="2" className="menu-item-name">
                            Collection price: {subItem.Collection_Price}
                        </Col>
                        {isHospitality && (
                            <>
                                <Col md="2" className="menu-item-name">
                                    Delivery price: {subItem.Delivery_Price}
                                </Col>
                                <Col md="2" className="menu-item-name">
                                    Eatin price: {subItem.EatIn_Price}
                                </Col>
                            </>
                        )}
                        <Col md="1">
                            <Link className="text-success" to={`/menu/sub-menu-items/${subItem.id}`}>
                                <i
                                className="mdi mdi-pencil font-size-18"
                                id="edittooltip"
                                ></i>
                            </Link>
                            <Link className="text-success" to="#">
                                <i
                                className="mdi mdi-delete font-size-18"
                                id="deletetooltip"
                                onClick={() => handleDeleteSubMenuItem(subItem)}
                                ></i>
                            </Link>
                        </Col>
                    </Row>
                ))}

                <div key='add-new' className="flex flex-row menu-item">
                    <AvForm
                        onValidSubmit={(e: any, values: any) => {
                            handleAddSubMenuItem(values);
                        }}
                        ref={menuItemAddFormRef}
                    >
                        <Row className="align-items-end">
                            <Col md="2">
                                <div className="">
                                <AvField
                                    name="Name"
                                    label="Name"
                                    type="text"
                                    errorMessage="Name required"
                                />
                                </div>
                            </Col>
                            <Col md="1">
                                <div className="">
                                <AvField
                                    name="Position"
                                    label="Position"
                                    type="number"
                                    errorMessage="Position required"
                                    defaultValue={subMenuItems.length + 1}
                                />
                                </div>
                            </Col>
                            <Col md="2">
                                <div className="">
                                    <AvField
                                        name="Collection_Price"
                                        label="Collection Price"
                                        type="text"
                                        errorMessage="Collection price required"
                                    />
                                </div>
                            </Col>
                            {isHospitality && (
                                <>
                                    <Col md="2">
                                        <div className="">
                                        <AvField
                                            name="Delivery_Price"
                                            label="Delivery Price"
                                            type="text"
                                            errorMessage="Delivery price required"
                                        />
                                        </div>
                                    </Col>
                                    <Col md="2">
                                        <div className="">
                                        <AvField
                                            name="EatIn_Price"
                                            label="EatIn Price"
                                            type="text"
                                            errorMessage="EatIn price required"
                                        />
                                        </div>
                                    </Col>
                                </>
                            )}                            
                            
                            <Col md="1">
                                <button type="submit" className="btn btn-success save-user" disabled={loadingItems}>
                                    {loadingItems ? (
                                        <SpinnerCircular
                                            size={30}
                                            thickness={150}
                                            speed={150}
                                            color="#36ad47"
                                            secondaryColor="rgba(0, 0, 0, 0.44)"
                                        />
                                    ) : (
                                        <>Add</>
                                    )}
                                </button>
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            </div>
        )
    }

    
    if (row.id !== selectedCategory.id) {
        return <></>
    }

    if (loadingItems) {
        return (
          <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100px" }}
            >
              <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
            </div>
        )
    }
    
    const filteredItems = menuItems.filter((mi: any) => 
        mi.Name.toLowerCase().includes(searchItemKey.toLowerCase()) || 
        (mi.BarCode && mi.BarCode.toLowerCase().includes(searchItemKey.toLowerCase())))

    return (
        <div className="d-flex flex-column">
            { filteredItems.map((item: any) => (
                <div key={item.id}>
                    <Row className="menu-item" style={{cursor: item.SubMenu ? "pointer" : "inherit"}} onClick={() => handleMenuItemClick(item)}>
                        <Col md="1">
                            <img src={getMenuImageUrl(item.BackImage)} width={50} height={50} />
                        </Col>
                        <Col md="2" className="menu-item-name">
                            <span>{item.Name}</span> <b style={{fontStyle: 'italic'}}>{item.SubMenu ? "S" : ""}</b>
                        </Col>
                        {selectedCategory && selectedCategory.CategoryType === 1 ? (
                            <>
                            <Col md="8">
                                <Row>
                                    <Col md="2" className="menu-item-name">
                                        Price 1: {item.Topping_Price}
                                    </Col>
                                    <Col md="2" className="menu-item-name">
                                        Price 2: {item.Topping_Price2}
                                    </Col>
                                    <Col md="2" className="menu-item-name">
                                        Price 3: {item.Topping_Price3}
                                    </Col>
                                    <Col md="2" className="menu-item-name">
                                        Price 4: {item.Topping_Price4}
                                    </Col>
                                    <Col md="2" className="menu-item-name">
                                        Price 5: {item.Topping_Price5}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="2" className="menu-item-name">
                                        Price 6: {item.Topping_Price6}
                                    </Col>
                                    <Col md="2" className="menu-item-name">
                                        Price 7: {item.Topping_Price7}
                                    </Col>
                                    <Col md="2" className="menu-item-name">
                                        Price 8: {item.Topping_Price8}
                                    </Col>
                                    <Col md="2" className="menu-item-name">
                                        Price 9: {item.Topping_Price9}
                                    </Col>
                                    <Col md="2" className="menu-item-name">
                                        Price 10: {item.Topping_Price10}
                                    </Col>
                                </Row>
                            </Col>
                            
                            </>
                        ) : (
                            <>
                                <Col md="1" className="menu-item-name">
                                    Position: {item.Position}
                                </Col>
                                <Col md="2" className="menu-item-name">
                                    Collection price: {item.Collection_Price}
                                </Col>
                                {isHospitality && (
                                    <>
                                        <Col md="2" className="menu-item-name">
                                            Delivery price: {item.Delivery_Price}
                                        </Col>
                                        <Col md="2" className="menu-item-name">
                                            Eatin price: {item.EatIn_Price}
                                        </Col>
                                    </>
                                )}
                                {!isHospitality && (
                                    <>
                                        <Col md="2" className="menu-item-name">
                                            Barcode: {item.BarCode}
                                        </Col>
                                        {/* <Col md="2">
                                            PricePerKg: {item.PricePerKg}
                                        </Col> */}
                                        <Col md="2" className="menu-item-name">
                                            Stock: {item.Stock}
                                        </Col>
                                    </>
                                )}
                            </>
                        )}
                        <Col md="1">
                            <Link className="text-success" to={`/menu/items/${item.id}`}>
                                <i
                                className="mdi mdi-pencil font-size-18"
                                id="edittooltip"
                                ></i>
                            </Link>
                            <Link className="text-success" to="#">
                                <i
                                className="mdi mdi-delete font-size-18"
                                id="deletetooltip"
                                onClick={() => handleDeleteMenuItem(item)}
                                ></i>
                            </Link>
                        </Col>
                    </Row>
                    {renderSubMenuItems(item)}
                </div>
            ))}
            <div key='add-new' className="flex flex-row menu-item">
                <AvForm
                    onValidSubmit={(e: any, values: any) => {
                        handleAddMenuItem(values);
                    }}
                    ref={menuItemAddFormRef}
                >
                    <Row className="align-items-end">
                        <Col md="1">
                            <div className="">
                            <AvField
                                name="Name"
                                label="Name"
                                placeholder="Name"
                                type="text"
                                errorMessage="Name required"
                            />
                            </div>
                        </Col>
                        {selectedCategory && selectedCategory.CategoryType === 1 ? (
                            <>
                                <Col md="1">
                                    <AvField
                                        name="Topping_Price"
                                        label="Price1"
                                        type="text"
                                    />
                                </Col>
                                <Col md="1">
                                    <AvField
                                        name="Topping_Price2"
                                        label="Price2"
                                        type="text"
                                    />
                                </Col>
                                <Col md="1">
                                    <AvField
                                        name="Topping_Price3"
                                        label="Price3"
                                        type="text"
                                    />
                                </Col>
                                <Col md="1">
                                    <AvField
                                        name="Topping_Price4"
                                        label="Price4"
                                        type="text"
                                    />
                                </Col>
                                <Col md="1">
                                    <AvField
                                        name="Topping_Price5"
                                        label="Price5"
                                        type="text"
                                    />
                                </Col>
                                <Col md="1">
                                    <AvField
                                        name="Topping_Price6"
                                        label="Price6"
                                        type="text"
                                    />
                                </Col>
                                <Col md="1">
                                    <AvField
                                        name="Topping_Price7"
                                        label="Price7"
                                        type="text"
                                    />
                                </Col>
                                <Col md="1">
                                    <AvField
                                        name="Topping_Price8"
                                        label="Price8"
                                        type="text"
                                    />
                                </Col>
                                <Col md="1">
                                    <AvField
                                        name="Topping_Price9"
                                        label="Price9"
                                        type="text"
                                    />
                                </Col>
                                <Col md="1">
                                    <AvField
                                        name="Topping_Price10"
                                        label="Price10"
                                        type="text"
                                    />
                                </Col>
                            </>
                        ) : (
                            <>                                            
                                <Col md="1">
                                    <div className="">
                                    <AvField
                                        name="Position"
                                        label="Position"
                                        placeholder="Position"
                                        type="number"
                                        errorMessage="Position required"
                                        defaultValue={menuItems.length + 1}
                                    />
                                    </div>
                                </Col>
                                <Col md="2">
                                    <div className="">
                                    <AvField
                                        name="Collection_Price"
                                        label="Collection Price"
                                        placeholder="Collection Price"
                                        type="text"
                                        errorMessage="Collection price required"
                                    />
                                    </div>
                                </Col>
                                {isHospitality && (
                                    <>
                                        <Col md="2">
                                            <div className="">
                                            <AvField
                                                name="Delivery_Price"
                                                label="Delivery Price"
                                                placeholder="Delivery Price"
                                                type="text"
                                                errorMessage="Delivery price required"
                                            />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="">
                                            <AvField
                                                name="EatIn_Price"
                                                label="EatIn Price"
                                                placeholder="EatIn Price"
                                                type="text"
                                                errorMessage="EatIn price required"
                                            />
                                            </div>
                                        </Col>
                                    </>
                                )}
                                {!isHospitality && (
                                    <>
                                    <Col md="1">
                                            <div className="">
                                            <AvField
                                                name="OfferPrice"
                                                label="Offer Price"
                                                placeholder="Offer Price"
                                                type="text"
                                            />
                                            </div>
                                        </Col>
                                        <Col md="1">
                                            <div className="">
                                            <AvField
                                                name="OfferQuantity"
                                                label="Offer Quantity"
                                                placeholder="Offer Quantity"
                                                type="text"
                                            />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="">
                                            <AvField
                                                name="BarCode"
                                                label="Barcode"
                                                placeholder="Barcode"
                                                type="text"
                                            />
                                            </div>
                                        </Col>
                                        {/* <Col md="2">
                                            <div className="">
                                            <AvField
                                                name="PricePerKg"
                                                label="PricePerKg"
                                                placeholder="PricePerKg"
                                                type="number"
                                            />
                                            </div>
                                        </Col> */}
                                        <Col md="1">
                                            <div className="">
                                            <AvField
                                                name="Stock"
                                                label="Stock"
                                                placeholder="Stock"
                                                type="number"
                                            />
                                            </div>
                                        </Col>
                                    </>
                                )}
                                <Col md="2">
                                    <div className="">
                                    <AvField
                                        name="SubMenu"
                                        label="Has sub menu"
                                        type="checkbox"
                                        defaultValue={false}
                                    />
                                    </div>
                                </Col>
                            </>
                        )}
                        <Col md="1">
                            <button type="submit" className="btn btn-success save-user" disabled={loadingItems}>
                                {loadingItems ? (
                                    <SpinnerCircular
                                        size={30}
                                        thickness={150}
                                        speed={150}
                                        color="#36ad47"
                                        secondaryColor="rgba(0, 0, 0, 0.44)"
                                    />
                                ) : (
                                    <>Add</>
                                )}
                            </button>
                        </Col>
                    </Row>
                </AvForm>
            </div>
        </div>
    )
}