import { RestaurantTypes } from "./actionTypes";

export const INIT_STATE: any = {
  restaurants: [],
  singleRestaurant: {},
  error: {},
  loading: false,
  success: false,
};

const restaurant = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case RestaurantTypes.GET_ALL_RESTAURANTS:
      return {
        ...state,
        loading: true,
      };

    case RestaurantTypes.GET_ALL_RESTAURANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        restaurants: action.payload,
      };

    case RestaurantTypes.GET_ALL_RESTAURANTS_BY_MANAGER:
      return {
        ...state,
        loading: true,
      };

    case RestaurantTypes.GET_ALL_RESTAURANTS_BY_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        restaurants: action.payload,
      };

    case RestaurantTypes.GET_RESTAURANT_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case RestaurantTypes.GET_RESTAURANT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleRestaurant: action.payload,
      };

    case RestaurantTypes.UPDATE_RESTAURANT_BY_DOCID:
      return {
        ...state,
        loading: true,
      };

    case RestaurantTypes.UPDATE_RESTAURANT_BY_DOCID_SUCCESS:
      return action.payload.fromAdmin ? {
        ...state,
        loading: false,
        restaurants: state.restaurants.map((r: any) => r.id !== action.payload.restaurant.id ? r : ({...r, ...action.payload.restaurant}) )
      } : {
        ...state,
        loading: false,
        singleRestaurant: action.payload.restaurant,
      };

    case RestaurantTypes.ADD_RESTAURANT:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case RestaurantTypes.ADD_RESTAURANT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        restaurants: [...state.restaurants, action.payload],
      };

    default:
      return state;
  }
};

export default restaurant;
