import { call, put, takeEvery } from "redux-saga/effects";
import { CardTerminalsActionTypes } from "./actionTypes";
import { DeleteResponse, IResponse } from "../../interfaces/general";
import { deleteCardTerminalSuccess, getRestaurantCardTerminalsSuccess, getCardTerminalByIdSuccess, cardTerminalApiCallError, storeCardTerminalSuccess, updateCardTerminalByIdSuccess } from "./actions";

import { addCardTerminalAsync, deleteCardTerminalByIdAsync, getAllCardTerminalsAsync, getCardTerminalByIdAsync, updateCardTerminalByIdAsync } from "../../helpers/backend_helper";

function* getRestaurantCardTerminals({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getAllCardTerminalsAsync, id);
    console.log('response =>', response)
    if(response.success) {
      yield put(getRestaurantCardTerminalsSuccess(response.result.sort((a:any, b:any) => a.id-b.id)));
    } 
  } catch (error) {
    console.log(error)
    yield put(cardTerminalApiCallError(error))
  }
}

function* updateCardTerminal({ payload: {id, item, history} }: any) {
  try {
    const response: IResponse = yield call(updateCardTerminalByIdAsync, id, item);
    if(response.success) {
      yield put(updateCardTerminalByIdSuccess(response.result));
      if (history) {
        history.push('/cardTerminals')
      }
    }
  } catch (error) {
    console.log(error)
    yield put(cardTerminalApiCallError(error))
  }
}

function* addCardTerminal({ payload: menuDressing }: any) {
  try {
    const response: IResponse = yield call(addCardTerminalAsync, menuDressing);
    if(response.success) {
      yield put(storeCardTerminalSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(cardTerminalApiCallError(error))
  }
}

function* getCardTerminalById({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getCardTerminalByIdAsync, id);
    if(response.success) {
      yield put(getCardTerminalByIdSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(cardTerminalApiCallError(error))
  }
}

function* deleteCardTerminal({ payload: id }: any) {
  try {
    const response: DeleteResponse = yield call(deleteCardTerminalByIdAsync, id);
    if(response.success) {
      yield put(deleteCardTerminalSuccess(id));
    }
  } catch (error) {
    console.log(error)
    yield put(cardTerminalApiCallError(error))
  }
}

function* restaurantSaga() {
  yield takeEvery(CardTerminalsActionTypes.GET_RESTAURANT_CARD_TERMINALS, getRestaurantCardTerminals)
  yield takeEvery(CardTerminalsActionTypes.UPDATE_CARD_TERMINAL_BY_ID, updateCardTerminal);
  yield takeEvery(CardTerminalsActionTypes.DELETE_CARD_TERMINAL_BY_ID, deleteCardTerminal);
  yield takeEvery(CardTerminalsActionTypes.ADD_CARD_TERMINAL, addCardTerminal)
  yield takeEvery(CardTerminalsActionTypes.GET_CARD_TERMINAL_BY_ID, getCardTerminalById);
}

export default restaurantSaga;


