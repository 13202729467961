import { call, put, takeEvery } from "redux-saga/effects";
import { MenuToppingsActionTypes } from "./actionTypes";
import { DeleteResponse, IResponse } from "../../interfaces/general";
import { getAllMenuToppingsSuccess, storeMenuToppingSuccess, getMenuToppingByIdSuccess, menuToppingApiCallError, deleteMenuToppingSuccess, updateMenuToppingByIdSuccess } from "./actions";

import { addMenuToppingAsync, deleteMenuToppingByIdAsync, getAllMenuToppingsAsync, getMenuToppingByIdAsnyc, updateMenuToppingByIdAsync } from "../../helpers/backend_helper";

function* getAllMenuToppings() {
  try {
    const response: IResponse = yield call(getAllMenuToppingsAsync);
    console.log('response =>', response)
    if(response.success) {
      yield put(getAllMenuToppingsSuccess(response.result.sort((a:any, b:any) => b.id-a.id)));
    } 
  } catch (error) {
    console.log(error)
    yield put(menuToppingApiCallError(error))
  }
}

function* updateMenuTopping({ payload: {id, item, history} }: any) {
  try {
    const response: IResponse = yield call(updateMenuToppingByIdAsync, id, item);
    if(response.success) {
      yield put(updateMenuToppingByIdSuccess(response.result));
      if (history) {
        history.push('/menu/toppings')
      }
    }
  } catch (error) {
    console.log(error)
    yield put(menuToppingApiCallError(error))
  }
}

function* addMenuTopping({ payload: menuTopping }: any) {
  try {
    const response: IResponse = yield call(addMenuToppingAsync, menuTopping);
    if(response.success) {
      yield put(storeMenuToppingSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(menuToppingApiCallError(error))
  }
}

function* getMenuToppingById({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getMenuToppingByIdAsnyc, id);
    if(response.success) {
      yield put(getMenuToppingByIdSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(menuToppingApiCallError(error))
  }
}

function* deleteMenuTopping({ payload: id }: any) {
  try {
    const response: DeleteResponse = yield call(deleteMenuToppingByIdAsync, id);
    if(response.success) {
      yield put(deleteMenuToppingSuccess(id));
    }
  } catch (error) {
    console.log(error)
    yield put(menuToppingApiCallError(error))
  }
}

function* restaurantSaga() {
  yield takeEvery(MenuToppingsActionTypes.GET_ALL_MENU_TOPPINGS, getAllMenuToppings)
  yield takeEvery(MenuToppingsActionTypes.UPDATE_MENU_TOPPING_BY_ID, updateMenuTopping);
  yield takeEvery(MenuToppingsActionTypes.DELETE_MENU_TOPPING_BY_ID, deleteMenuTopping);
  yield takeEvery(MenuToppingsActionTypes.ADD_MENU_TOPPING, addMenuTopping)
  yield takeEvery(MenuToppingsActionTypes.GET_MENU_TOPPING_BY_ID, getMenuToppingById);
}

export default restaurantSaga;


