import { MigrationTypes } from "./actionTypes";

export const INIT_STATE: any = {
  migrations: [],
  singleMigration: {},
  error: {},
  loading: false,
  loadingSingle: false,
  success: false,
};

const restaurant = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case MigrationTypes.GET_ALL_MIGRATIONS:
      return {
        ...state,
        loading: true,
      };

    case MigrationTypes.GET_ALL_MIGRATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        migrations: action.payload,
      };

    case MigrationTypes.GET_MIGRATION_BY_ID:
      return {
        ...state,
        loadingSingle: true,
      };

    case MigrationTypes.GET_MIGRATION_BY_ID_SUCCESS:
      return {
        ...state,
        loadingSingle: false,
        singleMigration: action.payload,
        migrations: state.migrations.map((m: any) => m.id === parseInt(action.payload.id.toString()) ? action.payload : m)
      };

    case MigrationTypes.DELETE_MIGRATION_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case MigrationTypes.DELETE_MIGRATION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        migrations: state.migrations.filter((m: any) => m.id != parseInt(action.payload.toString()))
      };

    case MigrationTypes.ADD_MIGRATION:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case MigrationTypes.ADD_MIGRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        migrations: [...state.migrations, action.payload],
        migrationAdded: parseInt(action.payload.id.toString())
      };

    default:
      return state;
  }
};

export default restaurant;
