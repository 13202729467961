import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useParams } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getAllKDScreens, getRestaurantKDScreens, storeMenuCategory, storeRestaurant } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { getUserRestaurantId } from "src/helpers/backend_helper";
import { hexColorToInt } from "src/helpers/general";

const AddMenuCategory = (props: any) => {
  const dispatch = useDispatch();
  const { success, loading } = useSelector((state: any) => state.menu);
  const { screens } = useSelector((state: any) => state.kitchenDisplayScreens);
  const { currentUser } = useSelector((state: any) => state.login);
  const formRef = useRef<any>();
  const [categoryType, setCategoryType] = useState(0);
  const [linkedCategories, setLinkedCategories] = useState<any>([]);

  useEffect(() => {
    if (props.open && formRef.current) {
      formRef.current.reset();
    }
    if (props.open) {
      dispatch(getRestaurantKDScreens(getUserRestaurantId() > 0 ? getUserRestaurantId() : props.selectedRestId));
    }
  }, [props.open])

  useEffect(() => {
    if (success) {
      props.onDrawerClose(false);
    }
  }, [success]);

  const validateToppingGid = (value: any, ctx: any, input: number) => {
    if (parseInt(value.toString()) < 0) {
      return false;
    }
    if (parseInt(value.toString()) > 0 && props.toppingIds.indexOf(parseInt(value.toString())) >= 0) {
      return false;
    }
    return true;
  }

  const handleAddItem = (values: any) => {
    dispatch(storeMenuCategory({
      ...values,
      RestaurantId: getUserRestaurantId() < 0 ? parseInt(props.selectedRestId || '1') : getUserRestaurantId(),
      ForeColor: hexColorToInt(values['ForeColor']),
      BackColor: hexColorToInt(values['BackColor']),
      BaseColor: hexColorToInt(values['BaseColor']),
      ToppingGroupId: parseInt(values.ToppingGroupId),
      CategoryType: values.CategoryType ? parseInt(values.CategoryType) : 0,
      LinkedCategoryId: linkedCategories.join(","),
      LinkedKitchenDisplayScreenId: values.LinkedKitchenDisplayScreenId ? parseInt(values.LinkedKitchenDisplayScreenId) : 0
    }));
  };

  const onTypeChange = (e: any) => {
    setCategoryType(parseInt(e.target.value));
  }

  const onLinkedCategoryChangeAll = (value: any) => {
    setLinkedCategories(value ? props.linkingCategories.map((cat: any) => cat.id) : []);
  }

  const onLinkedCategoryChange = (id: any) => {
    setLinkedCategories((ids: any) => ids.indexOf(id) >= 0 ? ids.filter((id1: any) => id1 != id) : [...ids, id]);
  }

  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Add New Category</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-admin-form" style={{ padding: 20 }}>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleAddItem(values);
            }}
            ref={formRef}
          >
            <div className="add-company-form-cotainer">
            <Row className="align-items-end">
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Name"
                      label="Name"
                      type="text"
                      errorMessage="Name required"
                      validate={{
                        required: { value: true },
                      }}
                      defaultValue={""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="DisableLabelPrinting"
                      label="DisableLabelPrinting"
                      type="checkbox"
                      defaultValue={false}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Position"
                      label="Position"
                      type="number"
                      errorMessage="Position"
                      validate={{
                        required: { value: true },
                      }}
                      defaultValue={props.noOfCategories+1}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="PrintPosition"
                      label="Print position"
                      type="number"
                      errorMessage="PrintPosition"
                      validate={{
                        required: { value: true },
                      }}
                      defaultValue={props.noOfCategories+1}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="ForeColor"
                      label="Foreground color"
                      type="color"
                      errorMessage="Invalid foreground color"
                      defaultValue={"#ffffff"}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="BackColor"
                      label="Background color"
                      type="color"
                      errorMessage="Invalid background color"
                      defaultValue={"#333333"}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="BaseColor"
                      label="Base color"
                      type="color"
                      errorMessage="Invalid base color"
                      defaultValue={"#333333"}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="FontSize"
                      label="Font size"
                      type="number"
                      errorMessage="Font size"
                      validate={{
                        required: { value: true },
                      }}
                      defaultValue={20}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="LineAfterCat"
                      label="LineAfterCat"
                      type="checkbox"
                      errorMessage="Invalid LineAfterCat"
                      defaultValue={false}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="ForWetItems"
                      label="ForWetItems"
                      type="checkbox"
                      errorMessage="Invalid ForWetItems"
                      defaultValue={false}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="ForDryItems"
                      label="ForDryItems"
                      type="checkbox"
                      errorMessage="Invalid ForDryItems"
                      defaultValue={false}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="AddQuantityToLineItem"
                      label="AddQuantityToLineItem"
                      type="checkbox"
                      errorMessage="Invalid AddQuantityToLineItem"
                      defaultValue={false}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="d-flex flex-column">
                    <label>Linked categories</label>
                    <div className="d-flex justify-content-between w-[100%]">
                      <span>Select/unselect all</span>
                      <input type="checkbox" onChange={(e) => onLinkedCategoryChangeAll(e.target.checked)} checked={props.linkingCategories.reduce((prev: boolean, item: any) => linkedCategories.indexOf(item.id) >= 0 && prev, true)} />
                    </div>
                    {props.linkingCategories.map((cat: any) => (
                      <div key={cat.id} className="d-flex justify-content-between w-[100%]">
                        <span>{cat.Name}</span>
                        <input type="checkbox" checked={linkedCategories.indexOf(cat.id) >= 0} onChange={() => onLinkedCategoryChange(cat.id)} />
                      </div>
                    ))}
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="ExcludeFromDiscount"
                      label="ExcludeFromDiscount"
                      type="checkbox"
                      errorMessage="Invalid ExcludeFromDiscount"
                      defaultValue={false}
                    />
                  </div>
                </Col>
              </Row>
              
              <Row className="mt-3">
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="CategoryType"
                      label="Type"
                      placeholder="Type"
                      type="select"
                      value={0}
                      onChange={onTypeChange}
                    >
                      <option value={0}>Standard</option>
                      <option value={1}>Toppings</option>
                      <option value={2}>Dressings</option>
                      <option value={3}>Special Instructions</option>
                      <option value={4}>Pop Up</option>
                      <option value={5}>Miscellaneous</option>
                    </AvField>
                  </div>
                </Col>
                {categoryType === 1 && (
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="ToppingGroupId"
                        label="ToppingGroupId"
                        type="number"
                        defaultValue={"1"}
                        errorMessage="Invalid input or topping group id already exists"
                        validate={{
                          validateToppingGid,
                          max: {value: 5, errorMessage: "max is 5"},
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                )}
              </Row>

              <Row className="mt-3">
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="LinkedKitchenDisplayScreenId"
                      label="Linked kitchen display screen"
                      placeholder="LinkedKitchenDisplayScreenId"
                      type="select"
                      value={0}
                      onChange={onTypeChange}
                    >
                      <option value={0}>None</option>
                      {screens.map((screen: any) => (
                        <option key={screen.id} value={screen.id}>{screen.Name}</option>
                      ))}
                    </AvField>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user update-btn" disabled={loading}>
                      {loading ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : (
                        <>Save</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddMenuCategory);
