export enum MigrationTypes {
  GET_ALL_MIGRATIONS = "GET_ALL_MIGRATIONS",
  GET_ALL_MIGRATIONS_SUCCESS = 'GET_ALL_MIGRATIONS_SUCCESS',

  GET_MIGRATION_BY_ID = 'GET_MIGRATION_BY_ID',
  GET_MIGRATION_BY_ID_SUCCESS = 'GET_MIGRATION_BY_ID_SUCCESS',


  DELETE_MIGRATION_BY_ID = 'DELETE_MIGRATION_BY_ID',
  DELETE_MIGRATION_BY_ID_SUCCESS = 'DELETE_MIGRATION_BY_ID_SUCCESS',

  ADD_MIGRATION = 'ADD_MIGRATION',
  ADD_MIGRATION_SUCCESS = 'ADD_MIGRATION_SUCCESS'
}
