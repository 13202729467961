import { OrderTypes } from "./actionTypes";

export const INIT_STATE: any = {
  orderDetails: [],
  error: {},
  loading: false,
  success: false,
};

const order = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case OrderTypes.GET_ORDER_DETAIL_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case OrderTypes.GET_ORDER_DETAIL_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        orderDetails: action.payload,
      };

    default:
      return state;
  }
};

export default order;
