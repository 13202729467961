import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { BookingActionTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import {deleteBookingSuccess, getBookingByIdSuccess, getRestaurantBookingsSuccess, storeBookingSuccess, updateBookingByIdSuccess} from "./actions";

import { deleteBookingByIdAsync, getBookingByIdAsync, getRestaurantBookingsAsync, getUserRestaurantId, storeBookingAsync, updateBookingAsync } from "../../helpers/backend_helper";

function* getAllBookings({ payload: restaurantId}: any) {
  try {
    let response: IResponse;
    response = yield call(getRestaurantBookingsAsync, restaurantId);
    if(response.success) {
      yield put(getRestaurantBookingsSuccess(response.result.sort((a:any, b:any) => b.id-a.id)));
    } 
  } catch (error) {
    console.log(error)
  }
}

function* storeBooking({ payload: booking }: any) {
  try {
    const response: IResponse = yield call(storeBookingAsync, booking);
    if(response.success) {
      yield put(storeBookingSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
  }
}
function* updateBooking({ payload: {id, item, history} }: any) {
  try {
    const response: IResponse = yield call(updateBookingAsync, id, item);
    if(response.success) {
      yield put(updateBookingByIdSuccess(response.result));
      if (history) {
        history.push('/bookings')
      }
    }
  } catch (error) {
    console.log(error)
  }
}

function* getBookingById({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getBookingByIdAsync, id);
    if(response.success) {
      yield put(getBookingByIdSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
  }
}

function* deleteBookingById({ payload: id }: any) {
  try {
    const response: IResponse = yield call(deleteBookingByIdAsync, id);
    if(response.success) {
      yield put(deleteBookingSuccess(id));
    }
  } catch (error) {
    console.log(error)
  }
}

function* orderSaga() {
  yield takeEvery(BookingActionTypes.GET_RESTAURANT_BOOKINGS, getAllBookings)
  yield takeEvery(BookingActionTypes.STORE_BOOKING, storeBooking)
  yield takeEvery(BookingActionTypes.GET_BOOKING_BY_ID, getBookingById)
  yield takeEvery(BookingActionTypes.UPDATE_BOOKING_BY_ID, updateBooking)
  yield takeEvery(BookingActionTypes.DELETE_BOOKING_BY_ID, deleteBookingById)
}

export default orderSaga;


