import { OrderTypes } from "./actionTypes";

export const INIT_STATE: any = {
  orders: [],
  totalCount: 0,
  singleOrder: {},
  error: {},
  loading: false,
  success: false,
};

const order = (state = INIT_STATE, action: any) => {
  switch (action.type) {

    case OrderTypes.GET_ALL_ORDERS:
      return {
        ...state,
        loading: true,
      };

    case OrderTypes.GET_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload.orders,
        totalCount: action.payload.totalCount
      };

    default:
      return state;
  }
};

export default order;
