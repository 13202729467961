import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getSupplierMenuItems, storePurchaseOrder } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { getUserRestaurantId } from "src/helpers/backend_helper";

const AddPurchaseOrder = (props: any) => {
  const dispatch = useDispatch();

  const { items: menuItems } = useSelector((state: any) => state.menuItems);
  const { singleItem, success, loading, error } = useSelector((state: any) => state.purchaseOrders);
  const [supplierId, setSupplierId] = useState(0);

  useEffect(() => {
    if (supplierId > 0) {
      dispatch(getSupplierMenuItems(supplierId.toString()));
    }
  }, [supplierId])

  useEffect(() => {
    if (success) {
      props.onDrawerClose(false);
    }
  }, [success]);

  const handleAddPurchaseOrder = (values: any) => {
    dispatch(storePurchaseOrder({
      RestaurantId: getUserRestaurantId(),
      SupplierId: parseInt(values.SupplierId),
      MenuItemId: parseInt(values.MenuItemId),
      quantity: parseInt(values.quantity)
    }));
  };

  return (
    <React.Fragment>
      <div className="add-license">
        <MetaTags>
          <title>Add New Purchase Order</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-license-form" style={{ padding: 20, width: 'fit-content' }}>
          <AvForm
              onValidSubmit={(e: any, values: any) => {
                handleAddPurchaseOrder(values);
              }}
            >
              <Row className="align-items-center">
                  <Col md="12">
                    <div className="mb-3">
                      <AvField
                        name="SupplierId"
                        label="Supplier"
                        type="select"
                        value={supplierId}
                        errorMessage="Supplier required"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={(e: any) => setSupplierId(parseInt(e.target.value))}
                      >
                        <option key="0" value={0}>Select supplier</option>
                        {props.suppliers.map((sup: any) => (
                          <option key={sup.id} value={sup.id} >{sup.supplierName}</option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
              </Row>
              <Row className="align-items-center">
                  <Col md="12">
                    <div className="mb-3">
                      <AvField
                        name="MenuItemId"
                        label="Menu item"
                        type="select"
                        value={""}
                        errorMessage="Menu item required"
                        validate={{
                          required: { value: true },
                        }}
                      >
                        <option key="0" value={0}>Select menu item</option>
                        {menuItems.map((menuItem: any) => (
                          <option key={menuItem.id} value={menuItem.id} >{menuItem.Name}</option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
              </Row>
              <Row className="align-items-center">
                  <Col md="12">
                    <div className="mb-3">
                      <AvField
                        name="quantity"
                        label="quantity"
                        type="number"
                        defaultValue={"1"}
                      />
                    </div>
                  </Col>
              </Row>

              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user" disabled={loading}>
                      {loading ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : (
                        <>Save</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddPurchaseOrder);
