import React, { useEffect, useState } from "react";
import { withRouter, useParams, Link, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getMenuInstructionById, updateMenuInstructionById } from "../../store/actions";
import { Col, Container, Row } from "reactstrap";
import { SpinnerCircular } from "spinners-react";
import { hexColorToInt, intColorToHex } from "src/helpers/general";


const EditMenuInstruction = (props: any) => {
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const history = useHistory();

  const { singleItem, loading } = useSelector((state: any) => state.menuInstructions);

  useEffect(() => {
    dispatch(getMenuInstructionById(id));
  }, []);

  const handleEditItem = (values: any) => {
    dispatch(updateMenuInstructionById(id, {
      ...values,
      ForeColor: hexColorToInt(values['ForeColor']),
      BackColor: hexColorToInt(values['BackColor']),
      BaseColor: hexColorToInt(values['BaseColor'])
    }, history));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <div className="d-flex flex-row col-md-6">
              <div className="mb-3">                
                <Link to="/menu/instructions">
                    <i className="fa fa-arrow-left me-1"></i>
                  Menu special instructions
                </Link>
              </div>

              <div className="mb-3" style={{marginLeft: 20}}>
                <h5>Edit menu instructions</h5>
              </div>
            </div>
          </Row>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "20vh" }}
              >
                <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
              </div>
            ) : (
              <AvForm
                onValidSubmit={(e: any, values: any) => {
                  handleEditItem(values);
                }}
              >
                <Row className="align-items-center">
                  <Col md="12">
                    <div className="mb-3">
                      <AvField
                        name="Name"
                        label="Name"
                        type="text"
                        errorMessage="Name required"
                        validate={{
                          required: { value: true },
                        }}
                        defaultValue={singleItem.Name}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                    <Col md="12">
                      <div className="mb-3">
                        <AvField
                          name="Price"
                          label="Price"
                          type="number"
                          errorMessage="Price required"
                          validate={{
                            required: { value: true },
                          }}
                          defaultValue={singleItem.Price}
                        />
                      </div>
                    </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="ForeColor"
                        label="Foreground color"
                        type="color"
                        errorMessage="Invalid forground color"
                        validate={{
                          required: { value: false },
                        }}
                        value={intColorToHex(singleItem?.ForeColor) || "#ffffff"}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="BackColor"
                        label="Background color"
                        type="color"
                        errorMessage="Invalid background color"
                        validate={{
                          required: { value: true },
                        }}
                        value={intColorToHex(singleItem?.BackColor) || "#333333"}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="BaseColor"
                        label="Base color"
                        type="color"
                        errorMessage="Invalid BaseColor"
                        validate={{
                          required: { value: false },
                        }}
                        value={intColorToHex(singleItem?.BaseColor) || "#333333"}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="FontSize"
                        label="Font size"
                        type="number"
                        errorMessage="Invalid font size"
                        validate={{
                          required: { value: false },
                        }}
                        value={singleItem?.FontSize || 20}
                      />
                    </div>
                  </Col>
                </Row>

                <Row style={{alignItems: 'flex-end'}}>
                  <Col>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user update-btn" disabled={loading}>
                        {loading ? (
                          <SpinnerCircular
                            size={30}
                            thickness={150}
                            speed={150}
                            color="#36ad47"
                            secondaryColor="rgba(0, 0, 0, 0.44)"
                          />
                        ) : (
                          <>Update</>
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            )}
              
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditMenuInstruction);
