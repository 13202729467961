import { KDScreenActionTypes } from "./actionTypes";

export const INIT_STATE: any = {
  selectedRestaurantId: '',
  screens: [],
  singleScreen: {},
  error: {},
  loading: false,
  success: false,
};

const menu = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case KDScreenActionTypes.GET_ALL_KD_SCREENS:
      return {
        ...state,
        loading: true,
      };

    case KDScreenActionTypes.GET_ALL_KD_SCREENS_SUCCESS:
      return {
        ...state,
        loading: false,
        screens: action.payload,
      };
    
    case KDScreenActionTypes.GET_KD_SCREEN_BY_ID:
      return {
        ...state,
        loading: true
      }
    
    case KDScreenActionTypes.GET_KD_SCREEN_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleScreen: action.payload
      }

    case KDScreenActionTypes.GET_RESTAURANT_KD_SCREENS:
      return {
        ...state,
        loading: true,
        selectedRestaurantId: action.payload
      };

    case KDScreenActionTypes.GET_RESTAURANT_KD_SCREENS_SUCCESS:
      return {
        ...state,
        loading: false,
        screens: action.payload,
      };

    case KDScreenActionTypes.UPDATE_KD_SCREEN_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case KDScreenActionTypes.UPDATE_KD_SCREEN_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleScreen: action.payload,
        screens: state.screens.map((c: any) => c.id === action.payload.id ? action.payload : c)
      };
    case KDScreenActionTypes.DELETE_KD_SCREEN_BY_ID:
      return {
        ...state,
        loading: true
      }

    case KDScreenActionTypes.DELETE_KD_SCREEN_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        screens: state.screens.filter((c: any) => c.id !== parseInt(action.payload.toString()))
      }

    case KDScreenActionTypes.ADD_KD_SCREEN:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case KDScreenActionTypes.ADD_KD_SCREEN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        screens: [...state.screens, action.payload],
      };
    
    case KDScreenActionTypes.API_RETURN_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      }

    default:
      return state;
  }
};

export default menu;
