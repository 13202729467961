import React, { useEffect, useState } from "react";
import { withRouter, useParams, Link, useHistory } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getCategoryMenuItems, getMenuCategoryById, getMenuItemById, getRestaurantById, getRestaurantMenuCategories, getRestaurantMenuCategoriesWithItems, getRestaurantSuppliers, updateMenuCategoryById, updateMenuItemById, updateRestaurantById } from "../../store/actions";
import { Col, Container, Label, Row } from "reactstrap";
import { SpinnerCircular } from "spinners-react";
import { hexColorToInt, intColorToHex } from "src/helpers/general";
import { getUserRestaurantId } from "src/helpers/backend_helper";
import { isObject, unset } from "lodash";
import { getMenuImageUrl } from ".";
import ImageGallery from "./image-gallery";

const AddMealDeal = (props: any) => {
  const [maxItemCount, setMaxItemCount] = useState('');
  const [charge, setCharge] = useState(false);
  const [position, setPosition] = useState(1);
  const [menuItems, setMenuItems] = useState<any>([]);
  const [selectedItemIds, setSelectedItemIds] = useState<any>([]);
  const [result, setResult] = useState<any>({});
  const [selectedCategory, setSelCategory] = useState(-1)

  useEffect(() => {
    if (props.open && props.linkedItemIds) {
      try {
        setResult(JSON.parse(props.linkedItemIds));
      } catch(err) {
        console.log(err);
      }
    }
  }, [props.open, props.linkedItemIds]);

  useEffect(() => {    
    if (props.open && props.categories.length > 0) {
      if (props.categories.length > 0) {
        setSelCategory(parseInt(props.categories[0].id.toString()));
        setMenuItems(props.categories[0].items);
      } else {
        setSelCategory(-1);
        setMenuItems([]);
      }
    }
  }, [props.open, props.categories]);

  useEffect(() => {
    if (result[selectedCategory]) {
      setMaxItemCount(result[selectedCategory].maxItemNo);
      setCharge(!!result[selectedCategory].charge);
      setPosition(result[selectedCategory].position || (props.categories.map((cat: any) => cat.id).indexOf(selectedCategory)+1));
      setSelectedItemIds(result[selectedCategory].items);
    } else {
      setSelectedItemIds([]);
      setMaxItemCount('');
      setCharge(false);
      setPosition(props.categories.map((cat: any) => cat.id).indexOf(selectedCategory)+1);
    }
  }, [result, selectedCategory]);

  const onCategoryChange = (e: any) => {
    // save current selection
    if (selectedItemIds.length > 0) {
      setResult((r: any) => ({...r, [selectedCategory]: {      
        items: selectedItemIds,
        maxItemNo: maxItemCount,
        position: position,
        charge: charge
      }}));
    } else {
      setResult((r: any) => {
        unset(r, selectedCategory);
        return r;
      })
    }
    // change category and menu items
    setSelCategory(parseInt(e.target.value));
    setMenuItems(props.categories.length>0 ? (props.categories.filter((cat: any) => cat.id.toString() === e.target.value)[0]?.items || []):[]);
  }

  const onItemChange = (id: any) => {
    setSelectedItemIds((ids: any) => ids.indexOf(id) >= 0 ? ids.filter((id1: any) => id1 != id) : [...ids, id]);
  }

  const onItemChangeAll = (value: boolean) => {
    setSelectedItemIds(value ? menuItems.map((mi: any) => mi.id) : []);
  }
  
  const addItem = () => {
    let obj = result;
    if (selectedItemIds.length > 0) {
      obj = {
        ...result,
        [selectedCategory]: {
          items: selectedItemIds,
          maxItemNo: maxItemCount,
          position: position,
          charge: charge
        }
      };
    } else {
      unset(obj, selectedCategory);
    }

    props.onAddMealDealClose(true, JSON.stringify(obj));
  }

  const onMaxItemChange = (e: any) => {
    setMaxItemCount(e.target.value);
  }

  return (
    <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onAddMealDealClose}
      >
      <div className="d-flex flex-column p-4">
        <div className="m-2 d-flex flex-column p-1">
          <label>Select menu category</label>
          <select value={selectedCategory} onChange={onCategoryChange}>
            {props.categories.map((cat: any) => (
              <option key={cat.id} value={cat.id}>{cat.Name}</option>
            ))}
          </select>
        </div>

        <div className="m-2 d-flex flex-column p-1">
          <label>Max No. of items</label>
          <input type="number" name="max_item_in_category" value={maxItemCount} onChange={onMaxItemChange} />
        </div>

        <div className="m-2 d-flex flex-column p-1">
          <label>Position</label>
          <input type="number" name="max_item_in_category" value={position} min={1} onChange={(e: any) => setPosition(e.target.value)} />
        </div>

        <div className="m-2 d-flex flex-row justify-content-between p-1">
          <span>Charge item price to meal deal price</span>
          <input type="checkbox" name="charge_in_category" checked={charge} onChange={(e: any) => setCharge(e.target.checked)} />
        </div>
        
        <div className="m-2 d-flex flex-column p-1">
          <label>Menu items</label>
          <div className="d-flex justify-content-between w-[100%]">
            <span>Select/unselect all</span>
            <input type="checkbox" onChange={(e) => onItemChangeAll(e.target.checked)} checked={menuItems.reduce((prev: boolean, item: any) => selectedItemIds.indexOf(item.id) >= 0 && prev, true)} />
          </div>
          {menuItems.map((item: any) => (
            <div key={item.id} className="d-flex justify-content-between w-[100%]">
              <span>{item.Name}</span>
              <input type="checkbox" checked={selectedItemIds.indexOf(item.id) >= 0} onChange={() => onItemChange(item.id)} />
            </div>
          ))}
        </div>
        <button className="btn btn-success m-2" onClick={addItem}>Update</button>
      </div>
    </ReactDrawer>
  )
}

const EditMenuItem = (props: any) => {
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const history = useHistory();

  const { singleItem, loading } = useSelector((state: any) => state.menuItems);
  const { categories, singleCategory } = useSelector( (state: any) => state.menu);
  const { items: suppliers } = useSelector( (state: any) => state.suppliers);
  const { singleRestaurant: restaurant } = useSelector((state: any) => state.restaurant);
  const [isMealDeal, setIsMealDeal] = useState(!!singleItem?.IsMealDeal);
  const [linkedMenuIds, setLinkedMenuIds] = useState(singleItem.LinkedMenuId || "");
  const [addMealDealOpened, setAddMealDealOpened] = useState(false);
  const [isHospitality, setHospitality] = useState(true);
  const [uploadImage, setUploadImage] = useState<any>();
  const [backImage, setBackImage] = useState<any>(null);
  const [showImageGallery, setShowImageGallery] = useState(false);
  const [defaultToppings, setDefaultToppings] = useState<any>([]);
  const [toppingItems, setToppingItems] = useState<any>([]);
  
  useEffect(() => {
    dispatch(getMenuItemById(id));
  }, []);

  useEffect(() => {    
    const userRestId = getUserRestaurantId()
    if (userRestId > 0) {
      dispatch(getRestaurantMenuCategoriesWithItems(userRestId));
      dispatch(getRestaurantSuppliers(userRestId));
      dispatch(getRestaurantById(userRestId));
    } else {
      if (singleItem?.Category_Id) {
        dispatch(getMenuCategoryById(singleItem.Category_Id));
      }
      if (singleItem?.BackImage) {
        setUploadImage(singleItem.BackImage);
        setBackImage(singleItem.BackImage);
      } else {
        setUploadImage(null);
        setBackImage(null);
      }
    }
    if (singleItem) {
      if (singleItem.DefaultToppings)
        setDefaultToppings(singleItem.DefaultToppings.split("@"));
      else
        setDefaultToppings([]);
    }
  }, [singleItem]);
  
  useEffect(() => {
    if (singleCategory && singleCategory.RestaurantId) {
      dispatch(getRestaurantMenuCategoriesWithItems(singleCategory.RestaurantId));
      dispatch(getRestaurantById(singleCategory.RestaurantId));
    }
    if (getUserRestaurantId() < 0) {      
      if (singleCategory?.RestaurantId) {
        dispatch(getRestaurantSuppliers(singleCategory.RestaurantId));
      }
    }
  }, [singleCategory])

  useEffect(() => {
    if (restaurant && restaurant.id) {
      setHospitality(restaurant.RestaurantType === 0);
    }
  }, [restaurant])

  useEffect(() => {
    if (categories.length > 0) {
      const toppingCategories = categories.filter((cat: any) => cat.CategoryType === 1);
      setToppingItems(toppingCategories.map((cat: any) => cat.items).reduce((pr: any, cur: any) => !!cur ? [...pr, ...cur] : pr, []));
    }
  }, [categories])

  useEffect(() => {
    if (singleItem) {
      setIsMealDeal(!!singleItem.IsMealDeal);
      setLinkedMenuIds(singleItem.LinkedMenuId || "");
    }
  }, [singleItem]);

  const handleEditItem = (values: any) => {
    try{
      history.push("/menu/categories");
      dispatch(updateMenuItemById(id, {
        ...values,      
        ForeColor: hexColorToInt(values['ForeColor']),
        BackColor: hexColorToInt(values['BackColor']),
        BaseColor: hexColorToInt(values['BaseColor']),
        SupplierId: parseInt(values.SupplierId) > 0 ? values.SupplierId : null,
        Stock: !!values['Stock'] ? values['Stock'] : 0,
        PricePerKg: !!values['PricePerKg'] ? values['PricePerKg'] : 0,
        WeightDefinedProduct: !!values['PricePerKg'] ? values['PricePerKg'] > 0 : false,
        BackImage: uploadImage,
        RestaurantId: singleCategory?.RestaurantId,
        DefaultToppings: defaultToppings.join("@")
      }, history));
    }catch(ex) {
      console.log(ex);
    }
  };

  const onAddMealDealClose = (shouldUpdate: any, menuItemIds: any) => {
    setAddMealDealOpened(false);
    if (shouldUpdate) {
      setLinkedMenuIds(menuItemIds);
    }
  }

  const onBackImageChange = (e: any) => {
    let reader = new FileReader();
    reader.onload = function(e1: any) {
      setBackImage(e1.target.result);
    };
    setUploadImage(e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  }

  let linkedMenuIdObj: any = {};
  let linkedMenuIdObjKeys = Object.keys(linkedMenuIdObj);
  try {
    linkedMenuIdObj = !!linkedMenuIds ? JSON.parse(linkedMenuIds) : {};
    linkedMenuIdObjKeys = Object.keys(linkedMenuIdObj);
    linkedMenuIdObjKeys.sort((a, b) => (linkedMenuIdObj[a].position || 1) - (linkedMenuIdObj[b].position || 1))
  } catch(err) {
    console.log(err);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <div className="d-flex flex-row col-md-6">
              <div className="mb-3">                
                <Link to="/menu/categories">
                    <i className="fa fa-arrow-left me-1"></i>
                  Menu categories
                </Link>
              </div>

              <div className="mb-3" style={{marginLeft: 20}}>
                <h5>Edit menu item</h5>
              </div>

              <div className="mx-4">
              <span className={`toggle ${isHospitality ? "toggle-hp" : "toggle-rt"} toggle-on`}>{isHospitality ? "Hospitality" : "Retail"}</span>
              </div>
            </div>
          </Row>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "20vh" }}
              >
                <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
              </div>
            ) : (
              <AvForm
                onValidSubmit={(e: any, values: any) => {
                  handleEditItem(values);
                }}
              >
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="Name"
                        label="Name"
                        type="text"
                        errorMessage="Name required"
                        validate={{
                          required: { value: true },
                        }}
                        value={singleItem?.Name || ""}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">                      
                      <AvField
                        name="Preparation_Time"
                        label="Preparation time"
                        type="number"
                        errorMessage="Preparation time required"
                        validate={{
                          required: { value: false },
                        }}
                        value={singleItem?.Preparation_Time || "0"}
                      />
                    </div>
                  </Col>
                </Row>

                {singleCategory && singleCategory.CategoryType === 1 ? (
                  <>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">                      
                          <AvField
                            name="Topping_Price"
                            label="Topping Price"
                            type="text"
                            errorMessage="Topping price required"
                            validate={{
                              required: { value: true },
                            }}
                            value={singleItem?.Topping_Price || ""}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">                      
                          <AvField
                            name="Topping_Price2"
                            label="Topping Price 2"
                            type="text"
                            errorMessage="Topping price required"
                            validate={{
                              required: { value: true },
                            }}
                            value={singleItem?.Topping_Price2 || ""}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">                      
                          <AvField
                            name="Topping_Price3"
                            label="Topping Price 3"
                            type="text"
                            errorMessage="Topping price required"
                            validate={{
                              required: { value: true },
                            }}
                            value={singleItem?.Topping_Price3 || ""}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">                      
                          <AvField
                            name="Topping_Price4"
                            label="Topping Price 4"
                            type="text"
                            errorMessage="Topping price required"
                            validate={{
                              required: { value: true },
                            }}
                            value={singleItem?.Topping_Price4 || ""}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">                      
                          <AvField
                            name="Topping_Price5"
                            label="Topping Price 5"
                            type="text"
                            errorMessage="Topping price required"
                            validate={{
                              required: { value: true },
                            }}
                            value={singleItem?.Topping_Price5 || ""}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">                      
                          <AvField
                            name="Topping_Price6"
                            label="Topping Price 6"
                            type="text"
                            errorMessage="Topping price required"
                            validate={{
                              required: { value: true },
                            }}
                            value={singleItem?.Topping_Price6 || ""}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">                      
                          <AvField
                            name="Topping_Price7"
                            label="Topping Price 7"
                            type="text"
                            errorMessage="Topping price required"
                            validate={{
                              required: { value: true },
                            }}
                            value={singleItem?.Topping_Price7 || ""}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">                      
                          <AvField
                            name="Topping_Price8"
                            label="Topping Price 8"
                            type="text"
                            errorMessage="Topping price required"
                            validate={{
                              required: { value: true },
                            }}
                            value={singleItem?.Topping_Price8 || ""}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">                      
                          <AvField
                            name="Topping_Price9"
                            label="Topping Price 9"
                            type="text"
                            errorMessage="Topping price required"
                            validate={{
                              required: { value: true },
                            }}
                            value={singleItem?.Topping_Price9 || ""}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">                      
                          <AvField
                            name="Topping_Price10"
                            label="Topping Price 10"
                            type="text"
                            errorMessage="Topping price required"
                            validate={{
                              required: { value: true },
                            }}
                            value={singleItem?.Topping_Price10 || ""}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <AvField
                            name="Position"
                            label="Position"
                            type="number"
                            errorMessage="Invalid position"
                            validate={{
                              required: { value: true },
                            }}
                            value={singleItem?.Position}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">                      
                          <AvField
                            name="Collection_Price"
                            label="Collection Price"
                            type="text"
                            errorMessage="Collection price required"
                            validate={{
                              required: { value: true },
                            }}
                            value={singleItem?.Collection_Price || ""}
                          />
                        </div>
                      </Col>
                    </Row>
                    {isHospitality && (
                    <Row>
                      <Col md="6">
                        <div className="mb-3">                      
                          <AvField
                            name="EatIn_Price"
                            label="EatIn Price"
                            type="text"
                            errorMessage="Eatin price required"
                            validate={{
                              required: { value: true },
                            }}
                            value={singleItem?.EatIn_Price || ""}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">                      
                          <AvField
                            name="Delivery_Price"
                            label="Delivery Price"
                            type="text"
                            errorMessage="Delivery price required"
                            validate={{
                              required: { value: true },
                            }}
                            value={singleItem?.Delivery_Price || ""}
                          />
                        </div>
                      </Col>
                    </Row>
                    )}
                  </>
                )}

                

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="ForeColor"
                        label="Foreground color"
                        type="color"
                        errorMessage="Invalid forground color"
                        validate={{
                          required: { value: false },
                        }}
                        value={intColorToHex(singleItem?.ForeColor) || "#000000"}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="BackColor"
                        label="Background color"
                        type="color"
                        errorMessage="Invalid background color"
                        validate={{
                          required: { value: true },
                        }}
                        value={intColorToHex(singleItem?.BackColor) || "#000000"}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="BaseColor"
                        label="Base color"
                        type="color"
                        errorMessage="Invalid BaseColor"
                        validate={{
                          required: { value: false },
                        }}
                        value={intColorToHex(singleItem?.BaseColor) || "#000000"}
                      />
                    </div>
                  </Col>                  
                  <Col md="6">
                    <div className="mb-3">
                      <img src={getMenuImageUrl(backImage)} width={100} height={100}/>
                      <span className="btn btn-light mx-2 cursor-pointer" onClick={() => setShowImageGallery(true)}>Select from gallery</span>
                      <AvField
                        id="MenuItemBackImage"
                        name="BackImage"
                        label="Background image"
                        type="file"
                        style={{display: "none"}}
                        onChange={onBackImageChange}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="align-items-end">                
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="FontSize"
                        label="Font size"
                        type="number"
                        errorMessage="Invalid font size"
                        validate={{
                          required: { value: false },
                        }}
                        value={singleItem?.FontSize || 20}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="CounterForLabelPrinting"
                        label="Group labels"
                        type="number"
                        value={singleItem?.CounterForLabelPrinting || "1"}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="FreeToppingNumber"
                        label="Free topping number"
                        type="number"
                        value={singleItem?.FreeToppingNumber || "0"}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="COST_PRICE"
                        label="Cost price"
                        type="text"
                        value={singleItem?.COST_PRICE || "0"}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="align-items-end">
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="AttachDressing"
                        label="Attach Dressing"
                        type="checkbox"
                        value={!!singleItem?.AttachDressing}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="AttachInstructions"
                        label="Attach Instructions"
                        type="checkbox"
                        value={!!singleItem?.AttachInstructions}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="d-flex flex-row justify-content-between mb-3 w-[100%]">
                      <AvField
                        name="IsMealDeal"
                        label="Is meal deal"
                        type="checkbox"
                        checked={isMealDeal}
                        value={isMealDeal}
                        onChange={() => setIsMealDeal(!isMealDeal)}
                      />
                      <AvField
                        name="LinkedMenuId"
                        type="text"
                        value={linkedMenuIds}
                        readOnly
                        style={{opacity: 0, width: 0}}
                      />
                    </div>                    
                    {isMealDeal && (
                      <div className="d-flex flex-row justify-content-between">  
                        <div style={{'height': 160, 'overflowY': 'scroll', flex: 1, border: '1px gray solid'}}>
                          {linkedMenuIdObjKeys.map((catId: string) => {
                            const cat = categories.filter((cat: any) => cat.id.toString() === catId)[0];
                            if (cat) {
                              return (                              
                                <ul key={cat.id}>
                                  {cat.Name} {linkedMenuIdObj[catId].maxItemNo ? `(max: ${linkedMenuIdObj[catId].maxItemNo})` : ''} &nbsp;
                                  {cat.items && cat.items.filter((mi: any) => linkedMenuIdObj[catId].items.indexOf(parseInt(mi.id)) >= 0).map((mi: any) => (
                                    <li key={mi.id}>{mi.Name}</li>
                                  ))}
                                </ul>
                              )
                            }
                          })}
                        </div>
                        <div>
                          <button type="button" className="btn btn-success mx-4" disabled={!isMealDeal} onClick={() => setAddMealDealOpened(true)}>Edit linked items</button>
                          <button type="button" className="btn btn-success mx-1" disabled={!isMealDeal} onClick={() => setLinkedMenuIds("")}>Clear</button>
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="SubMenu"
                        label="Sub menu"
                        type="checkbox"
                        value={!!singleItem?.SubMenu}
                      />
                    </div>
                  </Col>
                </Row>

                {!isHospitality && (
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <AvField
                          name="BarCode"
                          label="Barcode"
                          type="text"
                          value={singleItem?.BarCode || ""}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <AvField
                          name="PricePerKg"
                          label="Price per kg"
                          type="number"
                          value={singleItem?.PricePerKg || ""}
                        />
                      </div>
                    </Col>
                  </Row>
                )}

                {!isHospitality && (
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <AvField
                          name="OfferPrice"
                          label="Offer Price"
                          type="text"
                          value={singleItem?.OfferPrice || ""}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <AvField
                          name="OfferQuantity"
                          label="Offer Quantity"
                          type="number"
                          value={singleItem?.OfferQuantity || ""}
                        />
                      </div>
                    </Col>
                  </Row>
                )}

                {!isHospitality && (
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <AvField
                          name="SupplierId"
                          label="Supplier"
                          type="select"
                          value={singleItem?.SupplierId || ""}
                        >
                          <option key="0" value={0}>None</option>
                          {suppliers.map((sup: any) => (
                            <option key={sup.id} value={sup.id} >{sup.supplierName}</option>
                          ))}
                        </AvField>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <AvField
                          name="Stock"
                          label="Stock"
                          type="number"
                          value={singleItem?.Stock || "0"}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="Remarks"
                        label="Remarks"
                        type="text"
                        value={singleItem?.Remarks}
                      />
                    </div>
                  </Col>
                  {!isHospitality ? (
                    <Col md="6">
                      <div className="mb-3">
                        <AvField
                          name="Multipack"
                          label="Multipack"
                          type="number"
                          value={singleItem?.Multipack}
                        />
                      </div>
                    </Col>
                  ) : (
                    <Col md="6">
                      <Label>Select default toppings</Label>
                      {toppingItems.map((menuItem: any) => (
                        <div className="mb-1" key={menuItem.id}>
                          <AvField
                            name={menuItem.Name}
                            label={menuItem.Name}
                            type="checkbox"
                            value={defaultToppings.indexOf(menuItem.Name) >= 0}
                            onChange={(e: any) => setDefaultToppings((dt: any) => 
                              dt.indexOf(menuItem.Name) >= 0 ? 
                              dt.filter((val: string) => val != menuItem.Name) :
                              [...dt, menuItem.Name]
                            )}
                          />
                        </div>
                      ))}
                    </Col>
                  )}
                </Row>

                {!isHospitality && (<Row>
                    <Col md="6">
                      <div className="mb-3">
                        <AvField
                          name="Weight"
                          label="Weight"
                          type="Weight"
                          value={singleItem?.Weight || "0"}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <AvField
                          name="Metric"
                          label="Metric"
                          type="select"
                          value={singleItem?.Metric || ""}
                        >
                          {["g (grams)", "mm (millilitres)", "cl (centilitres)"].map((metric: string) => (
                            <option key={metric} value={metric} >{metric}</option>
                          ))}
                        </AvField>
                      </div>
                    </Col>
                  </Row>
                )}

                <Row className="justify-content-end">

                  <Col md="6">
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user update-btn" disabled={loading}>
                        {loading ? (
                          <SpinnerCircular
                            size={30}
                            thickness={150}
                            speed={150}
                            color="#36ad47"
                            secondaryColor="rgba(0, 0, 0, 0.44)"
                          />
                        ) : (
                          <>Update</>
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            )}
              
        </Container>
      </div>
      <AddMealDeal 
        open={addMealDealOpened} 
        onAddMealDealClose={onAddMealDealClose}
        linkedItemIds={linkedMenuIds}
        categories={categories.filter((c: any) => singleItem.Category_Id != c.id && (c.CategoryType === null || c.CategoryType === 0 || c.CategoryType === 5))}
        singleCategory={singleCategory}
      />            
      <ImageGallery 
        restaurantId={singleCategory?.RestaurantId} 
        open={showImageGallery} 
        onDrawerClose={() => setShowImageGallery(false)} 
        onSelect={(id: any) => {
          setUploadImage("id:" + id);
          setBackImage("id:" + id);
          setShowImageGallery(false);
        }}
      />
    </React.Fragment>
  );
};

export default withRouter(EditMenuItem);
