import axios from "axios";

//sms
const options = {
    auth: {
        username: "accounts@eposanytime.co.uk",
        password: "BC70271B-15D0-12D6-A0BA-E165960AF085"
    },
    "Content-Type": "application/json"
}

const updateClickSentAuth = (username: string, password: string) => {
    options.auth = {
        username,
        password
    }
}

const baseUrl = "https://rest.clicksend.com/v3"
const apiPost = (url: string, body: any, authOptions: any) => 
    axios.post(baseUrl + url, body, authOptions ? {...options, auth: authOptions} : options)
    .then(r => ({success: r.status === 200, result: r.data.data}))

const apiPut = (url: string, body: any, authOptions: any) => 
    axios.put(baseUrl + url, body, authOptions ? {...options, auth: authOptions} : options)
    .then(r => ({success: r.status === 200, result: r.data.data}))

const apiDelete = (url: string, authOptions: any) => 
    axios.delete(baseUrl + url, authOptions ? {...options, auth: authOptions} : options)
    .then(r => ({success: r.status === 200}))

const getAll = (url: string, authOptions: any) => 
    axios.get(baseUrl + url, authOptions ? {...options, auth: authOptions} : options)
    .then(r => ({success: r.status === 200, result: r.data.data.data}))

const getOne = (url: string, authOptions: any) => 
    axios.get(baseUrl + url, authOptions ? {...options, auth: authOptions} : options)
    .then(r => ({success: r.status === 200, result: r.data.data}))

const sendSignleSMSAsync = (to: string, from: string, message: string, authOptions: any) => apiPost("/sms/send", {
    messages:  [{
        from: from,
        body: message,
        to: to
    }]
}, authOptions)

const sendSignleVMAsync = (to: string, message: string) => apiPost("/voice/send", {
    messages:  [{
        body: message,
        to: to,
        lang: 'en-gb'
    }]
}, null)

const sendCampaignSMSAsync = (name: string, list_id: number, from: string, body: string, schedule: number, authOptions: any) => 
    apiPost("/sms-campaigns/send", {list_id, from, name, body, schedule}, authOptions)

const getSMSTemplatesAsync = (authOptions: any) => getAll("/sms/templates", authOptions)

const createSMSTemplatesAsync = (template_name: string, body: string, authOptions: any) => apiPost("/sms/templates", {template_name, body}, authOptions)

const updateSMSTemplateAsync = (template_id: string, template_name: string, body: string, authOptions: any) => 
    apiPut(`/sms/templates/${template_id}`, {body, template_name}, authOptions)

const deleteSMSTemplateAsync = (template_id: string, authOptions: any) => 
    apiDelete(`/sms/templates/${template_id}`, authOptions)

const findContactListByName = (listName: string, authOptions: any) => 
    getAll("/lists", authOptions).then(r => ({success: r.success, result: r.result.find((c: any) => c.list_name === listName)}))

const createContactList = (list_name: string, authOptions: any) => apiPost("/lists", {list_name}, authOptions)
const copyContactList = (customers: any, fromListId: number, toListId: number, authOptions: any) => 
    Promise.all(customers.map((c: any) => apiPut(`/lists/${fromListId}/contacts/${c.contactId}/copy/${toListId}`, {}, authOptions)))

const createCampaignAsync = async (restaurantName: any, restaurantId: any, customers: any, name: string, from: string, body: string, scheduleTime: number, authOptions: any) => {
    const contactListName = "all_customers_" + restaurantName.replace(/\s+/g, "").toLowerCase() + "_" + restaurantId
    const r1 = await findContactListByName(contactListName, authOptions);
    if (r1.success && r1.result) {
        const allContactList = r1.result;
        const newContactListName = "campaign_customers_" + name.replace(/\s+/g, "").toLowerCase()
        const r2 = await createContactList(newContactListName, authOptions);
        if (r2.success && r2.result) {
            const newContact = r2.result
            try {
                await copyContactList(customers, allContactList.list_id, newContact.list_id, authOptions);
            } catch(ex) {
                return {success: false, result: "Customer contact id invalid"}
            }            
            return await sendCampaignSMSAsync(name, newContact.list_id, from, body, scheduleTime, authOptions);
        } else {
            return {success: false, result: "Create campaign contact list failed"}
        }
    }
    return {success: false, result: "All cusomters contact list not found"}
}

const cancelCampaignAsync = (campaignId: number, authOptions: any) => apiPut(`/sms-campaigns/${campaignId}/cancel`, {}, authOptions)

const getAllCampaignsAsync = (authOptions: any) => getAll("/sms-campaigns", authOptions)

export {
    sendSignleSMSAsync,
    sendCampaignSMSAsync,
    getSMSTemplatesAsync,
    createSMSTemplatesAsync,
    updateSMSTemplateAsync,
    deleteSMSTemplateAsync,
    findContactListByName,
    createContactList,
    copyContactList,
    createCampaignAsync,
    getAllCampaignsAsync,
    cancelCampaignAsync,
    updateClickSentAuth,
    sendSignleVMAsync
}