import React, { useEffect, useState } from "react";
import { withRouter, useParams, Link, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getPurchaseOrderById, getRestaurantSuppliers, getSupplierById, getSupplierMenuItems, updatePurchaseOrderById, updateSupplierById } from "../../store/actions";
import { Col, Container, Row } from "reactstrap";
import { SpinnerCircular } from "spinners-react";
import { getUserRestaurantId } from "src/helpers/backend_helper";


const EditStock = (props: any) => {
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const history = useHistory();

  const [supplierId, setSupplierId] = useState(0);
  const { singleItem, loading } = useSelector((state: any) => state.purchaseOrders);
  const { items: suppliers } = useSelector((state: any) => state.suppliers);
  const { items: menuItems } = useSelector((state: any) => state.menuItems);

  useEffect(() => {
    dispatch(getPurchaseOrderById(id));
    dispatch(getRestaurantSuppliers(getUserRestaurantId()));
  }, []);

  useEffect(() => {
    if (singleItem && singleItem.SupplierId) {
      setSupplierId(singleItem.SupplierId);
      dispatch(getSupplierMenuItems(singleItem.SupplierId));
    }
  }, [singleItem])

  useEffect(() => {
    if (supplierId > 0) {
      dispatch(getSupplierMenuItems(supplierId.toString()));
    }
  }, [supplierId]);

  const handleEditItem = (values: any) => {
    dispatch(updatePurchaseOrderById(id, {
      ...values,
      RestaurantId: getUserRestaurantId(),
    }, history));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <div className="d-flex flex-row col-md-6">
              <div className="mb-3">                
                <Link to="/stocks">
                    <i className="fa fa-arrow-left me-1"></i>
                  Stocks
                </Link>
              </div>

              <div className="mb-3" style={{marginLeft: 20}}>
                <h5>Edit purchase order</h5>
              </div>
            </div>
          </Row>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "20vh" }}
              >
                <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
              </div>
            ) : (
              <AvForm
                onValidSubmit={(e: any, values: any) => {
                  handleEditItem(values);
                }}
              >
                <Row className="align-items-center">
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="SupplierId"
                        label="Supplier"
                        type="select"
                        value={supplierId}
                        errorMessage="Supplier required"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={(e: any) => setSupplierId(parseInt(e.target.value))}
                      >
                        <option key="0" value={0}>Select supplier</option>
                        {suppliers.map((sup: any) => (
                          <option key={sup.id} value={sup.id}>{sup.supplierName}</option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="MenuItemId"
                        label="Menu item"
                        type="select"
                        value={singleItem?.MenuItemId}
                        errorMessage="Menu item required"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={(e: any) => {}}
                      >
                        <option key="0" value={0}>Select menu item</option>
                        {menuItems.map((menuItem: any) => (
                          <option key={menuItem.id} value={menuItem.id}>{menuItem.Name}</option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="quantity"
                        label="Quantity"
                        type="number"
                        defaultValue={singleItem?.quantity || "1"}
                        errorMessage="quantity required"
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row style={{alignItems: 'flex-end'}}>
                  <Col>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user update-btn" disabled={loading}>
                        {loading ? (
                          <SpinnerCircular
                            size={30}
                            thickness={150}
                            speed={150}
                            color="#36ad47"
                            secondaryColor="rgba(0, 0, 0, 0.44)"
                          />
                        ) : (
                          <>Update</>
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            )}
              
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditStock);
