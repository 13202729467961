export enum PrintersActionTypes {
  GET_RESTAURANT_PRINTERS = "GET_RESTAURANT_PRINTERS",
  GET_RESTAURANT_PRINTERS_SUCCESS = 'GET_RESTAURANT_PRINTERS_SUCCESS',

  UPDATE_PRINTER_BY_ID = 'UPDATE_PRINTER_BY_ID',
  UPDATE_PRINTER_BY_ID_SUCCESS = 'UPDATE_PRINTER_BY_ID_SUCCESS',

  ADD_PRINTER = 'ADD_PRINTER',
  ADD_PRINTER_SUCCESS = 'ADD_PRINTER_SUCCESS',

  DELETE_PRINTER_BY_ID = 'DELETE_PRINTER_BY_ID',
  DELETE_PRINTER_BY_ID_SUCCESS = 'DELETE_PRINTER_BY_ID_SUCCESS',

  GET_PRINTER_BY_ID = 'GET_PRINTER_BY_ID',
  GET_PRINTER_BY_ID_SUCCESS = 'GET_PRINTER_BY_ID_SUCCESS',

  PRINTERS_API_RETURN_ERROR = 'PRINTERS_API_RETURN_ERROR'
}
