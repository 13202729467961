import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Col, Alert, Container } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { adminLogin } from "../../store/actions";
import CarouselPage from "./CarouselPage";
import { SpinnerCircular } from "spinners-react";
import { withTranslation } from "react-i18next";
import LogoImage from "../../assets/images/logo.png";

const Login = (props: any) => {
  const dispatch = useDispatch();
  const { errorMsg, loading } = useSelector((state: any) => state.login);

  const handleLogin = (event: any, values: any) => {
    dispatch(adminLogin(values, props.history));
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Admin Login</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">

                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <img src={LogoImage} width={100} height={32}/>
                        <p className="text-muted mt-2">
                        Control your business from one portal
                        </p>
                      </div>
                        <AvForm
                          className="custom-form mt-4 pt-2"
                          onValidSubmit={(e: any, v: any) => {
                            handleLogin(e, v);
                          }}
                        >
                          {errorMsg ? (
                            <Alert color="danger"> {errorMsg} </Alert>
                          ) : null}
                          <div className="mb-3">
                            <AvField
                              name="email"
                              label={props.t("Email")}
                              value=""
                              className="form-control"
                              placeholder="Enter Company Code"
                              type="email"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1">
                                <label className="form-label">
                                  {props.t("Password")}
                                </label>
                              </div>
                              <div className="flex-shrink-0">
                                <div className="">
                                  <Link
                                    to="/auth-recoverpw"
                                    className="text-muted"
                                  >
                                    {props.t("Forgot Password")}
                                  </Link>
                                </div>
                              </div>
                            </div>

                            <div className="mb-3">
                              <AvField
                                name="password"
                                value=""
                                type="password"
                                className="form-control"
                                required
                                placeholder="Enter Password"
                              />
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="remember-check"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="remember-check"
                                >
                                  {props.t("Remember Me")}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                              disabled={loading}
                              style={{ height: 50 }}
                            >
                              {loading ? (
                                <SpinnerCircular
                                  size={30}
                                  thickness={150}
                                  speed={150}
                                  color="#36ad47"
                                  secondaryColor="rgba(0, 0, 0, 0.44)"
                                />
                              ) : (
                                <> {props.t("Login")}</>
                              )}
                            </button>
                          </div>
                        </AvForm>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Login.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(Login));
