import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Input, Label, FormGroup, Form  } from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
//redux
import { useSelector, useDispatch } from "react-redux";
import { getAllOrders, getRestaurantById, getAllRestaurantsByManager,getAllRestaurants } from "../../store/actions";
import { getCustomerByIdAsync, getUserRestaurantId } from "../../helpers/backend_helper";
import axios from "axios";
import SendSMS from "./send-sms";
import OrderDetail from "./order-detail";
import { convertSecondsToHMS } from "src/helpers/general";


const Order = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orders, totalCount, loading } = useSelector( (state: any) => state.order);
  const { singleRestaurant: restaurant, restaurants } = useSelector( (state: any) => state.restaurant);
  const { currentUser } = useSelector((state: any) => state.login);
  const [orderList, setOrderList] = useState<any>([]);
  const [selectedRestId, setSelRestId] = useState(localStorage.selectedRestaurantId || '');
  const [drawer, setDrawer] = useState<Boolean>(false);
  const [customerId, setCustomerId] = useState<string>("");
  const [restaurantId, setRestaurantId] = useState<number>(-1);
  const [isWebOrderOnly, setWebOrderOnly] = useState(false);
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [showDetail, setShowDetail] = useState<Boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [isHospitality, setHospitality] =  useState(true);

  const { SearchBar } = Search;

  console.log('orders =>', orders)

  const sidePerPage = 20;
  const pageOptions = {
    sizePerPage: sidePerPage,
    totalSize: totalCount, // replace later with size(companies),
    custom: true,
    page: selectedRestId < 0 ? currentPage : undefined,
    onPageChange: (pageNumber: number, sizePerPage: number) => {
      if (selectedRestId < 0) {
        setCurrentPage(pageNumber);     
        dispatch(getAllOrders("-1",isWebOrderOnly, (pageNumber-1) * sizePerPage, sizePerPage, dateStart, dateEnd));
      }
    }
  };

  const ordersListtColumns = [
    {
      dataField: "OrderNo",
      text: "Order No",
      sort: true,
    },
    {
      dataField: "RestaurantName",
      text: "Restaurant",
      sort: true,
      hidden: getUserRestaurantId() >= 0
    },
    {
      dataField: "OrderDate",
      text: "Date",
      sort: true,
    },
    {
      dataField: "Order_Type",
      text: "Order Type",
      sort: true,
      hidden: !isHospitality,
    },
    {
      dataField: "Order_Status",
      text: "Order Status",
      sort: true,
    },
    {
      dataField: "CustomerId",
      text: "CustomerId",
      sort: true,
      hidden: !isHospitality,
      formatter: (cell: any, row: any) => (
        <a href="#" onClick={() => sendSMS(cell, row)}>
          <span>{cell} {parseInt(cell)>0}</span>
        </a>
      )
    },
    {
      dataField: "PayStatus",
      text: "Pay Status",
      sort: true,
    },
    {
      dataField: "Payment_Type",
      text: "Payment Type ",
      sort: true,
    },
    {
      dataField: "TotalAmount",
      text: "Total",
      sort: true,
    },
    {
      dataField: "PrepTimeInSec",
      text: "Prep. Time",
      sort: true,
      hidden: !isHospitality,
      formatter: (cell: any, row: any) => convertSecondsToHMS(cell)
    },
    {
      dataField: "ServiceCharge",
      text: "Service Charge",
      sort: true,
      hidden: !isHospitality,
    },
    {
      dataField: "DeliveryCharge",
      text: "Delivery Charge",
      sort: true,
    },
    {
      dataField: "Discount",
      text: "Discount",
      sort: true,
    },
    {
      dataField: "WebOrderId",
      text: "WebOrderId",
      sort: true,
      hidden: !isHospitality,
    },
    {
      dataField: "Operator",
      text: "Operator",
      sort: true,
    },
    {
      dataField: "ComputerName",
      text: "Computer Name",
      sort: true,
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cellContent: any, row: any) => (
        <div className="d-flex gap-3">
          <button className="btn btn-success" onClick={() => handleRowClick(row)}>
            View
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const userRestId = getUserRestaurantId()
    if (userRestId > 0) {
      dispatch(getAllOrders(userRestId,isWebOrderOnly, 0, sidePerPage, dateStart, dateEnd));
    } else if(userRestId == -1) {
      // setSelRestId("-1");
      // localStorage.setItem("selectedRestaurantId", "-1");
      // dispatch(getAllOrders("-1",isWebOrderOnly, 0, sidePerPage, dateStart, dateEnd));
      dispatch(getAllRestaurants());
    } else if(userRestId == 0){
      dispatch(getAllRestaurantsByManager())
    }
  }, []);

  useEffect(() => {
    if (selectedRestId !== '' && !(getUserRestaurantId() >= 0 && selectedRestId == -1)) {
      localStorage.setItem("selectedRestaurantId", selectedRestId);
      dispatch(getAllOrders(selectedRestId,isWebOrderOnly, 0, sidePerPage, dateStart, dateEnd));
      if (parseInt(selectedRestId) > 0) {
        dispatch(getRestaurantById(selectedRestId));
      }
    }
  }, [selectedRestId])

  useEffect(() => {
    if (selectedRestId > 0 && restaurant && restaurant.id > 0) {
      setHospitality(restaurant.RestaurantType === 0);
    }
  }, [restaurant])

  useEffect(() => {
    dispatch(getAllOrders(selectedRestId,isWebOrderOnly, 0, sidePerPage, dateStart, dateEnd));
  }, [isWebOrderOnly]);

  useEffect(() => {
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      order.ServiceCharge = order.ServiceChargeFee + order.PackagingFee;
    }
    if (selectedRestId > 0){
      // frontend filtering
      setOrderList(orders.filter((order: any) => 
        (!dateStart || new Date(order.OrderDate).getTime() >= new Date(dateStart).getTime()) && 
        (!dateEnd || new Date(order.OrderDate).getTime() <= new Date(dateEnd).getTime()))
      )
    } else {
      // backend filtering
      setOrderList(orders.filter((order: any) => `${order.OrderNo} ${order.CustomerId} ${order.TotalAmount} ${order.RestaurantName} ${order.Order_Status} ${order.Order_Type} ${order.ComputerName} ${order.PayStatus} ${order.Payment_Type}`.toLowerCase().includes(searchText.toLowerCase())))
    }
  }, [orders, dateStart, dateEnd, searchText]);

  const onRestaurantChange = (event: any) => {
    setSelRestId(event.target.value)
  }

  const handleEditAdmin = (admin: any) => {
    history.push(`/admins/${admin.id}`);
  };

  const sendSMS = (customerId: any, rowContent: any) => {
    setCustomerId(customerId);
    setRestaurantId(rowContent.RestaurantId)
    setDrawer(true);
  }
  
  const onDrawerClose = (value: Boolean) => {
    setDrawer(value);    
  };

  const rowStyleFunc = (row: any, index: number) => {
    if (row["IsYummyOrder"] === true) {
      return { backgroundColor: '#FFB6C1' }
    } else if (row["IsWooOrder"] === true) {
      return { backgroundColor: '#ADD8E6' }
    } else if (row["IsSnappyOrder"] === true) {
      return { backgroundColor: '#FFCCCB' }
    }
    return {}
  }
  
  const handleWebOrderOnly = (e: any) => {
    setWebOrderOnly(e.target.checked);
  }

  const onDateFilterChange = (e: any) => {
    let startDate = dateStart, endDate = dateEnd;
    if (e.target.name === 'dateEnd') {
      setDateEnd(e.target.value);
      endDate = e.target.value;
    } else if (e.target.name === 'dateStart') {
      setDateStart(e.target.value);
      startDate = e.target.value;
    }
    setCurrentPage(0)
    dispatch(getAllOrders(selectedRestId,isWebOrderOnly, 0, sidePerPage, startDate, endDate));
  }

  const handleRowClick = (row: any) => {
    setSelectedOrder(row);
    setShowDetail(true);
  }

  const onTableChange = (type: string, params: any) => {
    if (type === 'search') {
      setSearchText(params.searchText);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Admins </title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
            </div>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={orderList}
                          columns={ordersListtColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <div className="row align-item  ms-center">
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <h5 className="card-title">
                                      orders List{" "}
                                        <span className="text-muted fw-normal ms-2">
                                          ({selectedRestId>0?orders.length:totalCount})
                                        </span>
                                      </h5>
                                    </div>
                                  </div>                             
                                </div>
                                <Row>
                                  <Col sm="8">
                                    <Row>
                                      <Col sm="3">
                                        <FormGroup row>
                                          <Col sm="2">
                                            <Input  type="checkbox" name="IsWebOrder" checked={isWebOrderOnly} onChange={(e) => handleWebOrderOnly(e)} />
                                          </Col>
                                        <Label htmlFor="IsWebOrder" sm="10">&nbsp; Web orders only</Label>
                                        </FormGroup>
                                      </Col>                                  
                                      <Col sm="5">
                                        <FormGroup row>
                                          <Label sm="6" htmlFor="dateStart">&nbsp;&nbsp;  Filter by date from: &nbsp; </Label>
                                          <Col sm="6">
                                            <Input type="date" name="dateStart" value={dateStart} onChange={onDateFilterChange} />
                                          </Col>
                                        </FormGroup>
                                      </Col>
                                      <Col sm="4">
                                        <FormGroup row>
                                        <Label sm="3" htmlFor="dateEnd">&nbsp;  to: &nbsp; </Label>
                                        <Col sm="8">
                                          <Input type="date" name="dateEnd" value={dateEnd} onChange={onDateFilterChange} />
                                        </Col>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col sm="2">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon-search" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="2">
                                    {getUserRestaurantId() <= 0 && (
                                      <Input name="restaurantId" onChange={onRestaurantChange} value={selectedRestId} type="select">
                                        <option key="all" value="-1">Select restaurant</option>
                                        {restaurants.map((restaurant: any) => (
                                          <option key={restaurant.id} value={restaurant.id}>{restaurant.ShopName}</option>
                                        ))}
                                      </Input>
                                    )}
                                  </Col>
                                </Row>
                                
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      remote={getUserRestaurantId() < 0}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      bordered={false}
                                      striped={false}
                                      rowStyle={rowStyleFunc}
                                      onTableChange={onTableChange}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <SendSMS open={drawer} onDrawerClose={onDrawerClose} customerId={customerId} restaurantId={restaurantId} />
          <OrderDetail open={showDetail} onDrawerClose={() => setShowDetail(false)} order={selectedOrder} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Order);
