import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row,  } from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
//redux
import { useSelector, useDispatch } from "react-redux";
import { cancelCampaign, getAllCampaigns } from "../../store/actions";
import SendSMS from "./send-sms";


const SMSCampaign = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { campaigns, campaignsLoading, cancelingCampaign } = useSelector( (state: any) => state.sms);
  const [cancelItem, setCancelItem] = useState<any>(null);

  const pageOptions = {
    sizePerPage: 20,
    totalSize: campaigns.length, // replace later with size(companies),
    custom: true,
  };

  const campaignListtColumns = [
    {
      dataField: "sms_campaign_id",
      text: "CampaignId",
      sort: true,
    },
    {
      dataField: "name",
      text: "CampaignName",
      sort: true,
    },
    {
      dataField: "from",
      text: "From",
      sort: true,
    },
    {
      dataField: "body",
      text: "Message",
      sort: true,
    },
    {
      dataField: "_total_count",
      text: "# of Customers",
      sort: true,
    },
    {
      dataField: "subaccount_id",
      text: "Sub account id"
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
    },
    {
      dataField: "menu",
      text: "Menu",
      formatter: (cellContent: any, campaign: any) => (
        <div className="d-flex gap-3">
          {(cancelItem && campaign.id === cancelItem.id && cancelingCampaign) ? (
            <span>Cancelling...</span>
          ) : (
            campaign.status === 'Scheduled' && (
              <button className="btn">
                <i
                  className="bx bx-x-circle font-size-24"
                  id="edittooltip"
                  onClick={() => handleCancelCampaign(campaign)}
                ></i>
              </button>
            )
          )}
        </div>
      ),
    }
  ];

  useEffect(() => {
    dispatch(getAllCampaigns());
  }, []);

  useEffect(() => {
    if (cancelItem && !cancelingCampaign) {
      setCancelItem(null)
      dispatch(getAllCampaigns());
    }
  }, [cancelingCampaign])

  const handleCancelCampaign = (campaign: any) => {
    setCancelItem(campaign)
    dispatch(cancelCampaign(campaign.sms_campaign_id))
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Admins </title>
        </MetaTags>
        <Container fluid>
          {campaignsLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
            </div>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={campaigns}
                          columns={campaignListtColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <div className="row align-item ms-center">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <h5 className="card-title">
                                        Campaign List{" "}
                                        <span className="text-muted fw-normal ms-2">
                                          ({campaigns.length})
                                        </span>
                                      </h5>
                                    </div>
                                  </div>
                                  
                                  <div className="col-md-6">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                     
                                        <div>
                                          <Link className="btn btn-light" to="/sms-templates" >
                                            SMS Templates List
                                          </Link>
                                        </div>

                                        <div>
                                          <Link
                                            to="/create-campaign"
                                            className="btn btn-light"
                                          >
                                            <i className="bx bx-send me-1"></i>{" "}
                                            Create campaign
                                          </Link>
                                        </div>
                                      
                                    </div>
                                  </div>
                                </div>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SMSCampaign);
