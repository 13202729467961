import React, { useEffect, useState } from "react";
import { withRouter, useParams, Link, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getPrinterById, getRestaurantMenuCategoriesWithItems, updatePrinterById } from "../../store/actions";
import { Col, Container, Row } from "reactstrap";
import { SpinnerCircular } from "spinners-react";
import { hexColorToInt, intColorToHex } from "src/helpers/general";
import { getUserRestaurantId } from "src/helpers/backend_helper";


const EditPrinter = (props: any) => {
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const history = useHistory();

  const { singleItem, loading } = useSelector((state: any) => state.printers);
  const { categories } = useSelector( (state: any) => state.menu);
  const [selectedCategory, setSelCategory] = useState(-1);
  const [menuItems, setMenuItems] = useState<any>([]);
  const [selectedItemIds, setSelectedItemIds] = useState<any>([]);
  const [printerType, setPrinterType] = useState("Local printer");

  useEffect(() => {
    dispatch(getPrinterById(id));
  }, []);

  useEffect(() => {
    if (singleItem) {
      dispatch(getRestaurantMenuCategoriesWithItems(singleItem.RestaurantId));
      if (singleItem.items) {
        setSelectedItemIds(singleItem.items.split(",").map((s: string) => parseInt(s)));
      }
      setPrinterType(singleItem.printerType);
    }
  }, [singleItem])

  useEffect(() => {    
    if (categories.length > 0) {
      if (categories.length > 0) {
        setSelCategory(parseInt(categories[0].id.toString()));
        setMenuItems(categories[0].items);
      } else {
        setSelCategory(-1);
        setMenuItems([]);
      }
    }
  }, [categories]);

  const handleEditItem = (values: any) => {
    dispatch(updatePrinterById(id, {
      ...values,
      RestaurantId: getUserRestaurantId() < 0 ? parseInt(singleItem?.RestaurantId || '1') : getUserRestaurantId(),
      items: selectedItemIds.join(",")
    }, history));
  };

  const onCategoryChange = (e: any) => {
    setSelCategory(e.target.value);
    setMenuItems(categories.length>0 ? (categories.filter((cat: any) => cat.id.toString() === e.target.value)[0]?.items || []):[]);
  }

  const onItemChange = (id: any) => {
    setSelectedItemIds((ids: any) => ids.indexOf(id) >= 0 ? ids.filter((id1: any) => id1 != id) : [...ids, id]);
  }

  const onItemChangeAll = (value: boolean) => {
    const selIds = menuItems.map((mi: any) => mi.id);
    setSelectedItemIds((ids: any) => !!value ? [...ids, ...selIds.filter((id: any) => ids.indexOf(id) < 0)] : ids.filter((id: any) => selIds.indexOf(id) < 0));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <div className="d-flex flex-row col-md-6">
              <div className="mb-3">                
                <Link to="/printers">
                    <i className="fa fa-arrow-left me-1"></i>
                  Printers
                </Link>
              </div>

              <div className="mb-3" style={{marginLeft: 20}}>
                <h5>Edit printer</h5>
              </div>
            </div>
          </Row>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "20vh" }}
              >
                <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
              </div>
            ) : (
              <AvForm
                onValidSubmit={(e: any, values: any) => {
                  handleEditItem(values);
                }}
              >
                <Row className="align-items-center">
                  <Col md="6">
                    <div className="mb-3">                      
                      <AvField type="select" label="Printer type" name="printerType" value={printerType} onChange={(e: any) => setPrinterType(e.target.value)}>
                        <option value="Local printer"> Local printer </option>
                        <option value="Sunmi" selected={singleItem?.printerType === 'Sunmi'}> Sunmi </option>
                        <option value="xPrinter XP-80C" selected={singleItem?.printerType === 'xPrinter XP-80C'}> xPrinter XP-80C </option>
                        <option value="Star mcPrint" selected={singleItem?.printerType === 'Star mcPrint'}> Star mcPrint </option>
                        <option value="Star TSP143" selected={singleItem?.printerType === 'Star TSP143'}> Star TSP143 </option>
                      </AvField>
                    </div>
                  </Col>
                  <Col md="6">
                  <div className="mb-3">
                      {printerType != "Local printer" ? (
                        <AvField type="select" label="Designation" name="designation" value={singleItem?.designation} >
                          <option value="" hidden></option>
                        <option value="bill printer" selected={singleItem?.designation === 'bill printer'}> bill printer </option>
                        <option value="sub receipt printer" selected={singleItem?.designation === 'sub receipt printer'}> sub receipt printer </option>
                        </AvField>
                      ) : (
                        <AvField type="text" label="Designation" name="designation" value={singleItem?.designation}/>
                      )}
                    </div>
                  </Col>
                </Row>
                {printerType != "Local printer" && (
                <Row className="align-items-center">
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="ipAddress"
                        label="IP address"
                        type="text"
                        errorMessage="IP address required"
                        validate={{
                          required: { value: true },
                        }}
                        defaultValue={singleItem?.ipAddress || ""}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="port"
                        label="port"
                        type="number"
                        errorMessage="port required"
                        validate={{
                          required: { value: true },
                        }}
                        defaultValue={singleItem?.port || ""}
                      />
                    </div>
                  </Col>
                </Row>
                )}
                <Row className="align-items-start">
                  <Col md="6">
                    <div className="mb-3">
                      {printerType != "Local printer" ? (
                        <AvField type="select" name="connectType" label="Connect type" value={singleItem?.connectType} >
                          <option value="" hidden></option>
                          <option value="LAN" selected={singleItem?.connectType === 'LAN'}> LAN </option>
                          <option value="USB" selected={singleItem?.connectType === 'USB'}> USB </option>
                        </AvField>
                      ) : (
                        <AvField type="text" label="Actual name" name="ActualName" value={singleItem?.ActualName}/>
                      )}
                    </div>
                  </Col>
                  
                  {printerType != "Local printer" && (
                    <Col md="6">
                      <div className="d-flex flex-column mb-2 mt-4">
                        <div className="d-flex flex-column">
                          <label>Select menu category</label>
                          <select value={selectedCategory} onChange={onCategoryChange}>
                            {categories.map((cat: any) => (
                              <option key={cat.id} value={cat.id}>{cat.Name}</option>
                            ))}
                          </select>
                        </div>
                        
                        <div className="d-flex flex-column mt-2">
                          <label>Menu items</label>
                          <div className="d-flex justify-content-between w-[100%]">
                            <span>Select/unselect all</span>
                            <input type="checkbox" onChange={(e) => onItemChangeAll(e.target.checked)} checked={menuItems && menuItems.reduce((prev: boolean, item: any) => selectedItemIds.indexOf(item.id) >= 0 && prev, true)} />
                          </div>
                          {menuItems.map((item: any) => (
                            <div key={item.id} className="d-flex justify-content-between w-[100%]">
                              <span>{item.Name}</span>
                              <input type="checkbox" checked={selectedItemIds.indexOf(item.id) >= 0} onChange={() => onItemChange(item.id)} />
                            </div>
                          ))}
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>

                <Row style={{alignItems: 'flex-end'}}>
                  <Col>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user update-btn" disabled={loading}>
                        {loading ? (
                          <SpinnerCircular
                            size={30}
                            thickness={150}
                            speed={150}
                            color="#36ad47"
                            secondaryColor="rgba(0, 0, 0, 0.44)"
                          />
                        ) : (
                          <>Update</>
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            )}
              
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditPrinter);
