import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { SmsActionTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import {
  cancelCampaignSuccess,
  createSMSTemplateSuccess,
  deleteSMSTemplateSuccess,
  getAllCampaignsSuccess,
  getSMSTemplatesSuccess,
  sendCampaignSMSFailed,
  sendCampaignSMSSuccess,
  sendSingleSMSSuccess,
  sendSingleVMSSuccess,
  updateSMSTemplateSuccess
} from "./actions";

import {
  cancelCampaignAsync,
  createCampaignAsync,
  createSMSTemplatesAsync,
  deleteSMSTemplateAsync,
  getAllCampaignsAsync,
  getSMSTemplatesAsync,
  sendSignleSMSAsync,
  sendSignleVMAsync,
  updateSMSTemplateAsync
} from "../../helpers/clicksend_helper";
import { getCustomerByIdAsync, getRestaurantByIdAsync, getUserRestaurantId } from "../../helpers/backend_helper";

function* sendSingleSMS({payload: {customerId, from, message}}: any) {
  try {
    const response: IResponse = yield call(getCustomerByIdAsync, customerId);
    if (response.success) {
      const customer: any = response.result;
      if (customer.Mobile) {
        let authOptions = null;
        if (getUserRestaurantId() <= 0)  { // for admin
          const restaurantRes: IResponse = yield call(getRestaurantByIdAsync, customer.RestaurantId);
          authOptions = {username: restaurantRes.result.ClickSendUsername, password: restaurantRes.result.ClickSendApiKey};
        }
        const smsRes: IResponse = yield call(sendSignleSMSAsync, customer.Mobile, from, message, authOptions);
        if (smsRes.success) {
          yield put(sendSingleSMSSuccess(response.result));
        }
      }
    }
  } catch (error) {
    console.log(error)
  }
}

function* sendCampaignSMS({payload: {restaurantId, customers, from, message, name, scheduleTime}}: any) {
  try {
    const ret1: IResponse = yield call(getRestaurantByIdAsync, restaurantId);
    if (ret1.success && ret1.result) {      
      let authOptions = null;
      if (getUserRestaurantId() <= 0)  { // for admin
        authOptions = {username: ret1.result.ClickSendUsername, password: ret1.result.ClickSendApiKey};
      }
      const response: IResponse = yield call(createCampaignAsync, ret1.result.ShopName, restaurantId, customers, name, from, message, scheduleTime, authOptions);
      if (response.success) {
        yield put(sendCampaignSMSSuccess({campaign: response.result}));
      } else {
        yield put(sendCampaignSMSFailed(response.result))
      }
    } else {
      yield put(sendCampaignSMSFailed(ret1.result))
    }
  } catch (error) {
    console.log(error)
  }
}

function* cancelCampaign({payload: {campaignId}}: any) {
  try {
    const ret1: IResponse = yield call(cancelCampaignAsync, campaignId, null);
    if (ret1.success) {
      yield put(cancelCampaignSuccess(campaignId));
    }
  } catch (error) {
    console.log(error)
  }
}

function* getSMSTemplates() {
  try {    
    const response: IResponse = yield call(getSMSTemplatesAsync, null);
    if (response.success) {
      const templates: any = response.result;      
      yield put(getSMSTemplatesSuccess(templates))
    }
  } catch (error) {
    console.log(error)
  }  
}

function* createSMSTemplate({payload: {name, message}}: any) {
  try {
    const response: IResponse = yield call(createSMSTemplatesAsync, name, message, null);
    if (response.success) {
      yield put(createSMSTemplateSuccess(response.result))
    }
  } catch (error) {
    console.log(error)
  }  
}

function* deleteSMSTemplate({payload: {id}}: any) {
  try {
    const response: IResponse = yield call(deleteSMSTemplateAsync, id, null);
    if (response.success) {
      console.log("yield put(deleteSMS " + JSON.stringify(response))
      yield put(deleteSMSTemplateSuccess(id))
    }
  } catch (error) {
    console.log(error)
  }  
}

function* updateSMSTemplate({payload: {id, name, message}}: any) {
  try {
    const response: IResponse = yield call(updateSMSTemplateAsync, id, name, message, null);
    if (response.success) {
      yield put(updateSMSTemplateSuccess(response.result))
    }
  } catch (error) {
    console.log(error)
  }  
}

function* getAllCampaigns() {
  try {
    const response: IResponse = yield call(getAllCampaignsAsync, null);
    if (response.success) {      
      let campaigns = response.result;
      const restaurantId = getUserRestaurantId();
      if (restaurantId > 0) {
        const restaurnatRes: IResponse = yield call(getRestaurantByIdAsync, restaurantId);
        if (restaurnatRes.success && restaurnatRes.result) {
          campaigns = campaigns.filter((t: any) => t.subaccount_id === restaurnatRes.result.ClickSendSubAcctId)
        }
      }
      yield put(getAllCampaignsSuccess(campaigns))
    }
  } catch (error) {
    console.log(error)
  }  
}

function* sendSingleVM({payload: {telNo, message}}: any) {
  try {
    const vmRes: IResponse = yield call(sendSignleVMAsync, telNo, message);
    if (vmRes.success) {
      yield put(sendSingleVMSSuccess(vmRes.result));
    }
  } catch (error) {
    console.log(error)
  }
}

function* smsSaga() {
    yield takeEvery(SmsActionTypes.SEND_SINGLE_SMS, sendSingleSMS);
    yield takeEvery(SmsActionTypes.SEND_CAMPAIGN_SMS, sendCampaignSMS);
    yield takeEvery(SmsActionTypes.GET_SMS_TEMPLATES, getSMSTemplates);
    yield takeEvery(SmsActionTypes.CREATE_SMS_TEMPLATES, createSMSTemplate);
    yield takeEvery(SmsActionTypes.DELETE_SMS_TEMPLATES, deleteSMSTemplate);
    yield takeEvery(SmsActionTypes.UPDATE_SMS_TEMPLATE, updateSMSTemplate);
    yield takeEvery(SmsActionTypes.GET_ALL_CAMPAIGNS, getAllCampaigns);
    yield takeEvery(SmsActionTypes.CANCEL_CAMPAIGN, cancelCampaign);
    yield takeEvery(SmsActionTypes.SEND_SINGLE_VM, sendSingleVM);
}
  
export default smsSaga;