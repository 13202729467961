export enum MenuToppingsActionTypes {
  GET_ALL_MENU_TOPPINGS = "GET_ALL_MENU_TOPPINGS",
  GET_ALL_MENU_TOPPINGS_SUCCESS = 'GET_ALL_MENU_TOPPINGS_SUCCESS',

  UPDATE_MENU_TOPPING_BY_ID = 'UPDATE_MENU_TOPPING_BY_ID',
  UPDATE_MENU_TOPPING_BY_ID_SUCCESS = 'UPDATE_MENU_TOPPING_BY_ID_SUCCESS',

  ADD_MENU_TOPPING = 'ADD_MENU_TOPPING',
  ADD_MENU_TOPPING_SUCCESS = 'ADD_MENU_TOPPING_SUCCESS',

  DELETE_MENU_TOPPING_BY_ID = 'DELETE_MENU_TOPPING_BY_ID',
  DELETE_MENU_TOPPING_BY_ID_SUCCESS = 'DELETE_MENU_TOPPING_BY_ID_SUCCESS',

  GET_MENU_TOPPING_BY_ID = 'GET_MENU_TOPPING_BY_ID',
  GET_MENU_TOPPING_BY_ID_SUCCESS = 'GET_MENU_TOPPING_BY_ID_SUCCESS',

  TOPPING_API_RETURN_ERROR = 'TOPPING_API_RETURN_ERROR'
}
