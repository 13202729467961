import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, useParams, Link, useHistory } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getRestaurantById, updateRestaurantById } from "../../store/actions";
import { Card, CardBody, Col, Container, Label, Row, Spinner } from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
import { SpinnerCircular } from "spinners-react";
import CurrencyList from 'currency-list';

const AllOrderTypes = ["ToGo", "Dinein", "Delivery", "Collection"];

const EditRestaurant = (props: any) => {
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const history = useHistory();

  const { singleRestaurant, loading } = useSelector((state: any) => state.restaurant);
  const [supportingOrderTypes, setSupportingOrderTypes] = useState<any>([]);

  useEffect(() => {
    dispatch(getRestaurantById(id));
  }, []);

  useEffect(() => {
    if (singleRestaurant) {
      if (singleRestaurant.SupportingOrderTypes){
        setSupportingOrderTypes(singleRestaurant.SupportingOrderTypes.split("").reduce((pr: any, ot: string, idx: number) => ot === "1" ? [...pr, AllOrderTypes[idx]] : pr, []))
      } else {
        setSupportingOrderTypes([]);
      }
    }
    console.log(JSON.stringify(singleRestaurant));
  }, [singleRestaurant])

  const handleEditRestaurant = (values: any) => {
    dispatch(updateRestaurantById(id, {
      ...values,
      SupportingOrderTypes: AllOrderTypes.map((ot) => supportingOrderTypes.indexOf(ot) >= 0 ? "1" : "0").join("")
    }, history, false));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleEditRestaurant(values);
            }}
          >
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="ShopName"
                      label="ShopName"
                      type="text"
                      errorMessage="ShopName"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleRestaurant?.ShopName || ""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="BuildingName"
                      label="BuildingName"
                      type="text"
                      errorMessage="BuildingName"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleRestaurant?.BuildingName || ""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Street"
                      label="Street"
                      type="text"
                      errorMessage="Street"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleRestaurant?.Street || ""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="City"
                      label="City"
                      type="text"
                      errorMessage="Invalid City"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleRestaurant?.City || ""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Country"
                      label="Country"
                      type="text"
                      errorMessage="Invalid Country"
                      validate={{
                        required: { value: false },
                      }}
                      value={singleRestaurant?.Country || ""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Tel1"
                      label="Tel1"
                      type="text"
                      errorMessage="Invalid Tel1"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleRestaurant?.Tel1 || ""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Tel2"
                      label="Tel2"
                      type="text"
                      errorMessage="Invalid Tel2"
                      validate={{
                        required: { value: false },
                      }}
                      value={singleRestaurant?.Tel2 || ""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="WebURL"
                      label="WebURL"
                      type="text"
                      errorMessage="Invalid WebURL"
                      validate={{
                        required: { value: false },
                      }}
                      value={singleRestaurant?.WebURL || ""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="email"
                      type="text"
                      errorMessage="Invalid email"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleRestaurant?.email || ""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="FreeAgentEmail"
                      label="FreeAgentEmail"
                      type="text"
                      errorMessage="Invalid freeagent email"
                      validate={{
                        required: { value: false },
                      }}
                      value={singleRestaurant?.FreeAgentEmail || ""}
                    />
                  </div>
                </Col>
              </Row>

              <Row style={{alignItems: 'flex-end'}}>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="RestaurantType"
                      label="RestaurantType"
                      type="select"
                      value={singleRestaurant?.RestaurantType || "0"}
                    >
                      <option key={0} value={0}>Hospitality</option>
                      <option key={1} value={1}>Retail</option>
                    </AvField>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Currency"
                      label="Currency"
                      type="select"
                      value={singleRestaurant?.Currency || "USD"}
                    >
                      <option key="None" value="">Not selected</option>
                      {Object.values(CurrencyList.getAll("en_GB")).map((cur: any) => (
                        <option key={cur.code} value={cur.symbol}>{`${cur.code} - ${cur.name} (${cur.symbol})` }</option>
                      ))}
                    </AvField>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="WebsiteAPIKey"
                      label="Website API Key"
                      type="password"
                      value={singleRestaurant?.WebsiteAPIKey || ""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="WebsiteAPISecret"
                      label="Website API Secret"
                      type="password"
                      value={singleRestaurant?.WebsiteAPISecret || ""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Label>Supported order types</Label>
                  {AllOrderTypes.map((orderType) => (
                    <div className="mb-1">
                      <AvField
                        name={"Support" + orderType}
                        label={orderType}
                        type="checkbox"
                        value={supportingOrderTypes.indexOf(orderType) >= 0}
                        onChange={(e: any) => setSupportingOrderTypes((ot: any) => 
                          ot.indexOf(orderType) >= 0 ? 
                          ot.filter((val: string) => val != orderType) :
                          [...ot, orderType]
                        )}
                      />
                    </div>
                  ))}
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="forceOrderType"
                      label="Force order type"
                      type="checkbox"
                      value={!!singleRestaurant?.forceOrderType}
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="syncOrder"
                      label="Auto Sync Order"
                      type="checkbox"
                      value={!!singleRestaurant?.syncOrder}
                    />
                  </div>
                </Col>
              </Row>

              <Row style={{justifyContent: 'flex-end'}}>                
                <Col md="6">
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user update-btn" disabled={loading}>
                        {loading ? (
                          <SpinnerCircular
                            size={30}
                            thickness={150}
                            speed={150}
                            color="#36ad47"
                            secondaryColor="rgba(0, 0, 0, 0.44)"
                          />
                        ) : (
                          <>Update</>
                        )}
                      </button>
                    </div>
                  </Col>
              </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditRestaurant);
