export enum SubMenuItemsActionTypes {
  GET_ALL_SUBMENUITEMS = "GET_ALL_SUBMENUITEMS",
  GET_ALL_SUBMENUITEMS_SUCCESS = 'GET_ALL_SUBMENUITEMS_SUCCESS',

  GET_MENUITEM_SUBMENUITEMS = 'GET_MENUITEM_SUBMENUITEMS',
  GET_MENUITEM_SUBMENUITEMS_SUCCESS = 'GET_MENUITEM_SUBMENUITEMS_SUCCESS',

  UPDATE_SUBMENUITEM_BY_ID = 'UPDATE_SUBMENUITEM_BY_ID',
  UPDATE_SUBMENUITEM_BY_ID_SUCCESS = 'UPDATE_SUBMENUITEM_BY_ID_SUCCESS',

  ADD_SUBMENUITEM = 'ADD_SUBMENUITEM',
  ADD_SUBMENUITEM_SUCCESS = 'ADD_SUBMENUITEM_SUCCESS',

  DELETE_SUBMENUITEM_BY_ID = 'DELETE_SUBMENUITEM_BY_ID',
  DELETE_SUBMENUITEM_BY_ID_SUCCESS = 'DELETE_SUBMENUITEM_BY_ID_SUCCESS',

  GET_SUBMENUITEM_BY_ID = 'GET_SUBMENUITEM_BY_ID',
  GET_SUBMENUITEM_BY_ID_SUCCESS = 'GET_SUBMENUITEM_BY_ID_SUCCESS',

  SMI_API_RETURN_ERROR = 'SMI_API_RETURN_ERROR'
}
