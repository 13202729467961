import { LoginTypes } from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  currentUser: null,
  success: false,
  errorMsg: "",
};

const login = (state = initialState, action: any) => {
  switch (action.type) { 
    case LoginTypes.LOGIN_ADMIN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LoginTypes.LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        currentUser: action.payload,
        success: true,
      };
      break;
    case LoginTypes.LOGIN_FAIL:
      state = {
        ...state,
        loading: false,
        success: action.payload.success,
        errorMsg: action.payload.message,
      };
      break;
    case LoginTypes.SET_AUTH_USER:
      state = {
        ...state,
        currentUser: action.payload.user,
      };
      break;

    case LoginTypes.LOGOUT_USER:
      state = { ...state };
      break;
    case LoginTypes.LOGOUT_USER_SUCCESS:
      state = { ...state };
      break;
    case LoginTypes.API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
