import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { StatsTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import { getAllStatsSuccess } from "./actions";

import { getAllStatsAsync, getUserRestaurantId } from "../../helpers/backend_helper";

function* getAllStats({payload:restaurantId}:any) {
  try {
    const response: IResponse = yield call(getAllStatsAsync, restaurantId);
    if(response.success) {
      yield put(getAllStatsSuccess(response.result));
    } 
  } catch (error) {
    console.log(error)
  }
}

function* statsSaga() {
    yield takeEvery(StatsTypes.GET_ALL_STATS, getAllStats)
}
  
export default statsSaga;