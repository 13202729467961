import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAuthUser } from "../../store/actions";
import jwtDecode from 'jwt-decode'

interface AuthLayoutProps {
  component: any;
  layout: any;
  isAuthProtected: any;
  path?: string;
  exact?: boolean;
  key?: number;
}

const Authmiddleware = ({
  component,
  layout,
  isAuthProtected,
  path,
  exact,
  key,
  ...rest
}: AuthLayoutProps) => {
  const dispatch = useDispatch();
  const Layout = layout;
  const Component = component;

  useEffect(() => {
    const accessToken:any = localStorage.getItem('accessToken')
    if(accessToken) {
      const authUser: any = jwtDecode(accessToken)
      dispatch(setAuthUser(authUser));
    }
  }, [])

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !localStorage.getItem("authUser")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default Authmiddleware;
