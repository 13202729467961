import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { getSMSTemplates, createSMSTemplate, deleteSMSTemplate, sendCampaignSMS } from "../../store/sms/actions";
import Select from 'react-select';
import { getRestaurantById, updateRestaurantById } from "../..//store/actions";


const SendSMS = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sendingCampaign, sendingCampaignSuccess, smsTemplates, sendingCampaignError } = useSelector((state: any) => state.sms);
  const { singleRestaurant, loading: loadingRestaurant } = useSelector((state: any) => state.restaurant);
  const [formValues, setFormValues] = useState<any>({})
  const [showCreate, setShowCreate] = useState(false)
  const [showSchedule, setShowSchedule] = useState(false)

  useEffect(() => {
    if (props.open) {
      dispatch(getSMSTemplates());
      dispatch(getRestaurantById(props.restaurantId));
    }
    setShowCreate(false)
  }, [props.open])

  useEffect(() => {
    if (sendingCampaignSuccess) {
      props.onDrawerClose(false);
      history.push("/campaigns")
    }
  }, [sendingCampaignSuccess]);

  useEffect(() => {
    if (formValues.SMSTemplate) {
      const v = smsTemplates.find((t: any) => t.template_name === formValues.SMSTemplate.label);
      if (!v) {
        setFormValues({...formValues, SMSTemplate: {
          label: smsTemplates.length > 0 ? smsTemplates[0].template_name : "",
          value: smsTemplates.length > 0 ? smsTemplates[0].body : ""
        }})
      }
    }
  }, [smsTemplates])

  useEffect(() => {
    if (singleRestaurant && singleRestaurant.senderId && !loadingRestaurant) {
      setFormValues((val: any) => ({...val, From: singleRestaurant.senderId}))
    }
  }, [singleRestaurant, loadingRestaurant])

  const handleSendMessage = (e: any) => {
    if (!!formValues.From && !!formValues.Message && !!formValues.CampaignName) {
      const scheduleTime = showSchedule 
        ? Math.floor(new Date(formValues.ScheduleDate + " " + formValues.ScheduleTime).getTime()/1000)
        : Math.floor(new Date().getTime()/1000);
      console.log("scheduleTime " + scheduleTime)
      dispatch(sendCampaignSMS(props.restaurantId, formValues.CampaignName, props.customers, formValues.From, formValues.Message, scheduleTime));
      dispatch(updateRestaurantById(props.restaurantId, {senderId: formValues.From}, null, false));
    }
  };

  const handleFormChange = (e: any) => {
    setFormValues((values: any) => ({...values, [e.target.name]: e.target.value}))
  }

  const handleTemplateChange = (newValue: any) => {
    setFormValues((values: any) => ({...values, Message: newValue.value, SMSTemplate: newValue}))
  }


  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Add New Admin</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-admin-form" style={{ padding: 20 }}>
          <AvForm>
            <div className="add-company-form-cotainer">
              <Row>
                <Col md="12">
                  <h4 className="mt-4 mb-4">Send SMS to customers</h4>

                  <p>{props.customers.length} customers are selected to send message</p>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <div className="mb-3">
                    <AvField
                      name="CampaignName"
                      label="Campaign name"
                      type="text"
                      placeholder="Type campaign name"
                      errorMessage="Input campaign name"
                      validate={{
                        required: { value: true },
                      }}
                      value={formValues.CampaignName}
                      onChange={handleFormChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <div className="mb-3">
                    <AvField
                      name="From"
                      label="From"
                      type="text"
                      maxLength={11}
                      placeholder="Type senderId (will be saved when submit SMS for later usage)"
                      errorMessage="Input senderId"
                      validate={{
                        required: { value: true },
                      }}
                      value={formValues.From}
                      onChange={handleFormChange}
                    />
                  </div>
                </Col>
              </Row>
              
              <Row>
                <p><b>
                  Type message or select from templates
                </b></p>
              </Row>

              <Row className="mb-3">
                <Col md="12">
                  <Select
                    name="SMSTemplate"
                    options={smsTemplates.map((t: any) => ({value: t.body, label: t.template_name}))}
                    type="textarea"
                    validate={{
                      required: { value: true },
                    }}
                    value={formValues.SMSTemplate}
                    onChange={handleTemplateChange}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <div className="mb-3">
                    <AvField
                      name="Message"
                      type="textarea"
                      errorMessage="Input message"
                      validate={{
                        required: { value: true },
                      }}
                      style={{height: 150}}
                      value={formValues.Message}
                      onChange={handleFormChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="10">
                  <AvField
                    name="SetSchedule"
                    type="checkbox"
                    label="Set schedule time"
                    validate={{
                      required: { value: false },
                    }}
                    value={showSchedule}
                    onChange={(e: any) => setShowSchedule(e.target.checked)}
                  />
                </Col>
              </Row>

              {showSchedule && (                
                <Row>
                  <Col md="6">
                    <AvField
                      name="ScheduleDate"
                      type="date"
                      label="Schedule date"
                      errorMessage="Set schedule date"
                      validate={{
                        required: { value: true },
                      }}
                      value={formValues.ScheduleDate}
                      onChange={handleFormChange}
                    />
                  </Col>
                  <Col md="6">
                    <AvField
                      name="ScheduleTime"
                      type="time"
                      label="Schedule time"
                      errorMessage="Set schedule time"
                      validate={{
                        required: { value: true },
                      }}
                      value={formValues.ScheduleTime}
                      onChange={handleFormChange}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col md="12">
                  <p>
                    {!sendingCampaign && sendingCampaignError && (
                      <i style={{color: "red"}}>Error occurred: {sendingCampaignError}</i>
                    )}
                  </p>
                  <div className="text-end">
                    <button type="submit" formAction="sendMessage" className="btn btn-success" disabled={sendingCampaign} onClick={handleSendMessage}>
                      {sendingCampaign ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : (
                        <>Create campaign</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(SendSMS);
