export enum PurchaseOrdersActionTypes {
  GET_RESTAURANT_PURCHASE_ORDERS = "GET_RESTAURANT_PURCHASE_ORDERS",
  GET_RESTAURANT_PURCHASE_ORDERS_SUCCESS = 'GET_RESTAURANT_PURCHASE_ORDERS_SUCCESS',

  UPDATE_PURCHASE_ORDER_BY_ID = 'UPDATE_PURCHASE_ORDER_BY_ID',
  UPDATE_PURCHASE_ORDER_BY_ID_SUCCESS = 'UPDATE_PURCHASE_ORDER_BY_ID_SUCCESS',

  ADD_PURCHASE_ORDER = 'ADD_PURCHASE_ORDER',
  ADD_PURCHASE_ORDER_SUCCESS = 'ADD_PURCHASE_ORDER_SUCCESS',

  DELETE_PURCHASE_ORDER_BY_ID = 'DELETE_PURCHASE_ORDER_BY_ID',
  DELETE_PURCHASE_ORDER_BY_ID_SUCCESS = 'DELETE_PURCHASE_ORDER_BY_ID_SUCCESS',

  GET_PURCHASE_ORDER_BY_ID = 'GET_PURCHASE_ORDER_BY_ID',
  GET_PURCHASE_ORDER_BY_ID_SUCCESS = 'GET_PURCHASE_ORDER_BY_ID_SUCCESS',

  PURCHASE_ORDER_API_RETURN_ERROR = 'PURCHASE_ORDER_API_RETURN_ERROR'
}
