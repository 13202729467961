export enum SuppliersActionTypes {
  GET_RESTAURANT_SUPPLIERS = "GET_RESTAURANT_SUPPLIERS",
  GET_RESTAURANT_SUPPLIERS_SUCCESS = 'GET_RESTAURANT_SUPPLIERS_SUCCESS',

  UPDATE_SUPPLIER_BY_ID = 'UPDATE_SUPPLIER_BY_ID',
  UPDATE_SUPPLIER_BY_ID_SUCCESS = 'UPDATE_SUPPLIER_BY_ID_SUCCESS',

  ADD_SUPPLIER = 'ADD_SUPPLIER',
  ADD_SUPPLIER_SUCCESS = 'ADD_SUPPLIER_SUCCESS',

  DELETE_SUPPLIER_BY_ID = 'DELETE_SUPPLIER_BY_ID',
  DELETE_SUPPLIER_BY_ID_SUCCESS = 'DELETE_SUPPLIER_BY_ID_SUCCESS',

  GET_SUPPLIER_BY_ID = 'GET_SUPPLIER_BY_ID',
  GET_SUPPLIER_BY_ID_SUCCESS = 'GET_SUPPLIER_BY_ID_SUCCESS',

  SUPPLIER_API_RETURN_ERROR = 'SUPPLIER_API_RETURN_ERROR'
}
