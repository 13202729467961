import { PurchaseOrdersActionTypes } from "./actionTypes";

//get all dmin by sitecode
export const getRestaurantPurchaseOrders = (id: string) => ({
  type: PurchaseOrdersActionTypes.GET_RESTAURANT_PURCHASE_ORDERS,
  payload: id
});

export const getRestaurantPurchaseOrdersSuccess = (categories: any) => ({
  type: PurchaseOrdersActionTypes.GET_RESTAURANT_PURCHASE_ORDERS_SUCCESS,
  payload: categories,
});

export const updatePurchaseOrderById = (id: string, item: any, history: any) => ({
  type: PurchaseOrdersActionTypes.UPDATE_PURCHASE_ORDER_BY_ID,
  payload: {id, item, history}
})

export const updatePurchaseOrderByIdSuccess = (item: any) => ({
  type: PurchaseOrdersActionTypes.UPDATE_PURCHASE_ORDER_BY_ID_SUCCESS,
  payload: item,
});


export const storePurchaseOrder = (item: any) => ({
  type: PurchaseOrdersActionTypes.ADD_PURCHASE_ORDER,
  payload: item,
});

export const storePurchaseOrderSuccess = (item: any) => ({
  type: PurchaseOrdersActionTypes.ADD_PURCHASE_ORDER_SUCCESS,
  payload: item,
});

export const deletePurchaseOrder = (id: string) => ({
  type: PurchaseOrdersActionTypes.DELETE_PURCHASE_ORDER_BY_ID,
  payload: id
});

export const deletePurchaseOrderSuccess = (id: string) => ({
  type: PurchaseOrdersActionTypes.DELETE_PURCHASE_ORDER_BY_ID_SUCCESS,
  payload: id
})

export const getPurchaseOrderById = (id: string) => ({
  type: PurchaseOrdersActionTypes.GET_PURCHASE_ORDER_BY_ID,
  payload: id
})

export const getPurchaseOrderByIdSuccess = (category: any) => ({
  type: PurchaseOrdersActionTypes.GET_PURCHASE_ORDER_BY_ID_SUCCESS,
  payload: category
})

export const purchaseOrderApiCallError = (error: any) => ({
  type: PurchaseOrdersActionTypes.PURCHASE_ORDER_API_RETURN_ERROR,
  payload: error
})

