export enum BookingActionTypes {
  GET_RESTAURANT_BOOKINGS = "GET_RESTAURANT_BOOKINGS",
  GET_RESTAURANT_BOOKINGS_SUCCESS = 'GET_RESTAURANT_BOOKINGS_SUCCESS',
  GET_BOOKING_BY_ID = "GET_BOOKING_BY_ID",
  GET_BOOKING_BY_ID_SUCCESS = "GET_BOOKING_BY_ID_SUCCESS",
  STORE_BOOKING = "STORE_BOOKING",
  STORE_BOOKING_SUCCESS = 'STORE_BOOKING_SUCCESS',
  UPDATE_BOOKING_BY_ID = "UPDATE_BOOKING_BY_ID",
  UPDATE_BOOKING_BY_ID_SUCCESS = "UPDATE_BOOKING_BY_ID_SUCCESS",
  DELETE_BOOKING_BY_ID="DELETE_BOOKING_BY_ID",
  DELETE_BOOKING_BY_ID_SUCCESS="DELETE_BOOKING_BY_ID_SUCCESS",
}
