import { MenuActionTypes } from "./actionTypes";

export const INIT_STATE: any = {
  selectedRestaurantId: '',
  categories: [],
  singleCategory: {},
  error: {},
  loading: false,
  success: false,
};

const menu = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case MenuActionTypes.GET_ALL_MENU_CATEGORIES:
      return {
        ...state,
        loading: true,
      };

    case MenuActionTypes.GET_ALL_MENU_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    
    case MenuActionTypes.GET_MENU_CATEGORY_BY_ID:
      return {
        ...state,
        loading: true
      }
    
    case MenuActionTypes.GET_MENU_CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleCategory: action.payload
      }

    case MenuActionTypes.GET_RESTAURANT_MENU_CATEGORIES:
      return {
        ...state,
        loading: true,
        selectedRestaurantId: action.payload
      };

    case MenuActionTypes.GET_RESTAURANT_MENU_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };

    case MenuActionTypes.UPDATE_MENU_CATEGORY_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case MenuActionTypes.UPDATE_MENU_CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleCategory: action.payload,
        categories: state.categories.map((c: any) => c.id === action.payload.id ? action.payload : c)
      };
    case MenuActionTypes.DELETE_MENU_CATEGORY_BY_ID:
      return {
        ...state,
        loading: true
      }

    case MenuActionTypes.DELETE_MENU_CATEGORY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: state.categories.filter((c: any) => c.id !== parseInt(action.payload.toString()))
      }

    case MenuActionTypes.ADD_MENU_CATEGORY:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case MenuActionTypes.ADD_MENU_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        categories: [...state.categories, action.payload],
      };
    
    case MenuActionTypes.API_RETURN_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      }

    default:
      return state;
  }
};

export default menu;
