import { MigrationTypes } from "./actionTypes";

export const getAllMigrations = () => ({
  type: MigrationTypes.GET_ALL_MIGRATIONS,
});

export const getAllMigrationsSuccess = (migrations: any) => ({
  type: MigrationTypes.GET_ALL_MIGRATIONS_SUCCESS,
  payload: migrations,
});

export const getMigrationById = (id: string) => ({
  type: MigrationTypes.GET_MIGRATION_BY_ID,
  payload: id,
});

export const getMigrationByIdSuccess = (migration: any) => ({
  type: MigrationTypes.GET_MIGRATION_BY_ID_SUCCESS,
  payload: migration,
});

export const deleteMigrationById = (id: string) => ({
  type: MigrationTypes.DELETE_MIGRATION_BY_ID,
  payload: id,
});

export const deleteMigrationByIdSuccess = (id: any) => ({
  type: MigrationTypes.DELETE_MIGRATION_BY_ID_SUCCESS,
  payload: id,
});

