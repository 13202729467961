import { call, put, takeEvery } from "redux-saga/effects";
import { LoginTypes } from "./actionTypes";
import { apiError, loginSuccess, loginFail } from "./actions";
import {IResponse, LoginResponse} from '../../../interfaces/general'
import { getRestaurantByIdAsync, loginAdminAsync } from '../../../helpers/backend_helper'
import jwtDecode from 'jwt-decode'
import { setAuthToken } from "src/helpers/api_helper";
import { updateClickSentAuth } from "src/helpers/clicksend_helper";

function* loginAdmin({ payload: { user, history } }: any) {
  try {
    const response: LoginResponse = yield call(loginAdminAsync, user);
    if(response.auth) {
      localStorage.setItem('accessToken', response.accessToken)
      setAuthToken(response.accessToken)
      let authUser:any = jwtDecode(response.accessToken)
      localStorage.setItem("authUser", JSON.stringify(authUser));
      if (authUser.isAdmin !== 1) {
        const restaurantRes: IResponse = yield call(getRestaurantByIdAsync, authUser.RestaurantId);
        if (restaurantRes.success) {
          updateClickSentAuth(restaurantRes.result.ClickSendUsername, restaurantRes.result.ClickSendApiKey);
        }
      }
      yield put(loginSuccess(authUser));
      history.push(`/dashboard`);
    } else {
      yield put(loginFail(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LoginTypes.LOGIN_ADMIN, loginAdmin);
}

export default authSaga;
