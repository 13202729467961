import { SmsActionTypes } from "./actionTypes";

export const INIT_STATE: any = {
  error: "",
  sendingSms: false,
  sendingSmsSuccess: false,
  sendingVM: false,
  sendingVMSuccess: false,

  sendingCampaign: false,
  sendingCampaignSuccess: false,
  sendingCampaignError: null,

  campaignsLoading: false,
  cancelingCampaign: false,

  loadingTemplates: false,
  smsTemplates: [],
  creatingTemplate: false,
  creatingTemplateSuccess: false,
  updatingTemplate: false,
  updatingTemplateSuccess: false,
  campaigns: [],
};

const stats = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case SmsActionTypes.SEND_SINGLE_SMS:
      return {
        ...state,
        sendingSms: true,        
        sendingSmsSuccess: false
      };
    case SmsActionTypes.SEND_SINGLE_SMS_SUCCESS:
      return {
        ...state,
        sendingSms: false,
        sendingSmsSuccess: true
      };
    
    case SmsActionTypes.SEND_SINGLE_VM:
      return {
        ...state,
        sendingVM: true,        
        sendingVMSuccess: false
      };
    case SmsActionTypes.SEND_SINGLE_VM_SUCCESS:
      return {
        ...state,
        sendingVM: false,
        sendingVMSuccess: true
      };
    case SmsActionTypes.SEND_CAMPAIGN_SMS:
      return {
        ...state,
        sendingCampaign: true,
        campaignSms: action.payload,
        sendingCampaignError: ""
      };
    case SmsActionTypes.SEND_CAMPAIGN_SMS_SUCCESS:
      return {
        ...state,
        sendingCampaign: false,
        sendingCampaignSuccess: true
      };
    case SmsActionTypes.SEND_CAMPAIGN_SMS_FAILED:
      return {
        ...state,
        sendingCampaign: false,
        sendingCampaignSuccess: false,
        sendingCampaignError: action.payload
      }
    case SmsActionTypes.GET_SMS_TEMPLATES:
      return {
        ...state,
        loadingTemplates: true,
        creatingTemplateSuccess: false,
        updatingTemplateSuccess: false
      }
    case SmsActionTypes.GET_SMS_TEMPLATES_SUCCESS:
      return {
        ...state,
        smsTemplates: action.payload,
        loadingTemplates: false
      }
    case SmsActionTypes.CREATE_SMS_TEMPLATES:
      return {
        ...state,
        creatingTemplate: true,
        creatingTemplateSuccess: false,
      }
    case SmsActionTypes.CREATE_SMS_TEMPLATES_SUCCESS:
      return {
        ...state,
        creatingTemplate: false,
        creatingTemplateSuccess: true,
        smsTemplates: [
          ...state.smsTemplates,
          action.payload
        ]
      }
    case SmsActionTypes.UPDATE_SMS_TEMPLATE:
      return {
        ...state,
        updatingTemplate: true,
        updatingTemplateSuccess: false
      }
    case SmsActionTypes.UPDATE_SMS_TEMPLATE_SUCCESS:
      return {
        ...state,
        updatingTemplate: false,
        updatingTemplateSuccess: true,
        smsTemplates: state.smsTemplates.map((t: any) => (t.template_id === action.payload.template_id ? action.payload : t))
      }
    case SmsActionTypes.DELETE_SMS_TEMPLATES_SUCCESS:
      console.log("action payload id " + JSON.stringify(action.payload))
      return {
        ...state,
        smsTemplates: state.smsTemplates.filter((t: any) => t.template_id !== action.payload.id)
      }
    case SmsActionTypes.UPDATE_SMS_TEMPLATE_SUCCESS:
      return {
        ...state,
        smsTemplates: [
          ...state.smsTemplates.filter((t: any) => t.id === action.payload.id),
          action.payload
        ]
      }
    case SmsActionTypes.GET_ALL_CAMPAIGNS:
      return {
        ...state,
        campaignsLoading: true,
        sendingCampaignSuccess: false
      }
    case SmsActionTypes.GET_ALL_CAMPAIGNS_SUCCESS: 
      return {
        ...state,
        campaigns: action.payload,
        campaignsLoading: false
      }
    case SmsActionTypes.CANCEL_CAMPAIGN:
      return {
        ...state,
        cancelingCampaign: true
      }
    case SmsActionTypes.CANCEL_CAMPAIGN_SUCCESS:
      return {
        ...state,
        cancelingCampaign: false
      }
    default:
      return state;
  }
};

export default stats;
