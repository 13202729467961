import { PrintersActionTypes } from "./actionTypes";

export const INIT_STATE: any = {
  items: [],
  singleItem: {},
  error: {},
  loading: false,
  success: false,
};

const printers = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case PrintersActionTypes.GET_RESTAURANT_PRINTERS:
      return {
        ...state,
        loading: true,
      };

    case PrintersActionTypes.GET_RESTAURANT_PRINTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };
    
    case PrintersActionTypes.GET_PRINTER_BY_ID:
      return {
        ...state,
        loading: true
      }
    
    case PrintersActionTypes.GET_PRINTER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleItem: action.payload
      }

    case PrintersActionTypes.UPDATE_PRINTER_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case PrintersActionTypes.UPDATE_PRINTER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleItem: action.payload,
        items: state.items.map((c: any) => c.id === action.payload.id ? action.payload : c)
      };
    case PrintersActionTypes.DELETE_PRINTER_BY_ID:
      return {
        ...state,
        loading: true
      }

    case PrintersActionTypes.DELETE_PRINTER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter((c: any) => c.id !== parseInt(action.payload.toString()))
      }

    case PrintersActionTypes.ADD_PRINTER:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case PrintersActionTypes.ADD_PRINTER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        items: [...state.items, action.payload],
      };
    
    case PrintersActionTypes.PRINTERS_API_RETURN_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      }

    default:
      return state;
  }
};

export default printers;
