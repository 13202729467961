import { SuppliersActionTypes } from "./actionTypes";

//get all dmin by sitecode
export const getRestaurantSuppliers = (id: string) => ({
  type: SuppliersActionTypes.GET_RESTAURANT_SUPPLIERS,
  payload: id
});

export const getRestaurantSuppliersSuccess = (categories: any) => ({
  type: SuppliersActionTypes.GET_RESTAURANT_SUPPLIERS_SUCCESS,
  payload: categories,
});

export const updateSupplierById = (id: string, item: any, history: any) => ({
  type: SuppliersActionTypes.UPDATE_SUPPLIER_BY_ID,
  payload: {id, item, history}
})

export const updateSupplierByIdSuccess = (item: any) => ({
  type: SuppliersActionTypes.UPDATE_SUPPLIER_BY_ID_SUCCESS,
  payload: item,
});


export const storeSupplier = (item: any) => ({
  type: SuppliersActionTypes.ADD_SUPPLIER,
  payload: item,
});

export const storeSupplierSuccess = (item: any) => ({
  type: SuppliersActionTypes.ADD_SUPPLIER_SUCCESS,
  payload: item,
});

export const deleteSupplier = (id: string) => ({
  type: SuppliersActionTypes.DELETE_SUPPLIER_BY_ID,
  payload: id
});

export const deleteSupplierSuccess = (id: string) => ({
  type: SuppliersActionTypes.DELETE_SUPPLIER_BY_ID_SUCCESS,
  payload: id
})

export const getSupplierById = (id: string) => ({
  type: SuppliersActionTypes.GET_SUPPLIER_BY_ID,
  payload: id
})

export const getSupplierByIdSuccess = (category: any) => ({
  type: SuppliersActionTypes.GET_SUPPLIER_BY_ID_SUCCESS,
  payload: category
})

export const supplierApiCallError = (error: any) => ({
  type: SuppliersActionTypes.SUPPLIER_API_RETURN_ERROR,
  payload: error
})

