import { EmployeeTypes } from "./actionTypes";

export const getAllEmployees = () => ({
  type: EmployeeTypes.GET_ALL_EMPLOYEES,
});

export const getAllEmployeesSuccess = (employees: any) => ({
  type: EmployeeTypes.GET_ALL_EMPLOYEES_SUCCESS,
  payload: employees,
});

export const getRestaurantEmployees = (restaurantId: string) => ({
  type: EmployeeTypes.GET_RESTAURANT_EMPLOYEES,
  payload: restaurantId
});

export const getRestaurantEmployeesSuccess = (employees: any) => ({
  type: EmployeeTypes.GET_RESTAURANT_EMPLOYEES_SUCCESS,
  payload: employees,
});

export const getRestaurantDrivers = (restaurantId: string) => ({
  type: EmployeeTypes.GET_RESTAURANT_DRIVERS,
  payload: restaurantId
});

export const getRestaurantDriversSuccess = (drivers: any) => ({
  type: EmployeeTypes.GET_RESTAURANT_DRIVERS_SUCCESS,
  payload: drivers
});

export const getEmployeeById = (employeeId: string) => ({
  type: EmployeeTypes.GET_EMPLOYEE_BY_ID,
  payload: employeeId
});

export const getEmployeeByIdSuccess = (employee: any) => ({
  type: EmployeeTypes.GET_EMPLOYEE_BY_ID_SUCCESS,
  payload: employee,
});

export const addEmployee = (employee: any) => ({
  type: EmployeeTypes.ADD_EMPLOYEE,
  payload: employee
});

export const addEmployeeSuccess = (employee: any) => ({
  type: EmployeeTypes.ADD_EMPLOYEE_SUCCESS,
  payload: employee
});

export const updateEmployee = (id: string, employee: any) => ({
  type: EmployeeTypes.UPDATE_EMPLOYEE,
  payload: {
    id, employee
  }
});

export const updateEmployeeSuccess = (employee: any) => ({
  type: EmployeeTypes.UPDATE_EMPLOYEE_SUCCESS,
  payload: employee
});

export const deleteEmployee = (id: string) => ({
  type: EmployeeTypes.DELETE_EMPLOYEE,
  payload: id
});

export const deleteEmployeeSuccess = (id: string) => ({
  type: EmployeeTypes.DELETE_EMPLOYEE_SUCCESS,
  payload: id
});