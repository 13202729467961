import { MenuDressingsActionTypes } from "./actionTypes";

export const INIT_STATE: any = {
  items: [],
  singleItem: {},
  error: {},
  loading: false,
  success: false,
};

const menu = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case MenuDressingsActionTypes.GET_ALL_MENU_DRESSINGS:
      return {
        ...state,
        loading: true,
      };

    case MenuDressingsActionTypes.GET_ALL_MENU_DRESSINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };
    
    case MenuDressingsActionTypes.GET_MENU_DRESSING_BY_ID:
      return {
        ...state,
        loading: true
      }
    
    case MenuDressingsActionTypes.GET_MENU_DRESSING_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleItem: action.payload
      }

    case MenuDressingsActionTypes.UPDATE_MENU_DRESSING_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case MenuDressingsActionTypes.UPDATE_MENU_DRESSING_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleItem: action.payload,
        items: state.items.map((c: any) => c.id === action.payload.id ? action.payload : c)
      };
    case MenuDressingsActionTypes.DELETE_MENU_DRESSING_BY_ID:
      return {
        ...state,
        loading: true
      }

    case MenuDressingsActionTypes.DELETE_MENU_DRESSING_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter((c: any) => c.id !== parseInt(action.payload.toString()))
      }

    case MenuDressingsActionTypes.ADD_MENU_DRESSING:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case MenuDressingsActionTypes.ADD_MENU_DRESSING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        items: [...state.items, action.payload],
      };
    
    case MenuDressingsActionTypes.DRESSING_API_RETURN_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      }

    default:
      return state;
  }
};

export default menu;
