export enum MenuItemsActionTypes {
  GET_ALL_MENU_ITEMS = "GET_ALL_MENU_ITEMS",
  GET_ALL_MENU_ITEMS_SUCCESS = 'GET_ALL_MENU_ITEMS_SUCCESS',

  GET_CATEGORY_MENU_ITEMS = 'GET_CATEGORY_MENU_ITEMS',
  GET_CATEGORY_MENU_ITEMS_SUCCESS = 'GET_CATEGORY_MENU_ITEMS_SUCCESS',

  GET_SUPPLIER_MENU_ITEMS = 'GET_SUPPLIER_MENU_ITEMS',
  GET_SUPPLIER_MENU_ITEMS_SUCCESS = 'GET_SUPPLIER_MENU_ITEMS_SUCCESS',

  UPDATE_MENU_ITEM_BY_ID = 'UPDATE_MENU_ITEM_BY_ID',
  UPDATE_MENU_ITEM_BY_ID_SUCCESS = 'UPDATE_MENU_ITEM_BY_ID_SUCCESS',

  ADD_MENU_ITEM = 'ADD_MENU_ITEM',
  ADD_MENU_ITEM_SUCCESS = 'ADD_MENU_ITEM_SUCCESS',

  DELETE_MENU_ITEM_BY_ID = 'DELETE_MENU_ITEM_BY_ID',
  DELETE_MENU_ITEM_BY_ID_SUCCESS = 'DELETE_MENU_ITEM_BY_ID_SUCCESS',

  GET_MENU_ITEM_BY_ID = 'GET_MENU_ITEM_BY_ID',
  GET_MENU_ITEM_BY_ID_SUCCESS = 'GET_MENU_ITEM_BY_ID_SUCCESS',

  API_RETURN_ERROR = 'API_RETURN_ERROR'
}
