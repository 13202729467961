import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row,  Card,CardTitle, CardBody, FormGroup, Label, Input, Alert} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { addLicense, getAllRestaurants } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";

const AddLicense = (props: any) => {
  const dispatch = useDispatch();

  const { success, loading, error } = useSelector((state: any) => state.license);
  const { restaurants } = useSelector( (state: any) => state.restaurant);

  const [inputData, setInputData] = useState({
    RestaurantId: '',
    licensee: "",
    product_key: "",
    device_type: '',
    force_override: '',
    force_override_notes: '',
    status: '',
    period: '',
    goCardlessId: '',
    features: {
      usemap: '',
      email: false,
      caller_id: false,
      website: false,
      sms: false,
      postcode: false,
      server: false
    },
    check_license: false,
    notes: '',
  })

  const { currentUser } = useSelector((state: any) => state.login);

  useEffect(() => {
    dispatch(getAllRestaurants());
  }, [])

  useEffect(() => {
    if (success) {
      props.onDrawerClose(false);
    }
  }, [success]);

  const handleAddLicense = (values: any) => {
    const licenseData = {
      userId: currentUser.id,
      RestaurantId: Number(inputData.RestaurantId),
      licensee: inputData.licensee,
      product_key: inputData.product_key,
      device_type: inputData.device_type,
      period: inputData.period,
      features: inputData.features,
      force_override: inputData.force_override === '1' ? true : false,
      force_override_notes: inputData.force_override_notes,
      status: inputData.status === '1' ? true : false,
      goCardlessId: inputData.goCardlessId,
      check_license: inputData.check_license,
      notes: inputData.notes,
      expiry_date: null
    }
    dispatch(addLicense(licenseData));
  };


  const handleChangeInput = (name: string) => (e: any) => {
    setInputData({ ...inputData, [name]: e.target.value })
  }

  const handleChangeInputCheckBox = (e: any) => {
    if (e.target.name === "force_override") {
      setInputData({ ...inputData, [e.target.name]: e.target.value, status: e.target.value })
    } else {
      setInputData({ ...inputData, [e.target.name]: e.target.value })
    }
  }

  const changeCheckLicense = (name: string) => (e: any) => {
    setInputData({ ...inputData, [name]: e.target.checked })
  }

  const changeFeature = (name: string) => (e: any) => {
    setInputData({ ...inputData, features: { ...inputData.features, [name]: e.target.checked } })
  }

  const changeUseMap = (name: string) => (e: any) => {
    setInputData({ ...inputData, features: { ...inputData.features, [name]: e.target.value } })
  }

  return (
    <React.Fragment>
      <div className="add-license">
        <MetaTags>
          <title>Add New License</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-license-form" style={{ padding: 20, width: 1000 }}>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleAddLicense(values);
            }}
          >
            <div className="add-license-form-cotainer">
              <div>
                <Card>
                  <CardBody style={{ padding: 4, background: '#008cd4' }}>
                    <CardTitle tag="h6" style={{ paddingLeft: 10, color: 'white' }}>License Information</CardTitle>
                  </CardBody>
                  <CardBody>

                    <FormGroup>
                      <div className="row mt-2 mb-2">
                        <div className="col-md-3">
                          <Label for="Restaurant_select">Select Restaurant</Label>
                        </div>
                        <div className="col-md-6">
                          <Input type="select" name="RestaurantId" value={inputData.RestaurantId} onChange={handleChangeInput("RestaurantId")}>
                            <option value="" hidden></option>
                            {restaurants?.map((restaurant: any, index: number) => {
                              return (
                                <option value={restaurant?.id} key = {index}>{restaurant?.BuildingName}</option>
                              )
                            })}
                          </Input>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-3">
                          <Label for="licensee">Licensee</Label>
                        </div>
                        <div className="col-md-6">
                          <Input type="text" name="licensee" id="licensee" placeholder="" onChange={handleChangeInput("licensee")} />
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-md-3">
                          <Label for="product_key">Product Key</Label>
                        </div>
                        <div className="col-md-6">
                          <Input type="text" name="product_key" id="product_key" placeholder="" onChange={handleChangeInput("product_key")} />
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-md-3">
                          <Label for="device_type">Device Type</Label>
                        </div>
                        <div className="col-md-6">
                          <Input type="select" name="device_type" value={inputData.device_type} onChange={handleChangeInput("device_type")}>
                            <option value="" hidden></option>
                            <option> Server </option>
                            <option> Client </option>
                          </Input>
                        </div>
                      </div>


                      <div className="row mt-2">
                        <div className="col-md-3">
                          <Label for="goCardlessId">GoCardless ID</Label>
                        </div>
                        <div className="col-md-6">
                          <Input type="text" name="goCardlessId" id="goCardlessId" placeholder="" onChange={handleChangeInput('goCardlessId')} />
                        </div>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>

                <Card className="mt-2">
                  <CardBody style={{ padding: 4, background: '#008cd4', color: 'white' }}>
                    <CardTitle tag="h6" style={{ paddingLeft: 10, color: 'white' }}>License Period</CardTitle>
                  </CardBody>
                  <CardBody>
                    <div >
                      <input type="radio" value="Trial"  checked = {inputData?.period === 'Trial'} onChange={handleChangeInput('period')} /> Trial
                      <input type="radio" value="Monthly" checked = {inputData?.period === 'Monthly'} onChange={handleChangeInput('period')} style = {{marginLeft: 10}}/> Monthly
                      <input type="radio" value="Lifetime" checked = {inputData?.period === 'Lifetime'} onChange={handleChangeInput('period')} style = {{marginLeft: 10}}/> Lifetime
                    </div>
                  
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <Label for="force_override">Force Override</Label>
                      </div>
                      <div className="col-md-6">
                          <Input type="select" name="force_override" value={inputData.force_override} onChange={(e) => handleChangeInputCheckBox(e)}>
                            <option value={1}> True </option>
                            <option value={0}> False </option>
                          </Input>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-md-3">
                        <Label for="force_override_notes">Force Override Notes</Label>
                      </div>
                      <div className="col-md-6">
                        <Input type="textarea" name="force_override_notes" id="force_override_notes" placeholder="" value={inputData.force_override_notes} onChange={handleChangeInput('force_override_notes')} />
                      </div>
                    </div>

                    <FormGroup  className="mt-2">
                      <Label check>
                        <Input type="checkbox" name="check_license" onChange={changeCheckLicense('check_license')} checked = {inputData?.check_license} style = {{marginRight: 5}}/>
                          Check License
                      </Label>
                    </FormGroup>


                    <div className="row mt-2">
                      <div className="col-md-3">
                        <Label for="status">Status</Label>
                      </div>
                      <div className="col-md-6">
                        <Input type="select" name="status" value={inputData.status} onChange={(e) => handleChangeInputCheckBox(e)}>
                          <option value={1}> True </option>
                          <option value={0}> False </option>
                        </Input>
                    </div>
                    </div>

                  </CardBody>
                </Card>

                <Card className="mt-2">
                  <CardBody style={{ padding: 4, background: '#008cd4', color: 'white' }}>
                    <CardTitle tag="h6" style={{ paddingLeft: 10, color: 'white' }}>Features</CardTitle>
                  </CardBody>
                  <CardBody>
                    <div className="row">
                      <div className="col-md-3">
                        <div style={{ alignItems: 'center', display: 'flex'}}>
                          <input type="radio" value="Online"  checked = {inputData?.features.usemap === 'Online'} onChange={changeUseMap('usemap')}/><span style={{paddingLeft: 2}}>Use Online Map</span>
                        </div>
                        <div style={{ alignItems: 'center', display: 'flex'}}>
                          <input type="radio" value="Local" checked = {inputData?.features.usemap === 'Local'} onChange={changeUseMap('usemap')}/><span style={{paddingLeft: 2}}>Use Local Map</span> 
                        </div>
              
                      </div>
                      <div className="col-md-9">

                        <div className="checkbox-container">
                          <div className="row">

                            <div className="col-md-2">
                              <FormGroup check>
                                <Label check>
                                  <Input type="checkbox" name="email" onChange={changeFeature('email')} />{' '}
                                  Email
                                </Label>
                              </FormGroup>
                            </div>

                            <div className="col-md-2">
                              <FormGroup check>
                                <Label check>
                                  <Input type="checkbox" onChange={changeFeature('caller_id')} />{' '}
                                  Caller Id
                                </Label>
                              </FormGroup>
                            </div>

                            <div className="col-md-2">
                              <FormGroup check>
                                <Label check>
                                  <Input type="checkbox" onChange={changeFeature('website')} />{' '}
                                  Website
                                </Label>
                              </FormGroup>
                            </div>

                          </div>
                        </div>

                        <div className="checkbox-container">
                          <div className="row">

                            <div className="col-md-2">
                              <FormGroup check>
                                <Label check>
                                  <Input type="checkbox" onChange={changeFeature('sms')} />{' '}
                                  SMS
                                </Label>
                              </FormGroup>
                            </div>

                            <div className="col-md-2">
                              <FormGroup check>
                                <Label check>
                                  <Input type="checkbox" onChange={changeFeature('postcode')} />{' '}
                                  PostCode
                                </Label>
                              </FormGroup>
                            </div>

                            <div className="col-md-2">
                              <FormGroup check>
                                <Label check>
                                  <Input type="checkbox" onChange={changeFeature('server')} />{' '}
                                  Server
                                </Label>
                              </FormGroup>
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <Card className="mt-2">
                  <CardBody style={{ padding: 4, background: '#008cd4', color: 'white' }}>
                    <CardTitle tag="h6" style={{ paddingLeft: 10, color: 'white' }}> Notes Area </CardTitle>
                  </CardBody>
                  <CardBody>
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <Label>Notes</Label>
                        <Input type="textarea" name="notes" id="notes" placeholder="" value={inputData.notes} onChange={handleChangeInput('notes')} />
                      </div>
                    </div>
                  </CardBody>
                </Card>
                </div>
              <Row>
                {error && (
                  <Alert color="danger">{error}</Alert>
                )}
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user" disabled={loading}>
                      {loading ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : (
                        <>Save</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddLicense);
