import { LicenseTypes } from "./actionTypes";

//get all dmin by sitecode
export const getAllLicenses = () => ({
  type: LicenseTypes. GET_ALL_LICENSES,
});

export const getAllLicensesSuccess = (licenses: any) => ({
  type: LicenseTypes.GET_ALL_LICENSES_SUCCESS,
  payload: licenses,
});

//get all dmin by sitecode
export const addLicense = (license: any) => ({
  type: LicenseTypes. ADD_LICENSE,
  payload: license
});

export const addLicenseSuccess = (license: any) => ({
  type: LicenseTypes.ADD_LICENSE_SUCCESS,
  payload: license,
});

export const addLicenseFail = (error: any) => ({
  type: LicenseTypes.ADD_LICENSE_FAIL,
  payload: error,
});



export const getLicenseById = (id: number) => ({
  type: LicenseTypes.GET_LICENSE_BYID,
  payload: id
});

export const getLicenseByIdSuccess = (license: any) => ({
  type: LicenseTypes.GET_LICENSE_BYID_SUCCESS,
  payload: license,
});

export const updateLicenseById = (id: any, license: any, history: any) => ({
  type: LicenseTypes.UPDATE_LICENSE_BY_ID,
  payload: {id, license, history}
})

export const updateLicenseByIdSuccess = (license: any) => ({
  type: LicenseTypes.UPDATE_LICENSE_BY_ID_SUCCESS,
  payload: license,
});

//delete license
export const deleteLicenseById = (id: any) => ({
  type: LicenseTypes.DELETE_LICENSE_BY_ID,
  payload: id,
});

export const deleteLicenseByIdSuccess = (id: any) => ({
  type: LicenseTypes.DELETE_LICENSE_BY_ID_SUCCESS,
  payload: id,
});



