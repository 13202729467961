export enum EmployeeTypes {
  ADD_EMPLOYEE = "ADD_EMPLOYEE",
  ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS',

  UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE",
  UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS',

  DELETE_EMPLOYEE = "DELETE_EMPLOYEE",
  DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS',

  GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES",
  GET_ALL_EMPLOYEES_SUCCESS = 'GET_ALL_EMPLOYEES_SUCCESS',

  GET_RESTAURANT_EMPLOYEES = "GET_RESTAURANT_EMPLOYEES",
  GET_RESTAURANT_EMPLOYEES_SUCCESS = 'GET_RESTAURANT_EMPLOYEES_SUCCESS',

  GET_RESTAURANT_DRIVERS = "GET_RESTAURANT_DRIVERS",
  GET_RESTAURANT_DRIVERS_SUCCESS = 'GET_RESTAURANT_DRIVERS_SUCCESS',

  GET_EMPLOYEE_BY_ID = "GET_EMPLOYEE_BY_ID",
  GET_EMPLOYEE_BY_ID_SUCCESS = "GET_EMPLOYEE_BY_ID_SUCCESS",

  EMPLOYEE_API_ERRROR = 'EMPLOYEE_API_ERRROR'
}
