export enum MenuActionTypes {
  GET_ALL_MENU_CATEGORIES = "GET_ALL_MENU_CATEGORIES",
  GET_ALL_MENU_CATEGORIES_SUCCESS = 'GET_ALL_MENU_CATEGORIES_SUCCESS',

  GET_RESTAURANT_MENU_CATEGORIES = 'GET_RESTAURANT_MENU_CATEGORIES',
  GET_RESTAURANT_MENU_CATEGORIES_WITH_ITEMS = 'GET_RESTAURANT_MENU_CATEGORIES_WITH_ITEMS',
  GET_RESTAURANT_MENU_CATEGORIES_SUCCESS = 'GET_RESTAURANT_MENU_CATEGORIES_SUCCESS',
  
  UPDATE_MENU_CATEGORY_BY_ID = 'UPDATE_MENU_CATEGORY_BY_ID',
  UPDATE_MENU_CATEGORY_BY_ID_SUCCESS = 'UPDATE_MENU_CATEGORY_BY_ID_SUCCESS',

  ADD_MENU_CATEGORY = 'ADD_MENU_CATEGORY',
  ADD_MENU_CATEGORY_SUCCESS = 'ADD_MENU_CATEGORY_SUCCESS',

  DELETE_MENU_CATEGORY_BY_ID = 'DELETE_MENU_CATEGORY_BY_ID',
  DELETE_MENU_CATEGORY_BY_ID_SUCCESS = 'DELETE_MENU_CATEGORY_BY_ID_SUCCESS',

  GET_MENU_CATEGORY_BY_ID = 'GET_MENU_CATEGORY_BY_ID',
  GET_MENU_CATEGORY_BY_ID_SUCCESS = 'GET_MENU_CATEGORY_BY_ID_SUCCESS',

  API_RETURN_ERROR = 'API_RETURN_ERROR'
}
