import { LicenseTypes } from "./actionTypes";

export const INIT_STATE: any = {
  licenses: [],
  singleLicense: {},
  error: "",
  loading: false,
  success: false,
};

const license = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case LicenseTypes.GET_ALL_LICENSES:
      return {
        ...state,
        loading: true,
      };

    case LicenseTypes.GET_ALL_LICENSES_SUCCESS:
      return {
        ...state,
        loading: false,
        licenses: action.payload,
      };

    case LicenseTypes.ADD_LICENSE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case LicenseTypes.ADD_LICENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        licenses: [...state.licenses, action.payload],
      };

      case LicenseTypes.ADD_LICENSE_FAIL:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload
        };

    case LicenseTypes.GET_LICENSE_BYID:
      return {
        ...state,
        loading: true,
      };

    case LicenseTypes.GET_LICENSE_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleLicense: action.payload,
      };

    case LicenseTypes.UPDATE_LICENSE_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case LicenseTypes.UPDATE_LICENSE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleLicense: action.payload,
      };

    case LicenseTypes.DELETE_LICENSE_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case LicenseTypes.DELETE_LICENSE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        licenses: state.licenses.filter(
          (license: any) => license.id !== action.payload
        ),
      };

    default:
      return state;
  }
};

export default license;
