import { CardTerminalsActionTypes } from "./actionTypes";

//get all dmin by sitecode
export const getRestaurantCardTerminals = (id: string) => ({
  type: CardTerminalsActionTypes.GET_RESTAURANT_CARD_TERMINALS,
  payload: id
});

export const getRestaurantCardTerminalsSuccess = (categories: any) => ({
  type: CardTerminalsActionTypes.GET_RESTAURANT_CARD_TERMINALS_SUCCESS,
  payload: categories,
});

export const updateCardTerminalById = (id: string, item: any, history: any) => ({
  type: CardTerminalsActionTypes.UPDATE_CARD_TERMINAL_BY_ID,
  payload: {id, item, history}
})

export const updateCardTerminalByIdSuccess = (item: any) => ({
  type: CardTerminalsActionTypes.UPDATE_CARD_TERMINAL_BY_ID_SUCCESS,
  payload: item,
});


export const storeCardTerminal = (item: any) => ({
  type: CardTerminalsActionTypes.ADD_CARD_TERMINAL,
  payload: item,
});

export const storeCardTerminalSuccess = (item: any) => ({
  type: CardTerminalsActionTypes.ADD_CARD_TERMINAL_SUCCESS,
  payload: item,
});

export const deleteCardTerminal = (id: string) => ({
  type: CardTerminalsActionTypes.DELETE_CARD_TERMINAL_BY_ID,
  payload: id
});

export const deleteCardTerminalSuccess = (id: string) => ({
  type: CardTerminalsActionTypes.DELETE_CARD_TERMINAL_BY_ID_SUCCESS,
  payload: id
})

export const getCardTerminalById = (id: string) => ({
  type: CardTerminalsActionTypes.GET_CARD_TERMINAL_BY_ID,
  payload: id
})

export const getCardTerminalByIdSuccess = (category: any) => ({
  type: CardTerminalsActionTypes.GET_CARD_TERMINAL_BY_ID_SUCCESS,
  payload: category
})

export const cardTerminalApiCallError = (error: any) => ({
  type: CardTerminalsActionTypes.CARD_TERMINALS_API_RETURN_ERROR,
  payload: error
})

