import { StatsTypes } from "./actionTypes";

export const INIT_STATE: any = {
  stats: {},
  error: "",
  loading: false,
  success: false,
};

const stats = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case StatsTypes.GET_ALL_STATS:
      return {
        ...state,
        loading: true,
      };

    case StatsTypes.GET_ALL_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        stats: action.payload,
      };

    default:
      return state;
  }
};

export default stats;
