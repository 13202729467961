export enum GiftCardsActionTypes {
  GET_RESTAURANT_GIFT_CARDS = "GET_RESTAURANT_GIFT_CARDS",
  GET_RESTAURANT_GIFT_CARDS_SUCCESS = 'GET_RESTAURANT_GIFT_CARDS_SUCCESS',

  UPDATE_GIFT_CARD_BY_ID = 'UPDATE_GIFT_CARD_BY_ID',
  UPDATE_GIFT_CARD_BY_ID_SUCCESS = 'UPDATE_GIFT_CARD_BY_ID_SUCCESS',

  ADD_GIFT_CARD = 'ADD_GIFT_CARD',
  ADD_GIFT_CARD_SUCCESS = 'ADD_GIFT_CARD_SUCCESS',

  DELETE_GIFT_CARD_BY_ID = 'DELETE_GIFT_CARD_BY_ID',
  DELETE_GIFT_CARD_BY_ID_SUCCESS = 'DELETE_GIFT_CARD_BY_ID_SUCCESS',

  GET_GIFT_CARD_BY_ID = 'GET_GIFT_CARD_BY_ID',
  GET_GIFT_CARD_BY_ID_SUCCESS = 'GET_GIFT_CARD_BY_ID_SUCCESS',

  GIFT_CARD_API_RETURN_ERROR = 'GIFT_CARD_API_RETURN_ERROR'
}
