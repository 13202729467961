import { SubMenuItemsActionTypes } from "./actionTypes";

export const INIT_STATE: any = {
  items: [],
  singleItem: {},
  error: {},
  loading: false,
  success: false,
};

const menu = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case SubMenuItemsActionTypes.GET_ALL_SUBMENUITEMS:
      return {
        ...state,
        loading: true,
      };

    case SubMenuItemsActionTypes.GET_ALL_SUBMENUITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };
    
    case SubMenuItemsActionTypes.GET_SUBMENUITEM_BY_ID:
      return {
        ...state,
        loading: true
      }
    
    case SubMenuItemsActionTypes.GET_SUBMENUITEM_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleItem: action.payload
      }

    case SubMenuItemsActionTypes.GET_MENUITEM_SUBMENUITEMS:
      return {
        ...state,
        loading: true,
      };

    case SubMenuItemsActionTypes.GET_MENUITEM_SUBMENUITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };

    case SubMenuItemsActionTypes.UPDATE_SUBMENUITEM_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case SubMenuItemsActionTypes.UPDATE_SUBMENUITEM_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleItem: action.payload,
        items: state.items.map((c: any) => c.id === action.payload.id ? action.payload : c)
      };
    case SubMenuItemsActionTypes.DELETE_SUBMENUITEM_BY_ID:
      return {
        ...state,
        loading: true
      }

    case SubMenuItemsActionTypes.DELETE_SUBMENUITEM_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter((c: any) => c.id !== parseInt(action.payload.toString()))
      }

    case SubMenuItemsActionTypes.ADD_SUBMENUITEM:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case SubMenuItemsActionTypes.ADD_SUBMENUITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        items: [...state.items, action.payload],
      };
    
    case SubMenuItemsActionTypes.SMI_API_RETURN_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      }

    default:
      return state;
  }
};

export default menu;
