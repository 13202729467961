import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Input  } from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
//redux
import { useSelector, useDispatch } from "react-redux";
import { getAllCustomers, getAllRestaurantsByManager } from "../../store/actions";
import { getUserRestaurantId } from "src/helpers/backend_helper";


const Customer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { customers, loading } = useSelector( (state: any) => state.customer);
  const { currentUser } = useSelector((state: any) => state.login);
  const { restaurants } = useSelector( (state: any) => state.restaurant);
  const [selectedRestId, setSelRestId] = useState(localStorage.selectedRestaurantId || '');
  const [customerList, setCustomerList] = useState<any>([]);

  const { SearchBar } = Search;

  const pageOptions = {
    sizePerPage: 20,
    totalSize: customers.length, // replace later with size(companies),
    custom: true,
  };

  const customerListtColumns = [
    {
      dataField: "id",
      text: "CustomerId",
      sort: true,
    },
    {
      dataField: "RestaurantName",
      text: "RestaurantName",
      sort: true,
      hidden: getUserRestaurantId() >= 0
    },
    {
      dataField: "First_Name",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "Last_Name",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "Telephone",
      text: "Telephone",
      sort: true,
    },
    {
      dataField: "Mobile",
      text: "Mobile",
      sort: true,
    },
    {
      dataField: "Email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "Rating",
      text: "Rating",
      sort: true,
    },
    {
      dataField: "Member_Since",
      text: "Member Since",
      sort: true,
    },
    {
      dataField: "AmountSpent",
      text: "AmountSpent",
      sort: true,
    },
    {
      dataField: "NumberOfOrder",
      text: "NumberOfOrder",
      sort: true,
    },
    {
      dataField: "IsBlocked",
      text: "IsBlocked",
      sort: true,
    },
    {
      dataField: "Address",
      text: "Address",
      sort: true,
    },
    {
      dataField: "City",
      text: "City",
      sort: true,
    },
    {
      dataField: "Postcode",
      text: "Postcode",
      sort: true,
    },
   
  ];

  useEffect(() => {
    const userRestId = getUserRestaurantId()
    if (userRestId > 0) {
      dispatch(getAllCustomers(userRestId));
    } else if(userRestId == -1) {
      localStorage.setItem("selectedRestaurantId", "-1");
      dispatch(getAllCustomers("-1"));
    } else if(userRestId == 0){
      dispatch(getAllRestaurantsByManager())
    }
  }, []);

  useEffect(() => {
    if (selectedRestId !== '' && parseInt(selectedRestId) > 0) {
      localStorage.setItem("selectedRestaurantId", selectedRestId);
      dispatch(getAllCustomers(selectedRestId))
    }
  }, [selectedRestId])

  useEffect(()=>{
    setCustomerList(customers)
  },[customers])

  const onRestaurantChange = (event: any) => {
    setSelRestId(event.target.value)
  }

  const handleEditAdmin = (admin: any) => {
    history.push(`/admins/${admin.id}`);
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Admins </title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
            </div>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={customerList}
                          columns={customerListtColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <div className="row align-ite  ms-center">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <h5 className="card-title">
                                        Customers List{" "}
                                        <span className="text-muted fw-normal ms-2">
                                          ({customers.length})
                                        </span>
                                      </h5>
                                    </div>
                                  </div>

                             
                                </div>
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon-search" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="2">
                                  {getUserRestaurantId() == 0 && (
                                    <Input name="restaurantId" onChange={onRestaurantChange} value={selectedRestId} type="select">
                                      <option key="all" value="-1">Select restaurant</option>
                                      {restaurants.map((restaurant: any) => (
                                        <option key={restaurant.id} value={restaurant.id}>{restaurant.ShopName}</option>
                                      ))}
                                    </Input>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Customer);
