export enum CardTerminalsActionTypes {
  GET_RESTAURANT_CARD_TERMINALS = "GET_RESTAURANT_CARD_TERMINALS",
  GET_RESTAURANT_CARD_TERMINALS_SUCCESS = 'GET_RESTAURANT_CARD_TERMINALS_SUCCESS',

  UPDATE_CARD_TERMINAL_BY_ID = 'UPDATE_CARD_TERMINAL_BY_ID',
  UPDATE_CARD_TERMINAL_BY_ID_SUCCESS = 'UPDATE_CARD_TERMINAL_BY_ID_SUCCESS',

  ADD_CARD_TERMINAL = 'ADD_CARD_TERMINAL',
  ADD_CARD_TERMINAL_SUCCESS = 'ADD_CARD_TERMINAL_SUCCESS',

  DELETE_CARD_TERMINAL_BY_ID = 'DELETE_CARD_TERMINAL_BY_ID',
  DELETE_CARD_TERMINAL_BY_ID_SUCCESS = 'DELETE_CARD_TERMINAL_BY_ID_SUCCESS',

  GET_CARD_TERMINAL_BY_ID = 'GET_CARD_TERMINAL_BY_ID',
  GET_CARD_TERMINAL_BY_ID_SUCCESS = 'GET_CARD_TERMINAL_BY_ID_SUCCESS',

  CARD_TERMINALS_API_RETURN_ERROR = 'CARD_TERMINALS_API_RETURN_ERROR'
}
