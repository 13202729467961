import { AdminTypes } from "./actionTypes";

//get all dmin by sitecode
export const getAdminsUsers = () => ({
  type: AdminTypes. GET_ALL_ADMINS,
});

export const getAdminsBySiteCodeSuccess = (admins: any) => ({
  type: AdminTypes.GET_ALL_ADMINS_SUCCESS,
  payload: admins,
});


export const addAdmin = (admin: any) => ({
  type: AdminTypes.ADD_ADMIN,
  payload: admin,
});

export const addAdminSuccess = (admin: any) => ({
  type: AdminTypes.ADD_ADMIN_SUCCESS,
  payload: admin,
});

export const addAdminFail = (error: any) => ({
  type: AdminTypes.ADD_ADMIN_FAIL,
  payload: error,
});

//delete admin
export const deleteAdminByDocId = (id: string) => ({
  type: AdminTypes.DELETE_ADMIN_BY_DOCID,
  payload: id,
});

export const deleteAdminByDocIdSuccess = (id: any) => ({
  type: AdminTypes.DELETE_ADMIN_BY_DOCID_SUCCESS,
  payload: id,
});

export const deleteAdminByDocIdFail = (error: any) => ({
  type: AdminTypes.DELETE_ADMIN_BY_DOCID_FAIL,
  payload: error,
});

export const getAdminById = (id: string) => ({
  type: AdminTypes.GET_ADMIN_BY_ID,
  payload: id
})

export const getAdminByIdSuccess = (admin: any) => ({
  type: AdminTypes.GET_ADMIN_BY_ID_SUCCESS,
  payload: admin,
});

export const getAdminByIdFail = (error: any) => ({
  type: AdminTypes.GET_ADMIN_BY_ID_FAIL,
  payload: error,
});

export const updateAdminByDocId = (docid: string, admin: any, history: any) => ({
  type: AdminTypes.UPDATE_ADMIN_BY_DOCID,
  payload: {docid, admin, history}
})

export const updateAdminByDocIdSuccess = (admin: any) => ({
  type: AdminTypes.UPDATE_ADMIN_BY_DOCID_SUCCESS,
  payload: admin,
});

export const updateAdminByDocIdFail = (error: any) => ({
  type: AdminTypes.UPDATE_ADMIN_BY_DOCID_FAIL,
  payload: error,
});

//get all Admin by CompanyCode

export const getAllAdminsByCompanyCode = (companyCode: string) => ({
  type: AdminTypes.GET_ADMINS_BY_COMPANYCODE,
  payload: companyCode
});

export const getAllAdminsByCompanyCodeSuccess = (admins: any) => ({
  type: AdminTypes.GET_ADMINS_BY_COMPANYCODE_SUCCESS,
  payload: admins,
});






