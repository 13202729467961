import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row,  } from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
//redux
import { useSelector, useDispatch } from "react-redux";
import { deleteSMSTemplate, getSMSTemplates } from "../../store/actions";
import EditTemplate from "./edit-template";


const SMSTemplates = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { smsTemplates: templates, loadingTemplates: loading } = useSelector( (state: any) => state.sms);
  const [showEdit, setShowEdit] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>({});

  const pageOptions = {
    sizePerPage: 20,
    totalSize: templates.length, // replace later with size(companies),
    custom: true,
  };

  const templatesColumns = [
    {
      dataField: "template_name",
      text: "Template Name",
      sort: true,
    },
    {
      dataField: "body",
      text: "Body",
      sort: true,
    },
    {
      dataField: "menu",
      text: "Menu",
      formatter: (cellContent: any, template: any) => (
        <div className="d-flex">
          <button className="btn">
            <i
              className="bx bx-edit font-size-20"
              id="edittooltip"
              onClick={() => handleEdit(template)}
            ></i>
          </button>
          <button className="btn">
            <i
              className="bx bx-x-circle font-size-20"
              id="edittooltip"
              onClick={() => handleRemove(template)}
            ></i>
          </button>
        </div>
      ),
    }
  ];

  useEffect(() => {
    dispatch(getSMSTemplates());
  }, []);

  const handleCreate = () => {
    setShowCreate(true);
    setSelectedTemplate({name: "", body: ""});
  }

  const handleRemove = (template: any) => {
    dispatch(deleteSMSTemplate(template.template_id));
  }

  const handleEdit = (template: any) => {
    setSelectedTemplate(template);
    setShowEdit(true);
  }

  const onDrawerClose = () => {
    setShowCreate(false);
    setShowEdit(false);
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Admins </title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
            </div>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={templates}
                          columns={templatesColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <div className="row align-item ms-center">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <Link to="/campaigns">
                                        <i className="fa fa-arrow-left me-1"></i>
                                        Campaigns
                                      </Link>
                                    </div>
                                  </div>
                                  
                                  <div className="col-md-6">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                     
                                        <div>
                                          <button
                                            className="btn btn-light"
                                            onClick={handleCreate}
                                          >
                                            <i className="bx bx-send me-1"></i>{" "}
                                            Create template
                                          </button>
                                        </div>
                                      
                                    </div>
                                  </div>
                                </div>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <EditTemplate
            open={showCreate || showEdit}
            onDrawerClose={onDrawerClose}
            create={showCreate}
            template={selectedTemplate}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SMSTemplates);
