import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { storeKDScreen } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";

const AddKDScreen = (props: any) => {
  const dispatch = useDispatch();
  const { success, loading } = useSelector((state: any) => state.kitchenDisplayScreens);
  const formRef = useRef<any>();

  useEffect(() => {
    if (props.open && formRef.current) {
      formRef.current.reset();
    }
  }, [props.open])

  useEffect(() => {
    if (success) {
      props.onDrawerClose(false);
    }
  }, [success]);

  const handleAddItem = (values: any) => {
    dispatch(storeKDScreen({
      ...values,
      RestaurantId: props.selectedRestId
    }));
  };

  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Add Kitchen Display Screen</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-admin-form" style={{ padding: 20 }}>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleAddItem(values);
            }}
            ref={formRef}
          >
            <div className="add-company-form-cotainer">
            <Row className="align-items-end">
                <Col md="12">
                  <div className="mb-3">
                    <AvField
                      name="Name"
                      label="Name"
                      type="text"
                      errorMessage="Name required"
                      validate={{
                        required: { value: true },
                      }}
                      defaultValue={""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user update-btn" disabled={loading}>
                      {loading ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : (
                        <>Save</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddKDScreen);
