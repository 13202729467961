export enum MenuInstructionsActionTypes {
  GET_ALL_MENU_INSTRUCTIONS = "GET_ALL_MENU_INSTRUCTIONS",
  GET_ALL_MENU_INSTRUCTIONS_SUCCESS = 'GET_ALL_MENU_INSTRUCTIONS_SUCCESS',

  UPDATE_MENU_INSTRUCTION_BY_ID = 'UPDATE_MENU_INSTRUCTION_BY_ID',
  UPDATE_MENU_INSTRUCTION_BY_ID_SUCCESS = 'UPDATE_MENU_INSTRUCTION_BY_ID_SUCCESS',

  ADD_MENU_INSTRUCTION = 'ADD_MENU_INSTRUCTION',
  ADD_MENU_INSTRUCTION_SUCCESS = 'ADD_MENU_INSTRUCTION_SUCCESS',

  DELETE_MENU_INSTRUCTION_BY_ID = 'DELETE_MENU_INSTRUCTION_BY_ID',
  DELETE_MENU_INSTRUCTION_BY_ID_SUCCESS = 'DELETE_MENU_INSTRUCTION_BY_ID_SUCCESS',

  GET_MENU_INSTRUCTION_BY_ID = 'GET_MENU_INSTRUCTION_BY_ID',
  GET_MENU_INSTRUCTION_BY_ID_SUCCESS = 'GET_MENU_INSTRUCTION_BY_ID_SUCCESS',

  INSTRUCTION_API_RETURN_ERROR = 'INSTRUCTION_API_RETURN_ERROR'
}
