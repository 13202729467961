export enum SmsActionTypes {
    GET_ALL_CAMPAIGNS = "GET_ALL_CAMPAIGNS",
    GET_ALL_CAMPAIGNS_SUCCESS = "GET_ALL_CAMPAIGNS_SUCCESS",
    SEND_SINGLE_SMS = "SEND_SINGLE_SMS",  
    SEND_SINGLE_SMS_SUCCESS = "SEND_SINGLE_SMS_SUCCESS",
    SEND_CAMPAIGN_SMS = "SEND_CAMPAIGN_SMS",
    SEND_CAMPAIGN_SMS_SUCCESS = "SEND_CAMPAIGN_SMS_SUCCESS",
    SEND_CAMPAIGN_SMS_FAILED = "SEND_CAMPAIGN_SMS_FAILED",
    CANCEL_CAMPAIGN = "CANCEL_CAMPAIGN",
    CANCEL_CAMPAIGN_SUCCESS = "CANCEL_CAMPAIGN_SUCCESS",
    GET_SMS_TEMPLATES = "GET_SMS_TEMPLATES",
    GET_SMS_TEMPLATES_SUCCESS = "GET_SMS_TEMPLATES_SUCCESS",
    CREATE_SMS_TEMPLATES = "CREATE_SMS_TEMPLATES",
    UPDATE_SMS_TEMPLATES = "UPDATE_SMS_TEMPLATES",
    CREATE_SMS_TEMPLATES_SUCCESS = "CREATE_SMS_TEMPLATES_SUCCESS",
    UPDATE_SMS_TEMPLATES_SUCCESS = "UPDATE_SMS_TEMPLATES_SUCCESS",
    DELETE_SMS_TEMPLATES = "DELETE_SMS_TEMPLATES",
    DELETE_SMS_TEMPLATES_SUCCESS = "DELETE_SMS_TEMPLATES_SUCCESS",
    UPDATE_SMS_TEMPLATE = "UPDATE_SMS_TEMPLATE",
    UPDATE_SMS_TEMPLATE_SUCCESS = "UPDATE_SMS_TEMPLATE_SUCCESS",
    SEND_SINGLE_VM = "SEND_SINGLE_VM",
    SEND_SINGLE_VM_SUCCESS = "SEND_SINGLE_VM_SUCCESS"
  }
  