export enum KDScreenActionTypes {
  GET_ALL_KD_SCREENS = "GET_ALL_KD_SCREENS",
  GET_ALL_KD_SCREENS_SUCCESS = 'GET_ALL_KD_SCREENS_SUCCESS',

  GET_RESTAURANT_KD_SCREENS = 'GET_RESTAURANT_KD_SCREENS',
  GET_RESTAURANT_KD_SCREENS_SUCCESS = 'GET_RESTAURANT_KD_SCREENS_SUCCESS',
  
  UPDATE_KD_SCREEN_BY_ID = 'UPDATE_KD_SCREEN_BY_ID',
  UPDATE_KD_SCREEN_BY_ID_SUCCESS = 'UPDATE_KD_SCREEN_BY_ID_SUCCESS',

  ADD_KD_SCREEN = 'ADD_KD_SCREEN',
  ADD_KD_SCREEN_SUCCESS = 'ADD_KD_SCREEN_SUCCESS',

  DELETE_KD_SCREEN_BY_ID = 'DELETE_KD_SCREEN_BY_ID',
  DELETE_KD_SCREEN_BY_ID_SUCCESS = 'DELETE_KD_SCREEN_BY_ID_SUCCESS',

  GET_KD_SCREEN_BY_ID = 'GET_KD_SCREEN_BY_ID',
  GET_KD_SCREEN_BY_ID_SUCCESS = 'GET_KD_SCREEN_BY_ID_SUCCESS',

  API_RETURN_ERROR = 'API_RETURN_ERROR'
}
