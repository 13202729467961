import { CustomerTypes } from "./actionTypes";

export const INIT_STATE: any = {
  customers: [],
  singleCustomer: {},
  error: {},
  loading: false,
  success: false,
};

const customer = (state = INIT_STATE, action: any) => {
  switch (action.type) {

    case CustomerTypes.GET_ALL_CUSTOMERS:
      return {
        ...state,
        loading: true,
      };

    case CustomerTypes.GET_ALL_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: action.payload,
      };
  
    case CustomerTypes.GET_CUSTOMER_BY_ID:
      return {
        ...state,
        loadingSingleCustomer: true,
      }
    case CustomerTypes.GET_CUSTOMER_BY_ID_SUCCESS:
      return {
        ...state,
        loadingSingleCustomer: false,
        singleCustomer: action.payload
      }
    default:
      return state;
  }
};

export default customer;
