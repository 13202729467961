import React, { useEffect, useState } from "react";
import { withRouter, useParams, Link, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getAllCustomers, getBookingById, getPurchaseOrderById, getRestaurantById, getRestaurantEmployees, getRestaurantSuppliers, getSupplierById, getSupplierMenuItems, updateBookingById, updatePurchaseOrderById, updateSupplierById } from "../../store/actions";
import { Col, Container, Row } from "reactstrap";
import { SpinnerCircular } from "spinners-react";
import { getUserRestaurantId } from "src/helpers/backend_helper";
import { get } from "src/helpers/api_helper";


const EditStock = (props: any) => {
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const history = useHistory();

  const [restaurantType, setRestaurantType] = useState(0);
  const { employees } = useSelector((state: any) => state.employees);
  const { singleBooking, addingOrUpdating: loading, success } = useSelector((state: any) => state.bookings);
  const { singleRestaurant } = useSelector((state: any) => state.restaurant);
  const [customers, setCustomers] = useState<any>([]);
  const [mobileNumber, setMobNumber] = useState("");
  const [tableAreas, setTableAreas] = useState<any>([]);
  const [tables, setTables] = useState<any>([]);
  const [selectedTableArea, setSelTableArea] = useState("");
  const [selectedTime, setSelectedTime] = useState('');

  useEffect(() => {
    dispatch(getBookingById(id));
  }, []);

  useEffect(() => {
    if (singleBooking && singleBooking.RestaurantId) {
      dispatch(getRestaurantById(singleBooking.RestaurantId));
      dispatch(getRestaurantEmployees(singleBooking.RestaurantId));
      get("/dinein/tableAreas/restaurant-id/" + singleBooking.RestaurantId).then((result) => {
        setTableAreas(result.result);
        if (singleBooking.table) {
          setSelTableArea(singleBooking.table.TableArea_Id);
        }
      })
      setSelectedTime(singleBooking.BookingDateTime.toString().substring(11, 16))
    }
  }, [singleBooking])

  useEffect(() => {
    if (singleRestaurant) {
      setRestaurantType(singleRestaurant.RestaurantType);
    }
  }, [singleRestaurant]);

  useEffect(() => {
    if (mobileNumber != "" && singleRestaurant) {
      get("/customers/search/" + singleRestaurant.id + "?Mobile=" + mobileNumber).then((result: any) => {
        setCustomers(result.data);
      })
    }
  }, [mobileNumber])

  useEffect(() => {
    if (selectedTableArea) {
      get("/dinein/tables/area-id/" + selectedTableArea).then((result) => {
        setTables(result.result);
        if (singleBooking.table) {

        }
      })
    }
  }, [selectedTableArea])

  const handleEditItem = (values: any) => {
    dispatch(updateBookingById(id, {
      ...values,
      RestaurantId: singleBooking.RestaurantId,
      BookingDateTime: values.BookingDate + " " + values.BookingTime
    }, history));
  };

  // Function to handle changes in the time input
  const handleTimeChange = (event: any) => {
    setSelectedTime(event.target.value);
  };

  // Function to generate time options in 15-minute intervals
  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        options.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return options;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <div className="d-flex flex-row col-md-6">
              <div className="mb-3">                
                <Link to="/bookings">
                    <i className="fa fa-arrow-left me-1"></i>
                  Bookings
                </Link>
              </div>

              <div className="mb-3" style={{marginLeft: 20}}>
                <h5>Update booking</h5>
              </div>
            </div>
          </Row>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "20vh" }}
              >
                <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
              </div>
            ) : (
              <AvForm
                onValidSubmit={(e: any, values: any) => {
                  handleEditItem(values);
                }}
              >
                <Row className="align-items-center">
                  <Col md="6">
                    <div className="mb-3">
                      <AvField type="date" label="Booking datetime" name="BookingDate" value={singleBooking?.BookingDateTime?.substring(0,10)}/>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3 ml-3">
                      <AvField id="timeInput" label="time" type="select" name="BookingTime" value={selectedTime} onChange={handleTimeChange}>
                        {generateTimeOptions().map((time) => (
                          <option key={time} value={time}>{time}</option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md="12">
                    <div className="mb-3">
                      <AvField type="text" label="Customer" placeholder="Type mobile number to search" name="Customer" value={mobileNumber}
                        onChange={(e: any) => setMobNumber(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md="12">
                    <div className="mb-3">
                      <AvField type="select" name="CustomerId" value={singleBooking?.CustomerId}>
                        <option key={singleBooking?.CustomerId} value={singleBooking?.CustomerId}> {singleBooking?.customer?.First_Name} {singleBooking?.customer?.Last_Name} ({singleBooking?.customer?.Mobile}) </option>
                        {customers.map((customer: any) => (
                          <option key={customer.id} value={customer.id}> {customer.First_Name} {customer.Last_Name} ({customer.Mobile})</option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                </Row>
                {restaurantType === 1 && (
                  <Row className="align-items-center">
                    <Col md="12">
                      <div className="mb-3">
                        <AvField type="text" label="Menu item order" name="menu_item_order" value={singleBooking?.menu_item_order}/>
                      </div>
                    </Col>
                  </Row>
                )}
                {restaurantType === 1 && (
                  <Row className="align-items-center">
                    <Col md="12">
                      <div className="mb-3">
                        <AvField type="select" label="Employee" name="Employee" value={singleBooking?.Employee}>
                          <option key="0" value="0"> Select employee </option>
                          {employees.map((employee: any) => (
                            <option key={employee.id} value={employee.id}> {employee.First_Name} {employee.Last_Name} </option>
                          ))}
                        </AvField>
                      </div>
                    </Col>
                  </Row>
                )}
                {restaurantType === 0 && (
                  <Row className="align-items-center">
                    <Col md="6">
                      <div className="mb-3">
                        <AvField label="Table" type="select" name="TableArea" value={selectedTableArea} onChange={(e: any) => setSelTableArea(e.target.value)}>
                          <option key="0" value="0"> Select table area</option>
                          {tableAreas.map((area: any) => (
                            <option key={area.id} value={area.id}> {area.Name} </option>
                          ))}
                        </AvField>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <AvField label=".." type="select" name="TableId" defaultValue={singleBooking.TableId}>
                          <option key="0" value="0"> Select table</option>
                          {tables.map((table: any) => (
                            <option key={table.id} value={table.id}> {table.Name} </option>
                          ))}
                        </AvField>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row className="align-items-center">
                  <Col md="12">
                    <div className="mb-3">
                      <AvField type="select" label="Status" name="BookingStatus" value={singleBooking?.BookingStatus}>
                        {["New", "In process", "No show", "Completed", "Cancelled"].map((value: any) => (
                          <option key={value} value={value}> {value} </option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                </Row>

                <Row style={{alignItems: 'flex-end'}}>
                  <Col>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user update-btn" disabled={loading}>
                        {loading ? (
                          <SpinnerCircular
                            size={30}
                            thickness={150}
                            speed={150}
                            color="#36ad47"
                            secondaryColor="rgba(0, 0, 0, 0.44)"
                          />
                        ) : (
                          <>Update</>
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            )}
              
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditStock);
