import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useParams } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { getAllMigrations, getMigrationById } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";

const LogDetails = (props: any) => {
  const dispatch = useDispatch();
  const { singleMigration } = useSelector((state: any) => state.migrations);
  const [timer, setTimer] = useState<any>(null);

  useEffect(() => {
    let timer = null;
    if (props.open) {
      dispatch(getMigrationById(props.selectedId));
      setTimer(setInterval(() => {
        dispatch(getMigrationById(props.selectedId));
      }, 1500));
    } else {
      if (timer) {
        clearInterval(timer);
        setTimer(null);
      }
    }
  }, [props.open])

  useEffect(() => {
    if (props.open && singleMigration) {
      if (singleMigration.status === 2 && timer) {
        clearInterval(timer);
        setTimer(null);
      }
    }
  }, [props.open, singleMigration])

  useEffect(() => {
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    }
  }, [])

  const onClose = () => {
    props.onDrawerClose();
  }
  

  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Migration details</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        {props.open && (
          <div className="add-admin-form d-flex" style={{ padding: 20, flexDirection: 'column', alignItems: 'center' }}>
            <h5 style={{marginBottom: 20}}>Migration status: {["Created", "Processing", "Completed", "Failed"][singleMigration.status]}</h5>
            <h4>Logs</h4>
            {singleMigration.logs && (
              <div style={{height: '80vh', width: 320, overflowY: 'scroll'}}>
                {singleMigration.logs.split("\n").map((line: string, idx: number) => (
                  <div key={idx} style={{fontSize: 12}}>{line}</div>
                ))}
              </div>
            )}
            <button className="btn btn-light mt-4" onClick={onClose}>Close</button>
          </div>
        )}
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(LogDetails);
