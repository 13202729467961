import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { OrderTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import { getOrderDetailByIdSuccess } from "./actions";

import { getOrderDetailByIdAsync } from "../../helpers/backend_helper";

function* getOrderDetailById({ payload: {orderId}}: any) {
  try {
    let response: IResponse;
    response = yield call(getOrderDetailByIdAsync, orderId);
    if(response.success) {
      yield put(getOrderDetailByIdSuccess(response.result));
    } 
  } catch (error) {
    console.log(error)
  }
}

function* orderSaga() {
  yield takeEvery(OrderTypes.GET_ORDER_DETAIL_BY_ID, getOrderDetailById)
}

export default orderSaga;


