import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";
import Admins from '../pages/Admins';
import EditAdmin from '../pages/Admins/edit-admin'
import License from '../pages/License';
import EditLicense from '../pages/License/edit-license'
import Restaurant from '../pages/Restaurant'
import Migration from '../pages/Migration'
import EditRestaurant from '../pages/Restaurant/edit-restaurant'
import Customer from '../pages/Customer';
import EditCustomer from '../pages/Customer/edit-customer'
import Order from '../pages/Order';
import EditOrder from '../pages/Order/edit-order';
import MenuCategory from '../pages/Menu';
import EditMenuCategory from '../pages/Menu/edit-menu-category';
import EditMenuItem from '../pages/Menu/edit-menu-item';
import EditSubMenuItem from '../pages/Menu/edit-sub-menu-item';

//Authentication pages
import Login from "src/pages/Authentication/Login";
import Logout from "src/pages/Authentication/Logout";
import Register from "src/pages/Authentication/Register";
import userProfile from "src/pages/Authentication/user-profile";
import SMSCampaign from "src/pages/SMSCampaign";
import CreateCampaign from "src/pages/SMSCampaign/create-campaign";
import SMSTemplates from "src/pages/SMSTemplates";
import MenuToppings from "src/pages/MenuToppings";
import EditMenuTopping from "src/pages/MenuToppings/edit-topping";
import MenuDressings from "src/pages/MenuDressings";
import EditMenuDressing from "src/pages/MenuDressings/edit-dressing";
import MenuInstructions from "src/pages/MenuInstructions";
import EditMenuInstruction from "src/pages/MenuInstructions/edit-instruction";
import Reports from "src/pages/Reports";
import Employee from "src/pages/Employee";
import EditEmployee from "src/pages/Employee/edit-employee";
import Printers from "src/pages/Printers";
import EditPrinter from "src/pages/Printers/edit-printer";
import CardTerminals from "src/pages/CardTerminals";
import EditCardTerminal from "src/pages/CardTerminals/edit-terminal";
import GiftCards from "src/pages/GiftCards";
import EditGiftCard from "src/pages/GiftCards/edit-giftcard";
import KitchenDisplayScreens from "src/pages/KitchenDisplayScreens";
import EditKDScreen from "src/pages/KitchenDisplayScreens/edit-screen";
import Suppliers from "src/pages/Suppliers";
import EditSupplier from "src/pages/Suppliers/edit-supplier";
import Stocks from "src/pages/Stocks";
import EditStock from "src/pages/Stocks/edit-purchase-order";
import Bookings from "src/pages/Bookings";
import EditBooking from "src/pages/Bookings/edit-booking";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  //User Profile
  { path: "/:companyCode/profile", component: userProfile },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  //dashboard
  { path: "/dashboard", component: Dashboard },
  // this route should be at the end of all other routes
  { path: "/admins", component: Admins},
  { path: "/admins/:id", component: EditAdmin},
  { path: "/licenses", component: License},
  { path: "/licenses/:id", component: EditLicense},

  { path: "/restaurants", component: Restaurant},
  { path: "/restaurants/:id", component: EditRestaurant},

  { path: "/migrations", component: Migration},
  { path: "/customers", component: Customer},
  { path: "/customers/:id", component: EditCustomer},

  { path: "/orders", component: Order},
  { path: "/orders", component: EditOrder},
  
  { path: "/reports", component: Reports},
  
  { path: "/sms-templates", component: SMSTemplates},
  { path: "/campaigns", component: SMSCampaign},
  { path: "/create-campaign", component: CreateCampaign},
  
  { path: "/menu/categories", component: MenuCategory},
  { path: "/menu/categories/:id", component: EditMenuCategory},
  { path: "/menu/items/:id", component: EditMenuItem},
  { path: "/menu/sub-menu-items/:id", component: EditSubMenuItem},
  { path: "/menu/toppings", component: MenuToppings},
  { path: "/menu/toppings/:id", component: EditMenuTopping},
  { path: "/menu/dressings", component: MenuDressings},
  { path: "/menu/dressings/:id", component: EditMenuDressing},
  { path: "/menu/instructions", component: MenuInstructions},
  { path: "/menu/instructions/:id", component: EditMenuInstruction},
  { path: "/orders", component: EditOrder},
  { path: "/employees", component: Employee},
  { path: "/employees/:id", component: EditEmployee},

  { path: "/printers", component: Printers},
  { path: "/printers/:id", component: EditPrinter},

  { path: "/cardTerminals", component: CardTerminals},
  { path: "/cardTerminals/:id", component: EditCardTerminal},

  { path: "/giftCards", component: GiftCards},
  { path: "/giftCards/:id", component: EditGiftCard},

  { path: "/kitchenDisplayScreens", component: KitchenDisplayScreens},
  { path: "/kitchenDisplayScreens/:id", component: EditKDScreen},

  { path: "/suppliers", component: Suppliers},
  { path: "/suppliers/:id", component: EditSupplier},

  { path: "/stocks", component: Stocks},
  { path: "/stocks/:id", component: EditStock},

  { path: "/bookings", component: Bookings},
  { path: "/bookings/:id", component: EditBooking},
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/", exact: true,  component: () => <Redirect to="/login" /> },
  { path: "/login", component: Login },
  { path: "/:companyCode/logout", component: Logout },
  { path: "/register", component: Register },
];

export { userRoutes, authRoutes };
