import React, { useEffect } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getKDScreenById, updateKDScreenById } from "../../store/actions";
import { Col, Container, Row } from "reactstrap";
import { SpinnerCircular } from "spinners-react";

const EditKDScreen = (props: any) => {
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const history = useHistory();

  const { singleScreen, loading } = useSelector((state: any) => state.kitchenDisplayScreens);

  useEffect(() => {
    dispatch(getKDScreenById(id));
  }, []);

  const handleEdit = (values: any) => {
    dispatch(updateKDScreenById(id, {
      ...values
    }, history));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>          
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "20vh" }}
              >
                <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
              </div>
            ) : (
              <AvForm
                onValidSubmit={(e: any, values: any) => {
                  handleEdit(values);
                }}
              >
                <Row className="align-items-end">
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="Name"
                        label="Name"
                        type="text"
                        errorMessage="Name required"
                        validate={{
                          required: { value: true },
                        }}
                        value={singleScreen?.Name || ""}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user update-btn" disabled={loading}>
                        {loading ? (
                          <SpinnerCircular
                            size={30}
                            thickness={150}
                            speed={150}
                            color="#36ad47"
                            secondaryColor="rgba(0, 0, 0, 0.44)"
                          />
                        ) : (
                          <>Update</>
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            )}
              
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditKDScreen);
