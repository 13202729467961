import { call, put, takeEvery } from "redux-saga/effects";
import { MenuInstructionsActionTypes } from "./actionTypes";
import { DeleteResponse, IResponse } from "../../interfaces/general";
import { getAllMenuInstructionsSuccess, storeMenuInstructionSuccess, getMenuInstructionByIdSuccess, menuInstructionApiCallError, deleteMenuInstructionSuccess, updateMenuInstructionByIdSuccess } from "./actions";

import { addMenuInstructionAsync, deleteMenuInstructionByIdAsync, getAllMenuInstructionsAsync, getMenuInstructionByIdAsnyc, updateMenuInstructionByIdAsync } from "../../helpers/backend_helper";

function* getAllMenuInstructions() {
  try {
    const response: IResponse = yield call(getAllMenuInstructionsAsync);
    console.log('response =>', response)
    if(response.success) {
      yield put(getAllMenuInstructionsSuccess(response.result.sort((a:any, b:any) => b.id-a.id)));
    } 
  } catch (error) {
    console.log(error)
    yield put(menuInstructionApiCallError(error))
  }
}

function* updateMenuInstruction({ payload: {id, item, history} }: any) {
  try {
    const response: IResponse = yield call(updateMenuInstructionByIdAsync, id, item);
    if(response.success) {
      yield put(updateMenuInstructionByIdSuccess(response.result));
      if (history) {
        history.push('/menu/instructions')
      }
    }
  } catch (error) {
    console.log(error)
    yield put(menuInstructionApiCallError(error))
  }
}

function* addMenuInstruction({ payload: menuInstruction }: any) {
  try {
    const response: IResponse = yield call(addMenuInstructionAsync, menuInstruction);
    if(response.success) {
      yield put(storeMenuInstructionSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(menuInstructionApiCallError(error))
  }
}

function* getMenuInstructionById({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getMenuInstructionByIdAsnyc, id);
    if(response.success) {
      yield put(getMenuInstructionByIdSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(menuInstructionApiCallError(error))
  }
}

function* deleteMenuInstruction({ payload: id }: any) {
  try {
    const response: DeleteResponse = yield call(deleteMenuInstructionByIdAsync, id);
    if(response.success) {
      yield put(deleteMenuInstructionSuccess(id));
    }
  } catch (error) {
    console.log(error)
    yield put(menuInstructionApiCallError(error))
  }
}

function* restaurantSaga() {
  yield takeEvery(MenuInstructionsActionTypes.GET_ALL_MENU_INSTRUCTIONS, getAllMenuInstructions)
  yield takeEvery(MenuInstructionsActionTypes.UPDATE_MENU_INSTRUCTION_BY_ID, updateMenuInstruction);
  yield takeEvery(MenuInstructionsActionTypes.DELETE_MENU_INSTRUCTION_BY_ID, deleteMenuInstruction);
  yield takeEvery(MenuInstructionsActionTypes.ADD_MENU_INSTRUCTION, addMenuInstruction)
  yield takeEvery(MenuInstructionsActionTypes.GET_MENU_INSTRUCTION_BY_ID, getMenuInstructionById);
}

export default restaurantSaga;


