import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@ailibs/feather-react-ts";
import SimpleBar from "simplebar-react";
import { withTranslation } from "react-i18next";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import adminIcon from '../../assets/images/icons/admins.png'
import orderIcon from '../../assets/images/icons/order.png'
import menuIcon from '../../assets/images/icons/menu_book.svg'
import toppingIcon from '../../assets/images/icons/top.svg'
import smsIcon from '../../assets/images/icons/textsms.svg'
import dressIcon from '../../assets/images/icons/dress.svg'
import instructionIcon from '../../assets/images/icons/instructions.svg'
import reportsIcon from '../../assets/images/icons/reports.svg'
import employeesIcon from '../../assets/images/icons/employees.svg'
import keylockerIcon from '../../assets/images/icons/keylocker.png'
import restaurantIcon from '../../assets/images/icons/restaurant.png'
import printersIcon from '../../assets/images/icons/printers.svg'
import cardTerminalsIcon from '../../assets/images/icons/card_terminals.svg'
import giftCardsIcon from '../../assets/images/icons/gift_cards.svg'
import kitchenDisplayScreensIcon from '../../assets/images/icons/chef_screen.png'
import suppliersIcon from '../../assets/images/icons/suppliers.png'
import bookingIcon from '../../assets/images/icons/booking.png'
import { getUserRestaurantId } from "src/helpers/backend_helper";
import { getRestaurantById } from "src/store/actions";




const SidebarContent = (props: any) => {
  const ref = useRef<any>();
  const dispatch = useDispatch();

  const { currentUser } = useSelector((state: any) => state.login);
  const { singleRestaurant: restaurant} = useSelector((state: any) => state.restaurant);
  const [isHospitality, setHospitality] = useState(true);

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul: any = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  useEffect(() => {
    if (currentUser && getUserRestaurantId() > 0) {
      dispatch(getRestaurantById(getUserRestaurantId()));
    }
  }, [currentUser])

  useEffect(() => {
    if (currentUser && getUserRestaurantId() > 0 && restaurant && restaurant.id) {
      setHospitality(restaurant.RestaurantType === 0);
    }
  }, [restaurant])

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              {currentUser && (
                <Link
                  to={`/dashboard`}
                  className=""
                >
                  <Icon name="home" />
                  <span>{props.t("Dashboard")}</span>
                </Link>
              )}
            </li>
            <li>
              {(currentUser && getUserRestaurantId() < 0) && (
                <>
                 <Link to="/admins">
                    <img src = {adminIcon} alt = "admin Icon" width={15}/>
                    <span className = "sidebar-title">{props.t("Users")}</span>
                  </Link>
                </>
              )}
            </li>
            <li>
              {(currentUser && getUserRestaurantId() < 0) && (
                <>
                 <Link to="/licenses">
                    <img src = {keylockerIcon} alt = "licenses Icon" width={15}/>
                    <span className = "sidebar-title">Licenses</span>
                  </Link>
                </>
              )}
            </li>
            <li>
              {(currentUser && getUserRestaurantId() < 0) && (
                <>
                 <Link to="/restaurants">
                    <img src = {restaurantIcon} alt = "restaurants Icon" width={15}/>
                    <span className = "sidebar-title">Restaurants</span>
                  </Link>
                </>
              )}
            </li>
            <li>
              {(currentUser && getUserRestaurantId() < 0) && (
                <>
                 <Link to="/migrations">
                    <img src = {restaurantIcon} alt = "restaurants Icon" width={15}/>
                    <span className = "sidebar-title">Migrations</span>
                  </Link>
                </>
              )}
            </li>
            <li>
              {(currentUser && isHospitality) && (
                <>
                 <Link to="/customers">
                    <img src = {adminIcon} alt = "customerIcon" width={15}/>
                    <span className = "sidebar-title">Customers</span>
                  </Link>
                </>
              )}
            </li>
            <li>
              {currentUser && (
                <>
                 <Link to="/orders">
                    <img src = {orderIcon} alt = "orderIcon" width={15}/>
                    <span className = "sidebar-title">Orders</span>
                  </Link>
                </>
              )}
            </li>
            <li>
              {(currentUser && isHospitality) && (
                <>
                 <Link to="/campaigns">
                    <img src = {smsIcon} alt = "smsIcon" width={15}/>
                    <span className = "sidebar-title">SMS Campaign</span>
                  </Link>
                </>
              )}
            </li>
            <li>
              {(currentUser && getUserRestaurantId() >= 0) && (
                <Link to="/reports">
                  <img src = {reportsIcon} alt = "reportsIcon" width={15}/>
                  <span className = "sidebar-title">Reports</span>
                </Link>
              )}
            </li>
            <li>
              {currentUser && (
                <>
                 <Link to="/menu/categories">
                    <img src = {menuIcon} alt = "menuIcon" width={15}/>
                    <span className = "sidebar-title">{isHospitality ? "Menu": "Inventory"}</span>
                  </Link>
                </>
              )}
              {currentUser && !isHospitality && (
                <ul>
                  <li>
                  <Link to="/stocks">
                      <img src = {menuIcon} alt = "menuIcon" width={15}/>
                      <span className = "sidebar-title">Stocks</span>
                    </Link>                    
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link to="/employees">
                <img src = {employeesIcon} alt = "employeesIcon" width={15}/>
                <span className = "sidebar-title">Employees</span>
              </Link>
            </li>
            <li>
              <Link to="/printers">
                <img src = {printersIcon} alt = "printersIcon" width={15}/>
                <span className = "sidebar-title">Printers</span>
              </Link>
            </li>
            <li>
              <Link to="/cardTerminals">
                <img src = {cardTerminalsIcon} alt = "cardTerminalsIcon" width={15}/>
                <span className = "sidebar-title">Card Terminals</span>
              </Link>
            </li>
            <li>
              <Link to="/giftCards">
                <img src = {giftCardsIcon} alt = "giftCardsIcon" width={15}/>
                <span className = "sidebar-title">Gift Cards</span>
              </Link>
            </li>
            <li>
              {(currentUser && isHospitality) && (
                <Link to="/kitchenDisplayScreens">
                  <img src = {kitchenDisplayScreensIcon} alt = "kitchenDisplayScreens" width={15}/>
                  <span className = "sidebar-title">Kitchen Display Screens</span>
                </Link>
              )}
            </li>
            <li>
              <Link to="/suppliers">
                <img src = {suppliersIcon} alt = "suppliersIcon" width={15}/>
                <span className = "sidebar-title">Suppliers</span>
              </Link>
            </li>
            <li>
              <Link to="/bookings">
                <img src = {bookingIcon} alt = "bookingsIcon" width={15}/>
                <span className = "sidebar-title">Bookings</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(SidebarContent));
