import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { CustomerTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import {getAllCustomersSuccess, getCustomerByIdSuccess} from "./actions";

import { getAllCustomersAsync, getCustomerByIdAsync, getRestaurantCustomersAsync, getUserRestaurantId } from "../../helpers/backend_helper";

function* getAllCustomers({payload:restaurantId}:any) {
  try {
    
    // const restaurantId = getUserRestaurantId()
    let response: IResponse;
    if (restaurantId > 0) {
      response = yield call(getRestaurantCustomersAsync, restaurantId);
    } else {
      response = yield call(getAllCustomersAsync);
    }
    console.log('response =>', response)
    if(response.success) {
      yield put(getAllCustomersSuccess(response.result.sort((a:any, b:any) => b.id-a.id)));
    } 
  } catch (error) {
    console.log(error)
  }
}

function* getCustomerById({ payload: customerId}: any) {
  try {
    const response: IResponse = yield call(getCustomerByIdAsync, customerId);
    if(response.success) {
      yield put(getCustomerByIdSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
  }
}

// function* getAdminById({ payload: id}: any) {
//   try {
//     const response: IResponse = yield call(getAdminByIdAsync, id);
//     if(response.success) {
//       yield put(getAdminByIdSuccess(response.result));
//     }
//   } catch (error) {
//     yield put(getAdminByIdFail(error));
//   }
// }




function* customerSaga() {
  yield takeEvery(CustomerTypes.GET_ALL_CUSTOMERS, getAllCustomers)
  yield takeEvery(CustomerTypes.GET_CUSTOMER_BY_ID, getCustomerById)
  // yield takeEvery(AdminTypes.GET_ADMIN_BY_ID, getAdminById)
}

export default customerSaga;


