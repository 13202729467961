import { MenuDressingsActionTypes } from "./actionTypes";

//get all dmin by sitecode
export const getAllMenuDressings = () => ({
  type: MenuDressingsActionTypes.GET_ALL_MENU_DRESSINGS,
});

export const getAllMenuDressingsSuccess = (categories: any) => ({
  type: MenuDressingsActionTypes.GET_ALL_MENU_DRESSINGS_SUCCESS,
  payload: categories,
});

export const updateMenuDressingById = (id: string, item: any, history: any) => ({
  type: MenuDressingsActionTypes.UPDATE_MENU_DRESSING_BY_ID,
  payload: {id, item, history}
})

export const updateMenuDressingByIdSuccess = (item: any) => ({
  type: MenuDressingsActionTypes.UPDATE_MENU_DRESSING_BY_ID_SUCCESS,
  payload: item,
});


export const storeMenuDressing = (item: any) => ({
  type: MenuDressingsActionTypes.ADD_MENU_DRESSING,
  payload: item,
});

export const storeMenuDressingSuccess = (item: any) => ({
  type: MenuDressingsActionTypes.ADD_MENU_DRESSING_SUCCESS,
  payload: item,
});

export const deleteMenuDressing = (id: string) => ({
  type: MenuDressingsActionTypes.DELETE_MENU_DRESSING_BY_ID,
  payload: id
});

export const deleteMenuDressingSuccess = (id: string) => ({
  type: MenuDressingsActionTypes.DELETE_MENU_DRESSING_BY_ID_SUCCESS,
  payload: id
})

export const getMenuDressingById = (id: string) => ({
  type: MenuDressingsActionTypes.GET_MENU_DRESSING_BY_ID,
  payload: id
})

export const getMenuDressingByIdSuccess = (category: any) => ({
  type: MenuDressingsActionTypes.GET_MENU_DRESSING_BY_ID_SUCCESS,
  payload: category
})

export const menuDressingApiCallError = (error: any) => ({
  type: MenuDressingsActionTypes.DRESSING_API_RETURN_ERROR,
  payload: error
})

