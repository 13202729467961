import { SmsActionTypes } from "./actionTypes";

//get all stats
export const getAllCampaigns = () => ({
  type: SmsActionTypes.GET_ALL_CAMPAIGNS,
});

export const getAllCampaignsSuccess = (campaigns: any) => ({
  type: SmsActionTypes.GET_ALL_CAMPAIGNS_SUCCESS,
  payload: campaigns
})

export const sendSingleSMS = (customerId: string, from: string, message: string) => ({
  type: SmsActionTypes.SEND_SINGLE_SMS,
  payload: {
    customerId,
    from,
    message
  }
});

export const sendSingleVM = (telNo: string, message: string) => ({
  type: SmsActionTypes.SEND_SINGLE_VM,
  payload: {
    telNo,
    message
  }
});

export const sendSingleSMSSuccess = (stats: any) => ({
    type: SmsActionTypes.SEND_SINGLE_SMS_SUCCESS,
    payload: stats
});

export const sendSingleVMSSuccess = (stats: any) => ({
    type: SmsActionTypes.SEND_SINGLE_VM_SUCCESS,
    payload: stats
});

export const sendCampaignSMS = (restaurantId: number, campaignName: string, customers: any, from: string, message: string, scheduleTime: number) => ({
  type: SmsActionTypes.SEND_CAMPAIGN_SMS,
  payload: {
    restaurantId,
    name: campaignName,
    customers,
    from,
    message,
    scheduleTime
  }
});

export const sendCampaignSMSSuccess = (result: any) => ({
  type: SmsActionTypes.SEND_CAMPAIGN_SMS_SUCCESS,
  payload: result
})

export const sendCampaignSMSFailed = (result: any) => ({
  type: SmsActionTypes.SEND_CAMPAIGN_SMS_FAILED,
  payload: result
})

export const cancelCampaign = (campaignId: number) => ({
  type: SmsActionTypes.CANCEL_CAMPAIGN,
  payload: {
    campaignId
  }
})

export const cancelCampaignSuccess = (campaignId: number) => ({
  type: SmsActionTypes.CANCEL_CAMPAIGN_SUCCESS,
  payload: {
    campaignId
  }
})

export const getSMSTemplates = () => ({
  type: SmsActionTypes.GET_SMS_TEMPLATES
})

export const getSMSTemplatesSuccess = (templates: any) => ({
  type: SmsActionTypes.GET_SMS_TEMPLATES_SUCCESS,
  payload: templates
})

export const createSMSTemplate = ({message, name}: any) => ({
  type: SmsActionTypes.CREATE_SMS_TEMPLATES,
  payload: {
    message,
    name
  }
})

export const updateSMSTemplate = ({id, message, name}: any) => ({
  type: SmsActionTypes.UPDATE_SMS_TEMPLATE,
  payload: {
    id,
    message,
    name
  }
})

export const createSMSTemplateSuccess = (template: any) => ({
  type: SmsActionTypes.CREATE_SMS_TEMPLATES_SUCCESS,
  payload: template
})

export const deleteSMSTemplate = (id: string) => ({
  type: SmsActionTypes.DELETE_SMS_TEMPLATES,
  payload: {
    id
  }
})

export const deleteSMSTemplateSuccess = (id: string) => ({
  type: SmsActionTypes.DELETE_SMS_TEMPLATES_SUCCESS,
  payload: {
    id
  }
})

export const updateSMSTemplates = (id: string, message: string, name: string) => ({
  type: SmsActionTypes.UPDATE_SMS_TEMPLATE,
  payload: {
    id,
    message,
    name
  }
})

export const updateSMSTemplateSuccess = (template: any) => ({
  type: SmsActionTypes.UPDATE_SMS_TEMPLATE_SUCCESS,
  payload: template
})