import { call, put, takeEvery } from "redux-saga/effects";
import { SuppliersActionTypes } from "./actionTypes";
import { DeleteResponse, IResponse } from "../../interfaces/general";
import { deleteSupplierSuccess, getRestaurantSuppliersSuccess, getSupplierByIdSuccess, supplierApiCallError, storeSupplierSuccess, updateSupplierByIdSuccess } from "./actions";

import { addSupplierAsync, deleteSupplierByIdAsync, getAllSuppliersAsync, getSupplierByIdAsync, updateSupplierByIdAsync } from "../../helpers/backend_helper";

function* getRestaurantSuppliers({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getAllSuppliersAsync, id);
    console.log('response =>', response)
    if(response.success) {
      yield put(getRestaurantSuppliersSuccess(response.result.sort((a:any, b:any) => a.id-b.id)));
    } 
  } catch (error) {
    console.log(error)
    yield put(supplierApiCallError(error))
  }
}

function* updateSupplier({ payload: {id, item, history} }: any) {
  try {
    const response: IResponse = yield call(updateSupplierByIdAsync, id, item);
    if(response.success) {
      yield put(updateSupplierByIdSuccess(response.result));
      if (history) {
        history.push('/suppliers')
      }
    }
  } catch (error) {
    console.log(error)
    yield put(supplierApiCallError(error))
  }
}

function* addSupplier({ payload: menuDressing }: any) {
  try {
    const response: IResponse = yield call(addSupplierAsync, menuDressing);
    if(response.success) {
      yield put(storeSupplierSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(supplierApiCallError(error))
  }
}

function* getSupplierById({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getSupplierByIdAsync, id);
    if(response.success) {
      yield put(getSupplierByIdSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(supplierApiCallError(error))
  }
}

function* deleteSupplier({ payload: id }: any) {
  try {
    const response: DeleteResponse = yield call(deleteSupplierByIdAsync, id);
    if(response.success) {
      yield put(deleteSupplierSuccess(id));
    }
  } catch (error) {
    console.log(error)
    yield put(supplierApiCallError(error))
  }
}

function* restaurantSaga() {
  yield takeEvery(SuppliersActionTypes.GET_RESTAURANT_SUPPLIERS, getRestaurantSuppliers)
  yield takeEvery(SuppliersActionTypes.UPDATE_SUPPLIER_BY_ID, updateSupplier);
  yield takeEvery(SuppliersActionTypes.DELETE_SUPPLIER_BY_ID, deleteSupplier);
  yield takeEvery(SuppliersActionTypes.ADD_SUPPLIER, addSupplier)
  yield takeEvery(SuppliersActionTypes.GET_SUPPLIER_BY_ID, getSupplierById);
}

export default restaurantSaga;


