export enum AdminTypes {
  GET_ALL_ADMINS = "GET_ALL_ADMINS",
  GET_ALL_ADMINS_SUCCESS = 'GET_ALL_ADMINS_SUCCESS',

  ADD_ADMIN = 'ADD_ADMIN',
  ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS',
  ADD_ADMIN_FAIL = 'ADD_ADMIN_FAIL',

  GET_ADMIN_BY_ID = 'GET_ADMIN_BY_ID',
  GET_ADMIN_BY_ID_SUCCESS = 'GET_ADMIN_BY_ID_SUCCESS',
  GET_ADMIN_BY_ID_FAIL = 'GET_ADMIN_BY_ID_FAIL',

  UPDATE_ADMIN_BY_DOCID = 'UPDATE_ADMIN_BY_DOCID',
  UPDATE_ADMIN_BY_DOCID_SUCCESS = 'UPDATE_ADMIN_BY_DOCID_SUCCESS',
  UPDATE_ADMIN_BY_DOCID_FAIL = 'UPDATE_ADMIN_BY_DOCID_FAIL',

  GET_ADMINS_BY_COMPANYCODE = 'GET_ADMINS_BY_COMPANYCODE',
  GET_ADMINS_BY_COMPANYCODE_SUCCESS = 'GET_ADMINS_BY_COMPANYCODE_SUCCESS',

  DELETE_ADMIN_BY_DOCID = 'DELETE_ADMIN_BY_DOCID',
  DELETE_ADMIN_BY_DOCID_SUCCESS = 'DELETE_ADMIN_BY_DOCID_SUCCESS',
  DELETE_ADMIN_BY_DOCID_FAIL = 'DELETE_ADMIN_BY_DOCID_FAIL'
}
