
export enum LoginTypes {
    LOGIN_ADMIN = 'LOGIN_ADMIN',
    LOGIN_STAFF_ADMIN = 'LOGIN_STAFF_ADMIN',
    LOGIN_SUCCESS = '@@login/LOGIN_SUCCESS',
    LOGIN_FAIL = 'LOGIN_FAIL',
    SET_AUTH_USER = 'SET_AUTH_USER',
    LOGOUT_USER = '@@login/LOGOUT_USER',
    LOGOUT_USER_SUCCESS = '@@login/LOGOUT_USER_SUCCESS',
    API_ERROR = '@@login/API_ERROR',
}
