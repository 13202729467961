import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { storeMenuDressing, getAllRestaurants } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { hexColorToInt } from "src/helpers/general";

const AddDressing = (props: any) => {
  const dispatch = useDispatch();

  const { items, success, loading, error } = useSelector((state: any) => state.menuDressings);

  useEffect(() => {
    dispatch(getAllRestaurants());
  }, [])

  useEffect(() => {
    if (success) {
      props.onDrawerClose(false);
    }
  }, [success]);

  const handleAddDressing = (values: any) => {
    dispatch(storeMenuDressing({
      ...values,
      ForeColor: hexColorToInt('#ffffff'),
      BackColor: hexColorToInt('#333333'),
      BaseColor: hexColorToInt('#333333'),
      FontSize: 20
    }));
  };

  return (
    <React.Fragment>
      <div className="add-license">
        <MetaTags>
          <title>Add New Dressing</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-license-form" style={{ padding: 20, width: 'fit-content' }}>
          <AvForm
              onValidSubmit={(e: any, values: any) => {
                handleAddDressing(values);
              }}
            >
              <Row className="align-items-center">
                  <Col md="12">
                    <div className="mb-3">
                      <AvField
                        name="Name"
                        label="Name"
                        type="text"
                        errorMessage="Name required"
                        validate={{
                          required: { value: true },
                        }}
                        defaultValue={""}
                      />
                    </div>
                  </Col>
              </Row>
              <Row className="align-items-center">
                  <Col md="12">
                    <div className="mb-3">
                      <AvField
                        name="Price"
                        label="Price"
                        type="number"
                        errorMessage="Price required"
                        validate={{
                          required: { value: true },
                        }}
                        defaultValue={""}
                      />
                    </div>
                  </Col>
              </Row>
              <Row className="align-items-center">
                  <Col md="12">
                    <div className="mb-3">
                      <AvField
                        name="Position"
                        label="Position"
                        type="number"
                        errorMessage="Position required"
                        validate={{
                          required: { value: true },
                        }}
                        defaultValue={items.length + 1}
                      />
                    </div>
                  </Col>
              </Row>

              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user" disabled={loading}>
                      {loading ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : (
                        <>Save</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddDressing);
