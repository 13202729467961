import { BookingActionTypes } from "./actionTypes";

export const INIT_STATE: any = {
  bookings: [],
  singleBooking: {},
  error: {},
  loading: false,
  addingOrUpdating: false,
  success: false,
};

const order = (state = INIT_STATE, action: any) => {
  switch (action.type) {

    case BookingActionTypes.GET_RESTAURANT_BOOKINGS:
      return {
        ...state,
        loading: true,
      };

    case BookingActionTypes.GET_RESTAURANT_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        bookings: action.payload,
      };
    case BookingActionTypes.STORE_BOOKING:
      return {
        ...state,
        addingOrUpdating: true,
        success: false,
      };

    case BookingActionTypes.STORE_BOOKING_SUCCESS:
      return {
        ...state,
        addingOrUpdating: false,
        success: true,
        bookings: [...state.bookings, action.payload],
      };
    case BookingActionTypes.GET_BOOKING_BY_ID:
      return {
        ...state,
        loading: true
      }
    
    case BookingActionTypes.GET_BOOKING_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleBooking: action.payload
      }

    case BookingActionTypes.UPDATE_BOOKING_BY_ID:
      return {
        ...state,
        addingOrUpdating: true,
      };

    case BookingActionTypes.UPDATE_BOOKING_BY_ID_SUCCESS:
      return {
        ...state,
        addingOrUpdating: false,
        singleBooking: action.payload,
        bookings: state.bookings.map((c: any) => c.id === action.payload.id ? action.payload : c)
      };
    case BookingActionTypes.DELETE_BOOKING_BY_ID:
      return {
        ...state,
        loading: true
      }

    case BookingActionTypes.DELETE_BOOKING_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        bookings: state.bookings.filter((c: any) => c.id !== parseInt(action.payload.toString()))
      }
    default:
      return state;
  }
};

export default order;
