import { call, put, takeEvery } from "redux-saga/effects";
import { PrintersActionTypes } from "./actionTypes";
import { DeleteResponse, IResponse } from "../../interfaces/general";
import { deletePrinterSuccess, getRestaurantPrintersSuccess, getPrinterByIdSuccess, printerApiCallError, storePrinterSuccess, updatePrinterByIdSuccess } from "./actions";

import { addPrinterAsync, deletePrinterByIdAsync, getAllPrintersAsync, getPrinterByIdAsync, updatePrinterByIdAsync } from "../../helpers/backend_helper";

function* getRestaurantPrinters({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getAllPrintersAsync, id);
    console.log('response =>', response)
    if(response.success) {
      yield put(getRestaurantPrintersSuccess(response.result.sort((a:any, b:any) => a.id-b.id)));
    } 
  } catch (error) {
    console.log(error)
    yield put(printerApiCallError(error))
  }
}

function* updatePrinter({ payload: {id, item, history} }: any) {
  try {
    const response: IResponse = yield call(updatePrinterByIdAsync, id, item);
    if(response.success) {
      yield put(updatePrinterByIdSuccess(response.result));
      if (history) {
        history.push('/printers')
      }
    }
  } catch (error) {
    console.log(error)
    yield put(printerApiCallError(error))
  }
}

function* addPrinter({ payload: menuDressing }: any) {
  try {
    const response: IResponse = yield call(addPrinterAsync, menuDressing);
    if(response.success) {
      yield put(storePrinterSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(printerApiCallError(error))
  }
}

function* getPrinterById({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getPrinterByIdAsync, id);
    if(response.success) {
      yield put(getPrinterByIdSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
    yield put(printerApiCallError(error))
  }
}

function* deletePrinter({ payload: id }: any) {
  try {
    const response: DeleteResponse = yield call(deletePrinterByIdAsync, id);
    if(response.success) {
      yield put(deletePrinterSuccess(id));
    }
  } catch (error) {
    console.log(error)
    yield put(printerApiCallError(error))
  }
}

function* restaurantSaga() {
  yield takeEvery(PrintersActionTypes.GET_RESTAURANT_PRINTERS, getRestaurantPrinters)
  yield takeEvery(PrintersActionTypes.UPDATE_PRINTER_BY_ID, updatePrinter);
  yield takeEvery(PrintersActionTypes.DELETE_PRINTER_BY_ID, deletePrinter);
  yield takeEvery(PrintersActionTypes.ADD_PRINTER, addPrinter)
  yield takeEvery(PrintersActionTypes.GET_PRINTER_BY_ID, getPrinterById);
}

export default restaurantSaga;


