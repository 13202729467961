import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useParams } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { getSMSTemplates, sendSingleVM } from "src/store/sms/actions";
import Select from 'react-select';

const SendVM = (props: any) => {
  const dispatch = useDispatch();
  const { sendingVMSuccess: success, sendingVM: loading, smsTemplates } = useSelector((state: any) => state.sms);
  const [formValues, setFormValues] = useState<any>({});
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      // props.onDrawerClose(false);
      setShowSuccess(true);
    }
  }, [success]);

  useEffect(() => {
    if (props.open) {
      dispatch(getSMSTemplates());
      setShowSuccess(false);
    }
  }, [props.open])

  const handleSendMessage = (values: any) => {
    dispatch(sendSingleVM(props.telNo, values.Message));
  };

  const handleFormChange = (e: any) => {
    setFormValues((values: any) => ({...values, [e.target.name]: e.target.value}))
  }

  const handleTemplateChange = (newValue: any) => {
    setFormValues((values: any) => ({...values, Message: newValue.value, SMSTemplate: newValue}))
  }


  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Add New Admin</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-admin-form" style={{ padding: 20 }}>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              if (!showSuccess) {
                handleSendMessage(values);
              } else {
                props.onDrawerClose(false);
              }
            }}
          >
            <div className="add-company-form-cotainer">
              <Row>
                <Col md="12">
                  <h4 className="mt-4 mb-4">Send Voice Message</h4>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <div className="mb-3">
                    <AvField
                      name="To"
                      label="To"
                      type="text"
                      // maxLength={11}
                      disabled={true}
                      value={props.telNo}
                      onChange={handleFormChange}
                    />
                  </div>
                </Col>
              </Row>
              
              <Row className="mb-3">
                <Col md="12">
                  <Select
                    name="SMSTemplate"
                    options={smsTemplates.map((t: any) => ({value: t.body, label: t.template_name}))}
                    type="textarea"
                    validate={{
                      required: { value: true },
                    }}
                    value={formValues.SMSTemplate}
                    onChange={handleTemplateChange}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <div className="mb-3">
                    <AvField
                      name="Message"
                      label="Message"
                      type="textarea"
                      errorMessage="Input message"
                      validate={{
                        required: { value: true },
                      }}
                      style={{height: 150}}
                      value={formValues.Message}
                      onChange={handleFormChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <div className="text-end">
                    {showSuccess && (
                      <h5>VM sent successfully</h5>
                    )}
                    <button type="submit" className="btn btn-success" disabled={loading}>
                      {loading ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : showSuccess ? (
                        <>Close</>
                      ) : (                        
                        <>Send VM</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(SendVM);
