import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { EmployeeTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import {addEmployeeSuccess, deleteEmployeeSuccess, getAllEmployeesSuccess, getEmployeeByIdSuccess, getRestaurantDriversSuccess, getRestaurantEmployeesSuccess, updateEmployeeSuccess} from "./actions";

import { getAllEmployeesAsync, getRestaurantEmployeesAsync, getEmployeeByIdAsync, addEmployeeAsync, getRestaurantDriversAsync, updateEmployeeByIdAsync, deleteEmployeeByIdAsync } from "../../helpers/backend_helper";

function* getAllEmployees() {
  try {
    let response: IResponse;
    response = yield call(getAllEmployeesAsync);
    if(response.success) {
      yield put(getAllEmployeesSuccess(response.result));
    } 
  } catch (error) {
    console.log(error)
  }
}

function* getEmployeeById({ payload: employeeId}: any) {
  try {
    const response: IResponse = yield call(getEmployeeByIdAsync, employeeId);
    if(response.success) {
      yield put(getEmployeeByIdSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
  }
}

function* getRestaurantEmployees({ payload: restaurantId }: any) {
  try {
    let response: IResponse;
    response = yield call(getRestaurantEmployeesAsync, restaurantId);
    if(response.success) {
      yield put(getRestaurantEmployeesSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
  }
}

function* getRestaurantDrivers({ payload: restaurantId }: any) {
  try {
    let response: IResponse;
    response = yield call(getRestaurantDriversAsync, restaurantId);
    if(response.success) {
      yield put(getRestaurantDriversSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
  }
}

function* addEmployee({ payload: employee }: any) {
  try {
    const response: IResponse = yield call(addEmployeeAsync, employee);
    if(response.success) {
      yield put(addEmployeeSuccess(response.result));
    }
  } catch (error) {
    console.log(error);
  }
}

function* updateEmployee({ payload: {id, employee} }: any) {
  try {
    const response: IResponse = yield call(updateEmployeeByIdAsync, id, employee);
    if(response.success) {
      yield put(updateEmployeeSuccess(response.result));
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteEmployee({ payload: id}: any) {
  try {
    const response: IResponse = yield call(deleteEmployeeByIdAsync, id);
    if(response.success) {
      yield put(deleteEmployeeSuccess(id));
    }
  } catch (error) {
    console.log(error);
  }
}


function* employeesSaga() {
  yield takeEvery(EmployeeTypes.GET_ALL_EMPLOYEES, getAllEmployees)
  yield takeEvery(EmployeeTypes.GET_RESTAURANT_EMPLOYEES, getRestaurantEmployees)
  yield takeEvery(EmployeeTypes.GET_RESTAURANT_DRIVERS, getRestaurantDrivers)
  yield takeEvery(EmployeeTypes.GET_EMPLOYEE_BY_ID, getEmployeeById)
  yield takeEvery(EmployeeTypes.ADD_EMPLOYEE, addEmployee)
  yield takeEvery(EmployeeTypes.UPDATE_EMPLOYEE, updateEmployee)
  yield takeEvery(EmployeeTypes.DELETE_EMPLOYEE, deleteEmployee)
}

export default employeesSaga;


