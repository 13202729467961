import { call, put, takeEvery } from "redux-saga/effects";
import { MigrationTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import {deleteMigrationByIdSuccess, getAllMigrationsSuccess, getMigrationByIdSuccess} from "./actions";

import { deleteMigrationByIdAsync, getAllMigrationsAsync, getMigrationByIdAsync } from "../../helpers/backend_helper";

function* getAllMigrations() {
  try {
    const response: IResponse = yield call(getAllMigrationsAsync);
    if(response.success) {
      yield put(getAllMigrationsSuccess(response.result.sort((a:any, b:any) => b.id-a.id)));
    } 
  } catch (error) {
    console.log(error)
  }
}

function* getMigrationById({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getMigrationByIdAsync, id);
    if(response.success) {
      yield put(getMigrationByIdSuccess(response.result));
    } 
  } catch (error) {
    console.log(error)
  }
}

function* deleteMigrationById({ payload: id}: any) {
  try {
    const response: IResponse = yield call(deleteMigrationByIdAsync, id);
    if(response.success) {
      yield put(deleteMigrationByIdSuccess(id));
    } 
  } catch (error) {
    console.log(error)
  }
}

function* migrationsSaga() {
  yield takeEvery(MigrationTypes.GET_ALL_MIGRATIONS, getAllMigrations)
  yield takeEvery(MigrationTypes.GET_MIGRATION_BY_ID, getMigrationById)
  yield takeEvery(MigrationTypes.DELETE_MIGRATION_BY_ID, deleteMigrationById)
}

export default migrationsSaga;


