import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {Dropdown,DropdownToggle,DropdownMenu,DropdownItem,} from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter, Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import avatar from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = (props: any) => {

  let history = useHistory()
  const [menu, setMenu] = useState<boolean>(false);
  const { currentUser } = useSelector((state: any) => state.login)

  const onSignOut = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('authUser')
    history.push('/login')
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={avatar}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{currentUser?.username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="/#">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="" className="dropdown-item" onClick={() => onSignOut()}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};
export default withTranslation()(withRouter(ProfileMenu));
