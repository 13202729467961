import { MenuToppingsActionTypes } from "./actionTypes";

//get all dmin by sitecode
export const getAllMenuToppings = () => ({
  type: MenuToppingsActionTypes.GET_ALL_MENU_TOPPINGS,
});

export const getAllMenuToppingsSuccess = (categories: any) => ({
  type: MenuToppingsActionTypes.GET_ALL_MENU_TOPPINGS_SUCCESS,
  payload: categories,
});

export const updateMenuToppingById = (id: string, item: any, history: any) => ({
  type: MenuToppingsActionTypes.UPDATE_MENU_TOPPING_BY_ID,
  payload: {id, item, history}
})

export const updateMenuToppingByIdSuccess = (item: any) => ({
  type: MenuToppingsActionTypes.UPDATE_MENU_TOPPING_BY_ID_SUCCESS,
  payload: item,
});


export const storeMenuTopping = (item: any) => ({
  type: MenuToppingsActionTypes.ADD_MENU_TOPPING,
  payload: item,
});

export const storeMenuToppingSuccess = (item: any) => ({
  type: MenuToppingsActionTypes.ADD_MENU_TOPPING_SUCCESS,
  payload: item,
});

export const deleteMenuTopping = (id: string) => ({
  type: MenuToppingsActionTypes.DELETE_MENU_TOPPING_BY_ID,
  payload: id
});

export const deleteMenuToppingSuccess = (id: string) => ({
  type: MenuToppingsActionTypes.DELETE_MENU_TOPPING_BY_ID_SUCCESS,
  payload: id
})

export const getMenuToppingById = (id: string) => ({
  type: MenuToppingsActionTypes.GET_MENU_TOPPING_BY_ID,
  payload: id
})

export const getMenuToppingByIdSuccess = (category: any) => ({
  type: MenuToppingsActionTypes.GET_MENU_TOPPING_BY_ID_SUCCESS,
  payload: category
})

export const menuToppingApiCallError = (error: any) => ({
  type: MenuToppingsActionTypes.TOPPING_API_RETURN_ERROR,
  payload: error
})

