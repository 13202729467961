import React, { useEffect, useState } from "react";
import { withRouter, useParams, Link, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getSupplierById, updateSupplierById } from "../../store/actions";
import { Col, Container, Row } from "reactstrap";
import { SpinnerCircular } from "spinners-react";
import { getUserRestaurantId } from "src/helpers/backend_helper";


const EditSupplier = (props: any) => {
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const history = useHistory();

  const { singleItem, loading } = useSelector((state: any) => state.suppliers);

  useEffect(() => {
    dispatch(getSupplierById(id));
  }, []);

  const handleEditItem = (values: any) => {
    dispatch(updateSupplierById(id, {
      ...values,
      RestaurantId: getUserRestaurantId() < 0 ? parseInt(singleItem?.RestaurantId || '1') : getUserRestaurantId(),
    }, history));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <div className="d-flex flex-row col-md-6">
              <div className="mb-3">                
                <Link to="/Suppliers">
                    <i className="fa fa-arrow-left me-1"></i>
                  Suppliers
                </Link>
              </div>

              <div className="mb-3" style={{marginLeft: 20}}>
                <h5>Edit supplier</h5>
              </div>
            </div>
          </Row>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "20vh" }}
              >
                <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
              </div>
            ) : (
              <AvForm
                onValidSubmit={(e: any, values: any) => {
                  handleEditItem(values);
                }}
              >
                <Row className="align-items-center">
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="supplierName"
                        label="Supplier name"
                        type="text"
                        errorMessage="name required"
                        validate={{
                          required: { value: true },
                        }}
                        defaultValue={singleItem?.supplierName || ""}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="shortCode"
                        label="Short code"
                        type="text"
                        defaultValue={(singleItem?.shortcode || "")}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="accountId"
                        label="Account ID"
                        type="text"
                        defaultValue={singleItem?.accountId || ""}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="vatId"
                        label="VAT ID"
                        type="number"
                        defaultValue={(singleItem?.vatId || "")}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-end">
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="creditLimit"
                        label="Credit limit"
                        type="number"
                        defaultValue={singleItem?.creditLimit || ""}
                      />
                    </div>
                  </Col>
                </Row>

                <Row style={{alignItems: 'flex-end'}}>
                  <Col>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success save-user update-btn" disabled={loading}>
                        {loading ? (
                          <SpinnerCircular
                            size={30}
                            thickness={150}
                            speed={150}
                            color="#36ad47"
                            secondaryColor="rgba(0, 0, 0, 0.44)"
                          />
                        ) : (
                          <>Update</>
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            )}
              
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditSupplier);
