import { KDScreenActionTypes } from "./actionTypes";

//get all dmin by sitecode
export const getAllKDScreens = () => ({
  type: KDScreenActionTypes.GET_ALL_KD_SCREENS,
});

export const getAllKDScreensSuccess = (categories: any) => ({
  type: KDScreenActionTypes.GET_ALL_KD_SCREENS_SUCCESS,
  payload: categories,
});


export const getRestaurantKDScreens = (id: string) => ({
  type: KDScreenActionTypes.GET_RESTAURANT_KD_SCREENS,
  payload: id
})

export const getRestaurantKDScreensSuccess = (restaurant: any) => ({
  type: KDScreenActionTypes.GET_RESTAURANT_KD_SCREENS_SUCCESS,
  payload: restaurant,
});

export const updateKDScreenById = (id: string, category: any, history: any) => ({
  type: KDScreenActionTypes.UPDATE_KD_SCREEN_BY_ID,
  payload: {id, category, history}
})

export const updateKDScreenByIdSuccess = (restaurant: any) => ({
  type: KDScreenActionTypes.UPDATE_KD_SCREEN_BY_ID_SUCCESS,
  payload: restaurant,
});


export const storeKDScreen = (restaurant: any) => ({
  type: KDScreenActionTypes.ADD_KD_SCREEN,
  payload: restaurant,
});

export const storeKDScreenSuccess = (restaurant: any) => ({
  type: KDScreenActionTypes.ADD_KD_SCREEN_SUCCESS,
  payload: restaurant,
});

export const deleteKDScreen = (id: string) => ({
  type: KDScreenActionTypes.DELETE_KD_SCREEN_BY_ID,
  payload: id
});

export const deleteKDScreenSuccess = (id: string) => ({
  type: KDScreenActionTypes.DELETE_KD_SCREEN_BY_ID_SUCCESS,
  payload: id
})

export const getKDScreenById = (id: string) => ({
  type: KDScreenActionTypes.GET_KD_SCREEN_BY_ID,
  payload: id
})

export const getKDScreenByIdSuccess = (category: any) => ({
  type: KDScreenActionTypes.GET_KD_SCREEN_BY_ID_SUCCESS,
  payload: category
})

export const kdScreenApiCallError = (error: any) => ({
  type: KDScreenActionTypes.API_RETURN_ERROR,
  payload: error
})

