import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useParams } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getOrderDetailById } from "src/store/orderDetails/actions";
import { getRestaurantById } from "src/store/actions";
import moment from 'moment';


const StrokeByFlag = (props: React.PropsWithChildren<any>) => props.stroke ? <s style={{color: 'red'}}>{props.children}</s> : <>{props.children}</>

const OrderDetail = (props: any) => {
  const dispatch = useDispatch();
  const { orderDetails, loading: loadingDetail } = useSelector((state: any) => state.orderDetails);
  const { singleRestaurant: restaurant, loading: loadingRestaurant } = useSelector((state: any) => state.restaurant);

  useEffect(() => {
    if (props.open) {
      dispatch(getOrderDetailById(props.order.id));
      dispatch(getRestaurantById(props.order.RestaurantId));
    }
  }, [props.open])

  const renderModal = () => {
    if (!props.order) {
      return null;
    }
    return (
      <div className="add-company-form-cotainer">
        <table>
          <tr>
            <td colSpan={2} style={{textAlign: 'center'}}>
              {restaurant.ShopName}
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{textAlign: 'center'}}>
              {restaurant.Street}
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{textAlign: 'center'}}>
              {restaurant.City} {restaurant.PostCode}
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{textAlign: 'center'}}>
              Tel: {restaurant.Tel1}
            </td>
          </tr>
          
          {orderDetails.map((orderDetail: any, idx: number) => {
            let dressings = orderDetail.Dressing.split("@").filter((x: string) => !!x).join("+")
            let linkedDataReceipt = (JSON.parse(orderDetail?.LinkedDataReceipt))?.detailData;
            if (dressings) {
              dressings = "+" + dressings;
            }
            return (
              <>
                <tr style={{
                  borderTop: idx === 0 ? '1px solid black' : undefined, 
                  borderBottom: (idx === orderDetails.length-1 && linkedDataReceipt!=null && linkedDataReceipt?.length==0) ? '1px solid black' : undefined,
                }}>
                  <td>
                    <StrokeByFlag stroke={!orderDetail.Status}>
                      {orderDetail.menuItem ? orderDetail.menuItem.Name : (orderDetail.Menu_Item_Name + dressings)} x {orderDetail.Quantity}
                    </StrokeByFlag>
                    <br/>
                    <StrokeByFlag stroke={!orderDetail.Status}>
                      {orderDetail.subMenuItem?.Name}
                    </StrokeByFlag>
                  </td>
                  <td style={{verticalAlign: 'top', textAlign: 'right'}}>
                    <StrokeByFlag stroke={!orderDetail.Status}>
                      {orderDetail.Price}
                    </StrokeByFlag>
                  </td>
                </tr>
                {linkedDataReceipt!=null && linkedDataReceipt.map((linkeData:any, lidx:number)=>{
                  return (
                    <>
                      {(lidx === 0 || linkeData.linkedCategoryName !== linkedDataReceipt[lidx - 1].linkedCategoryName) && (
                        <tr>
                          <td>{linkeData?.linkedCategoryName}</td>
                          <td></td>
                        </tr>
                      )}
                      <tr style={{
                        borderBottom: (idx === orderDetails.length-1 && lidx === linkedDataReceipt.length-1) ? '1px solid black' : undefined,
                      }}>
                        <td>{"+"+linkeData?.linkedMenuItem?.menu_item_name}</td>
                        <td style={{verticalAlign: 'top', textAlign: 'right'}}>{linkeData?.linkedMenuItem?.price}</td>
                      </tr>
                    </>
                  )
                })}
              </>
            )
          })}
          <tr>
            <td style={{fontSize: 16, fontWeight: 'bold'}}>Total to pay</td>
            <td style={{textAlign: 'right'}}>{props.order.TotalAmount.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Sub Total</td>
            <td style={{textAlign: 'right', fontSize: 12, fontWeight: 'bold'}}>{(props.order.TotalAmount-props.order.ServiceCharge).toFixed(2)}</td>
          </tr>
          <tr>
            <td>{props.order.Order_Type=="DINE IN"?"Service Charge Fee":"Packaging Fee"}</td>
            <td style={{textAlign: 'right', fontSize: 12, fontWeight: 'bold'}}>{props.order.ServiceCharge.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Order date</td>
            <td style={{textAlign: 'right', fontSize: 12, fontWeight: 'bold'}}>{ moment.utc(props.order.OrderDate).format('MM/DD/yy HH:mm')}</td>
          </tr>
          <tr>
            <td>Order No:</td>
            <td style={{textAlign: 'right'}}>{props.order.OrderNo}</td>
          </tr>
          <tr>
            <td>Operator No:</td>
            <td style={{textAlign: 'right'}}>{props.order.Operator}</td>
          </tr>
          <tr>
            <td>Items:</td>
            <td style={{textAlign: 'right'}}>{orderDetails.length}</td>
          </tr>
        </table>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Order detail</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-admin-form" style={{ padding: 20 }}>
        
          <AvForm>
            {renderModal()}
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(OrderDetail);
