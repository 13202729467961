import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { LicenseTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import {addLicenseSuccess,addLicenseFail, getAllLicensesSuccess, getLicenseByIdSuccess, updateLicenseByIdSuccess, deleteLicenseByIdSuccess } from "./actions";

import { getAllLicensesAsync, addLicenseAsync, getLicenseByIdAsync, updateLicenseByIdAsync, deleteLicenseByIdAsync } from "../../helpers/backend_helper";

function* getAllLicenses() {
  try {
    const response: IResponse = yield call(getAllLicensesAsync);
    if(response.success) {
      yield put(getAllLicensesSuccess(response.result.sort((a:any, b:any) => b.id-a.id)));
    } 
  } catch (error) {
    console.log(error)
  }
}

function* addLicense({ payload: license }: any) {
  try {
    const response: IResponse = yield call(addLicenseAsync, license);
    console.log('response =>', response)
    if(response.success) {
      yield put(addLicenseSuccess(response.result));
    } else {
      yield put(addLicenseFail(response.message));
    }
  } catch (error) {
    console.log('error =>', error)
  }
}

function* getLicenseById({ payload: id }: any) {
  try {
    const response: IResponse = yield call(getLicenseByIdAsync, id);
    if(response.success) {
      yield put(getLicenseByIdSuccess(response.result));
    }
  } catch (error) {
    console.log('error =>', error)
  }
}


function* updateLicenseById({ payload: {id, license, history} }: any) {
  try {
    const response: IResponse = yield call(updateLicenseByIdAsync, id, license);
    if(response.success) {
      yield put(updateLicenseByIdSuccess(response.result));
      history.push('/licenses')
    }
  } catch (error) {
    console.log(error)
  }
}

function* deleteLicenseById({ payload: id }: any) {
  try {
    const response: IResponse = yield call(deleteLicenseByIdAsync, id);
    if(response.success) {
      yield put(deleteLicenseByIdSuccess(id));
    }
  } catch (error) {
    console.log(error)
  }
}


function* licenseSaga() {
  yield takeEvery(LicenseTypes.GET_ALL_LICENSES, getAllLicenses)
  yield takeEvery(LicenseTypes.ADD_LICENSE, addLicense)
  yield takeEvery(LicenseTypes.GET_LICENSE_BYID, getLicenseById)
  yield takeEvery(LicenseTypes.UPDATE_LICENSE_BY_ID, updateLicenseById)
  yield takeEvery(LicenseTypes.DELETE_LICENSE_BY_ID, deleteLicenseById)

}

export default licenseSaga;


