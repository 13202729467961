import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { AdminTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import {
  getAdminsBySiteCodeSuccess,
  addAdminSuccess,
  addAdminFail,
  getAdminByIdSuccess,
  getAdminByIdFail,
  updateAdminByDocIdSuccess,
  updateAdminByDocIdFail,
  deleteAdminByDocIdSuccess,
  deleteAdminByDocIdFail
} from "./actions";

import {
  getAllAdminsAsync,
  addAdminAsync,
  getAdminByIdAsync,
  updateAdminByDocIdAsync,
  deleteAdminByDocIdAsyc
} from "../../helpers/backend_helper";

function* getAllAdmins() {
  try {
    const response: IResponse = yield call(getAllAdminsAsync);
    if(response.success) {
      yield put(getAdminsBySiteCodeSuccess(response.result));
    } 
  } catch (error) {
    console.log(error)
  }
}


function* addAdmin({ payload: admin }: any) {
  try {
    const response: IResponse = yield call(addAdminAsync, admin);
    if(response.success) {
      yield put(addAdminSuccess(response.result));
    }
  } catch (error) {
    yield put(addAdminFail(error));
  }
}

function* getAdminById({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getAdminByIdAsync, id);
    if(response.success) {
      yield put(getAdminByIdSuccess(response.result));
    }
  } catch (error) {
    yield put(getAdminByIdFail(error));
  }
}


function* updateAdminByDocId({ payload: {docid, admin, history} }: any) {
  try {
    const response: IResponse = yield call(updateAdminByDocIdAsync, docid, admin);
    if(response.success) {
      yield put(updateAdminByDocIdSuccess(response.result));
      history.push('/admins')
    }
  } catch (error) {
    yield put(updateAdminByDocIdFail(error));
  }
}


function* deleteAdminByDocId({ payload: id }: any) {
  try {
    const response: IResponse = yield call(deleteAdminByDocIdAsyc, id);
    if(response.success) {
      yield put(deleteAdminByDocIdSuccess(id));
    }
  } catch (error) {
    console.log(error)
    yield put(deleteAdminByDocIdFail(error));
  }
}



function* adminSaga() {
  yield takeEvery(AdminTypes.GET_ALL_ADMINS, getAllAdmins)
  yield takeEvery(AdminTypes.ADD_ADMIN, addAdmin)
  yield takeEvery(AdminTypes.GET_ADMIN_BY_ID, getAdminById)
  yield takeEvery(AdminTypes.DELETE_ADMIN_BY_DOCID, deleteAdminByDocId),
  yield takeEvery(AdminTypes.UPDATE_ADMIN_BY_DOCID, updateAdminByDocId);
}

export default adminSaga;


