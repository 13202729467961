import { MenuInstructionsActionTypes } from "./actionTypes";

//get all dmin by sitecode
export const getAllMenuInstructions = () => ({
  type: MenuInstructionsActionTypes.GET_ALL_MENU_INSTRUCTIONS,
});

export const getAllMenuInstructionsSuccess = (categories: any) => ({
  type: MenuInstructionsActionTypes.GET_ALL_MENU_INSTRUCTIONS_SUCCESS,
  payload: categories,
});

export const updateMenuInstructionById = (id: string, item: any, history: any) => ({
  type: MenuInstructionsActionTypes.UPDATE_MENU_INSTRUCTION_BY_ID,
  payload: {id, item, history}
})

export const updateMenuInstructionByIdSuccess = (item: any) => ({
  type: MenuInstructionsActionTypes.UPDATE_MENU_INSTRUCTION_BY_ID_SUCCESS,
  payload: item,
});


export const storeMenuInstruction = (item: any) => ({
  type: MenuInstructionsActionTypes.ADD_MENU_INSTRUCTION,
  payload: item,
});

export const storeMenuInstructionSuccess = (item: any) => ({
  type: MenuInstructionsActionTypes.ADD_MENU_INSTRUCTION_SUCCESS,
  payload: item,
});

export const deleteMenuInstruction = (id: string) => ({
  type: MenuInstructionsActionTypes.DELETE_MENU_INSTRUCTION_BY_ID,
  payload: id
});

export const deleteMenuInstructionSuccess = (id: string) => ({
  type: MenuInstructionsActionTypes.DELETE_MENU_INSTRUCTION_BY_ID_SUCCESS,
  payload: id
})

export const getMenuInstructionById = (id: string) => ({
  type: MenuInstructionsActionTypes.GET_MENU_INSTRUCTION_BY_ID,
  payload: id
})

export const getMenuInstructionByIdSuccess = (category: any) => ({
  type: MenuInstructionsActionTypes.GET_MENU_INSTRUCTION_BY_ID_SUCCESS,
  payload: category
})

export const menuInstructionApiCallError = (error: any) => ({
  type: MenuInstructionsActionTypes.INSTRUCTION_API_RETURN_ERROR,
  payload: error
})

