export enum CustomerTypes {
  GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS",
  GET_ALL_CUSTOMERS_SUCCESS = 'GET_ALL_CUSTOMERS_SUCCESS',

  GET_CUSTOMER_BY_ID = "GET_CUSTOMER_BY_ID",
  GET_CUSTOMER_BY_ID_SUCCESS = "GET_CUSTOMER_BY_ID_SUCCESS",

  GET_ADMIN_BY_ID = 'GET_ADMIN_BY_ID',
  GET_ADMIN_BY_ID_SUCCESS = 'GET_ADMIN_BY_ID_SUCCESS',
  GET_ADMIN_BY_ID_FAIL = 'GET_ADMIN_BY_ID_FAIL',

}
