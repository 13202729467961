import { GiftCardsActionTypes } from "./actionTypes";

//get all dmin by sitecode
export const getRestaurantGiftCards = (id: string) => ({
  type: GiftCardsActionTypes.GET_RESTAURANT_GIFT_CARDS,
  payload: id
});

export const getRestaurantGiftCardsSuccess = (categories: any) => ({
  type: GiftCardsActionTypes.GET_RESTAURANT_GIFT_CARDS_SUCCESS,
  payload: categories,
});

export const updateGiftCardById = (id: string, item: any, history: any) => ({
  type: GiftCardsActionTypes.UPDATE_GIFT_CARD_BY_ID,
  payload: {id, item, history}
})

export const updateGiftCardByIdSuccess = (item: any) => ({
  type: GiftCardsActionTypes.UPDATE_GIFT_CARD_BY_ID_SUCCESS,
  payload: item,
});


export const storeGiftCard = (item: any) => ({
  type: GiftCardsActionTypes.ADD_GIFT_CARD,
  payload: item,
});

export const storeGiftCardSuccess = (item: any) => ({
  type: GiftCardsActionTypes.ADD_GIFT_CARD_SUCCESS,
  payload: item,
});

export const deleteGiftCard = (id: string) => ({
  type: GiftCardsActionTypes.DELETE_GIFT_CARD_BY_ID,
  payload: id
});

export const deleteGiftCardSuccess = (id: string) => ({
  type: GiftCardsActionTypes.DELETE_GIFT_CARD_BY_ID_SUCCESS,
  payload: id
})

export const getGiftCardById = (id: string) => ({
  type: GiftCardsActionTypes.GET_GIFT_CARD_BY_ID,
  payload: id
})

export const getGiftCardByIdSuccess = (category: any) => ({
  type: GiftCardsActionTypes.GET_GIFT_CARD_BY_ID_SUCCESS,
  payload: category
})

export const giftCardApiCallError = (error: any) => ({
  type: GiftCardsActionTypes.GIFT_CARD_API_RETURN_ERROR,
  payload: error
})

