import { PrintersActionTypes } from "./actionTypes";

//get all dmin by sitecode
export const getRestaurantPrinters = (id: string) => ({
  type: PrintersActionTypes.GET_RESTAURANT_PRINTERS,
  payload: id
});

export const getRestaurantPrintersSuccess = (categories: any) => ({
  type: PrintersActionTypes.GET_RESTAURANT_PRINTERS_SUCCESS,
  payload: categories,
});

export const updatePrinterById = (id: string, item: any, history: any) => ({
  type: PrintersActionTypes.UPDATE_PRINTER_BY_ID,
  payload: {id, item, history}
})

export const updatePrinterByIdSuccess = (item: any) => ({
  type: PrintersActionTypes.UPDATE_PRINTER_BY_ID_SUCCESS,
  payload: item,
});


export const storePrinter = (item: any) => ({
  type: PrintersActionTypes.ADD_PRINTER,
  payload: item,
});

export const storePrinterSuccess = (item: any) => ({
  type: PrintersActionTypes.ADD_PRINTER_SUCCESS,
  payload: item,
});

export const deletePrinter = (id: string) => ({
  type: PrintersActionTypes.DELETE_PRINTER_BY_ID,
  payload: id
});

export const deletePrinterSuccess = (id: string) => ({
  type: PrintersActionTypes.DELETE_PRINTER_BY_ID_SUCCESS,
  payload: id
})

export const getPrinterById = (id: string) => ({
  type: PrintersActionTypes.GET_PRINTER_BY_ID,
  payload: id
})

export const getPrinterByIdSuccess = (category: any) => ({
  type: PrintersActionTypes.GET_PRINTER_BY_ID_SUCCESS,
  payload: category
})

export const printerApiCallError = (error: any) => ({
  type: PrintersActionTypes.PRINTERS_API_RETURN_ERROR,
  payload: error
})

