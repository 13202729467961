import { SubMenuItemsActionTypes } from "./actionTypes";

//get all dmin by sitecode
export const getAllSubMenuItems = () => ({
  type: SubMenuItemsActionTypes.GET_ALL_SUBMENUITEMS,
});

export const getAllSubMenuItemsSuccess = (categories: any) => ({
  type: SubMenuItemsActionTypes.GET_ALL_SUBMENUITEMS_SUCCESS,
  payload: categories,
});


export const getMenuItemSubMenuItems = (id: string) => ({
  type: SubMenuItemsActionTypes.GET_MENUITEM_SUBMENUITEMS,
  payload: id
})

export const getMenuItemSubMenuItemsSuccess = (items: any) => ({
  type: SubMenuItemsActionTypes.GET_MENUITEM_SUBMENUITEMS_SUCCESS,
  payload: items,
});

export const updateSubMenuItemById = (id: string, item: any, history: any) => ({
  type: SubMenuItemsActionTypes.UPDATE_SUBMENUITEM_BY_ID,
  payload: {id, item, history}
})

export const updateSubMenuItemByIdSuccess = (item: any) => ({
  type: SubMenuItemsActionTypes.UPDATE_SUBMENUITEM_BY_ID_SUCCESS,
  payload: item,
});


export const storeSubMenuItem = (item: any) => ({
  type: SubMenuItemsActionTypes.ADD_SUBMENUITEM,
  payload: item,
});

export const storeSubMenuItemSuccess = (item: any) => ({
  type: SubMenuItemsActionTypes.ADD_SUBMENUITEM_SUCCESS,
  payload: item,
});

export const deleteSubMenuItem = (id: string) => ({
  type: SubMenuItemsActionTypes.DELETE_SUBMENUITEM_BY_ID,
  payload: id
});

export const deleteSubMenuItemSuccess = (id: string) => ({
  type: SubMenuItemsActionTypes.DELETE_SUBMENUITEM_BY_ID_SUCCESS,
  payload: id
})

export const getSubMenuItemById = (id: string) => ({
  type: SubMenuItemsActionTypes.GET_SUBMENUITEM_BY_ID,
  payload: id
})

export const getSubMenuItemByIdSuccess = (category: any) => ({
  type: SubMenuItemsActionTypes.GET_SUBMENUITEM_BY_ID_SUCCESS,
  payload: category
})

export const subMenuItemApiCallError = (error: any) => ({
  type: SubMenuItemsActionTypes.SMI_API_RETURN_ERROR,
  payload: error
})

