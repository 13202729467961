import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { addAdmin, addEmployee } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { getUserRestaurantId } from "src/helpers/backend_helper";
import { cityValues, countyValues } from ".";



const AddEmployee = (props: any) => {
  const dispatch = useDispatch();
  const formRef = useRef<any>();

  const { adding, addingSuccess } = useSelector((state: any) => state.employees);
  const { currentUser } = useSelector((state: any) => state.login);
  const [driverAccess, setDriverAccess] = useState("111");
  const [systemAccess, setSystemAccess] = useState("111");
  const [orderAccess, setOrderAccess] = useState("111111111111");
  const [showAccess, setShowAccess] = useState(false);

  useEffect(() => {
    if (!adding && addingSuccess) {
      props.onDrawerClose(false);
    }
  }, [adding, addingSuccess]);

  useEffect(() => {
    if (props.open) {
      if (formRef.current) {
        formRef.current.reset();
      }
      setTimeout(() => {
        setDriverAccess("111");
        setSystemAccess("111");
        setOrderAccess("111111111111");
        setShowAccess(true);
      }, 500);
    }
  }, [props.open])

  const handleAdd = (values: any) => {
    if (values.Password !== values.ConfirmPassword) {
      return;
    }
    const filteredValues: any = {};
    for(let i=0; i<Object.keys(values).length; i++) {
      if (Object.keys(values)[i].startsWith("chk")) {
        continue;
      }
      filteredValues[Object.keys(values)[i]] = values[Object.keys(values)[i]];
    }
    filteredValues["Driver_Access"] = driverAccess;
    filteredValues["System_Access"] = systemAccess;
    filteredValues["Orders_Access"] = orderAccess;
    filteredValues["ReportingAccess"] = "";
    filteredValues["IsDriver"] = props.addDriver?1:null;
    filteredValues["Title"] = props.Title || "Mr";
    if (getUserRestaurantId() > 0) {
      dispatch(addEmployee({...filteredValues, "RestaurantId": getUserRestaurantId()}));
    } else {
      if (props.selectedRestId > 0) {
        dispatch(addEmployee({...filteredValues, "RestaurantId": props.selectedRestId}));
      } else {
        console.log("Select restaurant")
      }
    }
  };

  const handleChkChange = (idx: number, e: any) => {
    const checked = e.target.checked ? "1" : "0";
    if (idx < 10) {
      const i = idx % 10;
      setDriverAccess((da: string) => da.substring(0, i) + checked + da.substring(i+1));
    } else if (idx < 20) {
      const i = idx % 10;
      setSystemAccess((da: string) => da.substring(0, i) + checked + da.substring(i+1));
    } else {
      const i = idx - 20;
      setOrderAccess((da: string) => da.substring(0, i) + checked + da.substring(i+1));
    }
  }

  return (
    <React.Fragment>
      <div className="add-admin">
        <MetaTags>
          <title>Add New {props.addDriver ? "Driver" : "Employee"}</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-admin-form" style={{ padding: 20 }}>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleAdd(values);
            }}            
            ref={formRef}
          >
            <div className="add-admin-form-cotainer">
              <Row>
              <Col md="6">
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="Title"
                        label="Title"
                        placeholder="Select title"
                        type="select"
                        value="Mr"
                      >
                        <option value="Mr">Mr</option>
                        <option value="Miss">Miss</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Dr">Dr</option>
                      </AvField>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="Designation"
                        label="Designation"
                        placeholder="Input designation"
                        type="text"
                        value={""}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="Login"
                        label="Login"
                        placeholder="Input Login"
                        type="text"
                        validate={{
                          required: { value: true }
                        }}
                        value={""}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="EmployeeNo"
                        label="EmployeeNo"
                        placeholder="Input EmployeeNo"
                        type="number"
                        validate={{
                          required: { value: true }
                        }}
                        value={""}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="Password"
                        label="Password"
                        placeholder="Input Password"
                        type="password"
                        validate={{
                          required: { value: true },
                          minLength: { value: 4 },
                          maxLength: { value: 4 }
                        }}
                        value={""}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="ConfirmPassword"
                        label="Confirm password"
                        placeholder="Confirm Password"
                        type="password"
                        validate={{
                          required: { value: true },
                          match: {value: 'Password'}
                        }}
                        value={""}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="First_Name"
                        label="First Name"
                        type="text"
                        errorMessage="Invalid Name"
                        validate={{
                          required: { value: true },
                        }}
                        value={""}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="Last_Name"
                        label="Last Name"
                        type="text"
                        errorMessage="Invalid Name"
                        validate={{
                          required: { value: true },
                        }}
                        value={""}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              
              <Col md="6">
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="HouseNo"
                        label="House No."
                        type="text"
                        errorMessage="Invalid house no."
                        value={""}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="Street"
                        label="Street"
                        type="text"
                        errorMessage="Invalid Street"
                        value={""}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="City"
                        label="City"
                        type="select"
                        errorMessage="Invalid City"
                        value={""}
                      >
                        {cityValues.map((value) => (
                          <option key={value} value={value}>{value}</option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="Postcode"
                        label="Postcode"
                        type="text"
                        errorMessage="Invalid Postcode"
                        value={""}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="County"
                        label="County"
                        type="select"
                        errorMessage="Invalid County"
                        value={""}
                      >
                        {countyValues.map((value) => (
                          <option key={value} value={value}>{value}</option>
                        ))}
                      </AvField>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="Telephone"
                        label="Telephone"
                        type="text"
                        errorMessage="Invalid Telephone"
                        value={""}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="Email"
                        label="Email"
                        type="text"
                        errorMessage="Invalid Email"
                        validate={{
                          required: { value: true },
                        }}
                        value={""}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <AvField
                        name="Mobile"
                        label="Mobile"
                        type="text"
                        errorMessage="Invalid Mobile"
                        value={""}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              </Row>
                        
              <Row>
                {showAccess && (
                  <Col md="9">                  
                    <label>Security Level</label>
                    <div>
                      <label>Driver Management</label>
                      <div className="d-flex flex-row">
                        <div>
                          <AvField
                            name="chkDriverPayment"
                            label="Driver Payment"
                            type="checkbox"
                            value={driverAccess.at(0) === "1"}
                            onChange={(e: any) => handleChkChange(0, e)}
                          />
                        </div>
                        <div className="mx-2">
                          <AvField
                            name="chkDriverAnalysis"
                            label="Driver Analysis"
                            type="checkbox"
                            value={driverAccess.at(1) === "1"}
                            onChange={(e: any) => handleChkChange(1, e)}
                          />
                        </div>
                        <div className="mx-2">
                          <AvField
                            name="chkDriverAnalysis"
                            label="Assign Drivers"
                            type="checkbox"
                            value={driverAccess.at(2) === "1"}
                            onChange={(e: any) => handleChkChange(2, e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <label>System Management</label>
                      <div className="d-flex flex-row">
                        <div>
                          <AvField
                            name="chkSystemManagement"
                            label="System Management"
                            type="checkbox"
                            value={systemAccess.substring(0, 1) === "1"}
                            onChange={(e: any) => handleChkChange(10, e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <label>Order Management</label>
                      <div className="d-flex flex-row">
                        <div>
                          <AvField
                            name="chkTakeOrders"
                            label="Take Orders"
                            type="checkbox"
                            value={orderAccess.at(0) === "1"}
                            onChange={(e: any) => handleChkChange(20, e)}
                          />
                        </div>
                        <div className="mx-2">
                          <AvField
                            name="chkEditOrders"
                            label="Edit Orders"
                            type="checkbox"
                            value={orderAccess.at(1) === "1"}
                            onChange={(e: any) => handleChkChange(21, e)}
                          />
                        </div>
                        <div className="mx-2">
                          <AvField
                            name="chkCancelOrders"
                            label="Cancel Orders"
                            type="checkbox"
                            value={orderAccess.at(2) === "1"}
                            onChange={(e: any) => handleChkChange(22, e)}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div>
                          <AvField
                            name="chkOpenTill"
                            label="Open Till"
                            type="checkbox"
                            value={orderAccess.at(3) === "1"}
                            onChange={(e: any) => handleChkChange(23, e)}
                          />
                        </div>
                        <div className="mx-2">
                          <AvField
                            name="chkGiveDiscount"
                            label="Give Discount"
                            type="checkbox"
                            value={orderAccess.at(4) === "1"}
                            onChange={(e: any) => handleChkChange(24, e)}
                          />
                        </div>
                        <div className="mx-2">
                          <AvField
                            name="chkViewOrders"
                            label="View Orders"
                            type="checkbox"
                            value={orderAccess.at(5) === "1"}
                            onChange={(e: any) => handleChkChange(25, e)}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div>
                          <AvField
                            name="chkGiveFree"
                            label="Give Free"
                            type="checkbox"
                            value={orderAccess.at(6) === "1"}
                            onChange={(e: any) => handleChkChange(26, e)}
                          />
                        </div>
                        <div className="mx-2">
                          <AvField
                            name="chkHoldOrders"
                            label="Hold Orders"
                            type="checkbox"
                            value={orderAccess.at(7) === "1"}
                            onChange={(e: any) => handleChkChange(27, e)}
                          />
                        </div>
                        <div className="mx-2">
                          <AvField
                            name="chkCancelOrderedItem"
                            label="Cancel Ordred Item"
                            type="checkbox"
                            value={orderAccess.at(8) === "1"}
                            onChange={(e: any) => handleChkChange(28, e)}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div>
                          <AvField
                            name="chkReducedOrderedItems"
                            label="Reduced Ordered Items"
                            type="checkbox"
                            value={orderAccess.at(9) === "1"}
                            onChange={(e: any) => handleChkChange(29, e)}
                          />
                        </div>
                        <div className="mx-2">
                          <AvField
                            name="chkContinueHoldOrder"
                            label="Continue Hold Order"
                            type="checkbox"
                            value={orderAccess.at(10) === "1"}
                            onChange={(e: any) => handleChkChange(30, e)}
                          />
                        </div>
                        <div className="mx-2">
                          <AvField
                            name="chkShowWebTableReservation"
                            label="Show Web Table Reservation"
                            type="checkbox"
                            value={orderAccess.at(11) === "1"}
                            onChange={(e: any) => handleChkChange(31, e)}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
                
                <Col md="3">
                  <div className="mb-3">
                    <AvField
                      name="Remarks"
                      label="Remarks"
                      type="textarea"
                      errorMessage="Invalid Remarks"
                      value={""}
                    />
                  </div>
                </Col>
              </Row>
              
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user update-btn" disabled={adding}>
                      {adding ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : (
                        <>Add</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddEmployee);
