import { BookingActionTypes } from "./actionTypes";

//get all dmin by sitecode
export const getRestaurantBookings = (restaurantId: number) => ({
  type: BookingActionTypes.GET_RESTAURANT_BOOKINGS,
  payload: restaurantId
});

export const getRestaurantBookingsSuccess = (bookings: any) => ({
  type: BookingActionTypes.GET_RESTAURANT_BOOKINGS_SUCCESS,
  payload: bookings,
});

export const getBookingById = (id: string) => ({
  type: BookingActionTypes.GET_BOOKING_BY_ID,
  payload: id
})

export const getBookingByIdSuccess = (category: any) => ({
  type: BookingActionTypes.GET_BOOKING_BY_ID_SUCCESS,
  payload: category
})

export const storeBooking = (item: any) => ({
  type: BookingActionTypes.STORE_BOOKING,
  payload: item,
});

export const storeBookingSuccess = (item: any) => ({
  type: BookingActionTypes.STORE_BOOKING_SUCCESS,
  payload: item,
});

export const updateBookingById = (id: string, item: any, history: any) => ({
  type: BookingActionTypes.UPDATE_BOOKING_BY_ID,
  payload: {id, item, history}
})

export const updateBookingByIdSuccess = (item: any) => ({
  type: BookingActionTypes.UPDATE_BOOKING_BY_ID_SUCCESS,
  payload: item,
});

export const deleteBooking = (id: string) => ({
  type: BookingActionTypes.DELETE_BOOKING_BY_ID,
  payload: id
});

export const deleteBookingSuccess = (id: string) => ({
  type: BookingActionTypes.DELETE_BOOKING_BY_ID_SUCCESS,
  payload: id
})