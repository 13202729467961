import { EmployeeTypes } from "./actionTypes";

export const INIT_STATE: any = {
  employees: [],
  drivers: [],
  singleEmployee: {},
  error: {},
  loading: false,
  loadingSingleEmployee: false,
  success: false,
  adding: false,
  addingSuccess: false
};

const employee = (state = INIT_STATE, action: any) => {
  switch (action.type) {

    case EmployeeTypes.GET_ALL_EMPLOYEES:
      return {
        ...state,
        loading: true,
      };

    case EmployeeTypes.GET_ALL_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: action.payload,
      };

    case EmployeeTypes.GET_RESTAURANT_EMPLOYEES:
      return {
        ...state,
        loading: true,
      };

    case EmployeeTypes.GET_RESTAURANT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: action.payload,
      };

    case EmployeeTypes.GET_RESTAURANT_DRIVERS:
      return {
        ...state,
        loading: true,
      };

    case EmployeeTypes.GET_RESTAURANT_DRIVERS_SUCCESS:
      return {
        ...state,
        loading: false,
        drivers: action.payload,
      };
  
    case EmployeeTypes.GET_EMPLOYEE_BY_ID:
      return {
        ...state,
        loadingSingleEmployee: true,
      }
    case EmployeeTypes.GET_EMPLOYEE_BY_ID_SUCCESS:
      return {
        ...state,
        loadingSingleEmployee: false,
        singleEmployee: action.payload
      }
    case EmployeeTypes.ADD_EMPLOYEE:
      return {
        ...state,
        adding: true
      }
    case EmployeeTypes.ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        adding: false,
        addingSuccess: true
      }
    case EmployeeTypes.DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        drivers: state.drivers.filter((d: any) => d.id !== action.payload),
        employees: state.employees.filter((e: any) => e.id !== action.payload)
      }
    default:
      return state;
  }
};

export default employee;
