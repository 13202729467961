import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useParams } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getAllMigrations, getAllRestaurants, storeRestaurant } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { postFormData } from "src/helpers/api_helper";
import * as url from "../../helpers/url_helper";

const AddMigration = (props: any) => {
  const dispatch = useDispatch();
  const { restaurants } = useSelector((state: any) => state.restaurant);
  const [uploading, setUploading] = useState(false);
  const { currentUser } = useSelector((state: any) => state.login);
  const [dbFile, setDbFile] = useState<any>(null);
  const dbFileRef = useRef();

  useEffect(() => {
    if (props.open) {
      dispatch(getAllRestaurants());
    }
  }, [props.open])

  const handleAddMigration = (values: any) => {
    const formData = new FormData();
    formData.append("dbFile", dbFile);
    setUploading(true);
    postFormData(`${url.ADD_RESTAURANT_MIGRATIONS}/${values.RestaurantId}?migrateOrders=${!!values.migrateOrders}&migrateCustomers=${!!values.migrateCustomers}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      setUploading(false);
      console.log("ress ", JSON.stringify(res));
      props.onDrawerClose(false, res.result);
    })
  };


  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Add New Admin</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-admin-form" style={{ padding: 20 }}>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleAddMigration(values);
            }}
          >
            <div className="add-company-form-cotainer" style={{ padding: 20 }}>
              <Row>
                <Col md="12">
                  <div className="mb-3">                      
                    <AvField 
                      type="select" 
                      label="Restaurant" 
                      name="RestaurantId" 
                      errorMessage="Restaurant required"
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option value="" hidden>Select restaurant</option>
                      {restaurants.map((rest: any) => (
                        <option key={rest.id} value={rest.id}>{rest.ShopName}</option>
                      ))}
                    </AvField>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <div className="mb-3">
                    <AvField
                      name="migrateOrders"
                      label="Migrate last 1 week orders"
                      type="checkbox"
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <div className="mb-3">
                    <AvField
                      name="migrateCustomers"
                      label="Migrate customers"
                      type="checkbox"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div className="mb-3">
                    <input
                      id="menu-category-upload-csv-file"
                      name="menuItems"
                      type="file"
                      onChange={(e: any) => {
                        setDbFile(e.target.files[0])
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success" disabled={uploading}>
                      {uploading ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : (
                        <>Upload</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(AddMigration);
