import { MenuActionTypes } from "./actionTypes";

//get all dmin by sitecode
export const getAllMenuCategories = () => ({
  type: MenuActionTypes.GET_ALL_MENU_CATEGORIES,
});

export const getAllMenuCategoriesSuccess = (categories: any) => ({
  type: MenuActionTypes.GET_ALL_MENU_CATEGORIES_SUCCESS,
  payload: categories,
});


export const getRestaurantMenuCategories = (id: string) => ({
  type: MenuActionTypes.GET_RESTAURANT_MENU_CATEGORIES,
  payload: id
})

export const getRestaurantMenuCategoriesWithItems = (id: string) => ({
  type: MenuActionTypes.GET_RESTAURANT_MENU_CATEGORIES_WITH_ITEMS,
  payload: id
})

export const getRestaurantMenuCategoriesSuccess = (restaurant: any) => ({
  type: MenuActionTypes.GET_RESTAURANT_MENU_CATEGORIES_SUCCESS,
  payload: restaurant,
});

export const updateMenuCategoryById = (id: string, category: any, history: any) => ({
  type: MenuActionTypes.UPDATE_MENU_CATEGORY_BY_ID,
  payload: {id, category, history}
})

export const updateMenuCategoryByIdSuccess = (restaurant: any) => ({
  type: MenuActionTypes.UPDATE_MENU_CATEGORY_BY_ID_SUCCESS,
  payload: restaurant,
});


export const storeMenuCategory = (restaurant: any) => ({
  type: MenuActionTypes.ADD_MENU_CATEGORY,
  payload: restaurant,
});

export const storeMenuCategorySuccess = (restaurant: any) => ({
  type: MenuActionTypes.ADD_MENU_CATEGORY_SUCCESS,
  payload: restaurant,
});

export const deleteMenuCategory = (id: string) => ({
  type: MenuActionTypes.DELETE_MENU_CATEGORY_BY_ID,
  payload: id
});

export const deleteMenuCategorySuccess = (id: string) => ({
  type: MenuActionTypes.DELETE_MENU_CATEGORY_BY_ID_SUCCESS,
  payload: id
})

export const getMenuCategoryById = (id: string) => ({
  type: MenuActionTypes.GET_MENU_CATEGORY_BY_ID,
  payload: id
})

export const getMenuCategoryByIdSuccess = (category: any) => ({
  type: MenuActionTypes.GET_MENU_CATEGORY_BY_ID_SUCCESS,
  payload: category
})

export const apiCallError = (error: any) => ({
  type: MenuActionTypes.API_RETURN_ERROR,
  payload: error
})

