import { OrderTypes } from "./actionTypes";


export const getOrderDetailById = (orderId: number) => ({
  type: OrderTypes.GET_ORDER_DETAIL_BY_ID,
  payload: {
    orderId
  }
});

export const getOrderDetailByIdSuccess = (orderDetail: any) => ({
  type: OrderTypes.GET_ORDER_DETAIL_BY_ID_SUCCESS,
  payload: orderDetail,
});
