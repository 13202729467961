import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useParams } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { Col, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { createSMSTemplate, updateSMSTemplate } from "src/store/sms/actions";

const EditTemplate = (props: any) => {
  const dispatch = useDispatch();
  const { creatingTemplate, creatingTemplateSuccess, updatingTemplate, updatingTemplateSuccess } = useSelector((state: any) => state.sms);

  useEffect(() => {
    if ((props.create && creatingTemplateSuccess) || (!props.create && updatingTemplateSuccess)) {
      props.onDrawerClose();
    }
  }, [creatingTemplateSuccess, updatingTemplateSuccess]);

  const handleSubmit = (values: any) => {
    console.log("props " + JSON.stringify(props))
    if (props.create) {
      dispatch(createSMSTemplate({message: values.Message, name: values.Name}));
    } else {
      dispatch(updateSMSTemplate({message: values.Message, name: values.Name, id: props.template.template_id}));
    }
  };


  return (
    <React.Fragment>
      <div className="add-company">
        <MetaTags>
          <title>Add New Admin</title>
        </MetaTags>
      </div>
      <ReactDrawer
        open={props.open}
        position={"right"}
        onClose={props.onDrawerClose}
      >
        <div className="add-admin-form" style={{ padding: 20 }}>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleSubmit(values);
            }}
          >
            <div className="add-company-form-cotainer">
              <Row>
                <Col md="12">
                  <h4 className="mt-4 mb-4">{props.create ? "Create" : "Edit"} template</h4>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <div className="mb-3">
                    <AvField
                      name="Name"
                      label="Name"
                      type="text"
                      errorMessage="Input template name"
                      validate={{
                        required: { value: true },
                      }}
                      value={props.template.template_name}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <div className="mb-3">
                    <AvField
                      name="Message"
                      label="Message"
                      type="textarea"
                      errorMessage="Input message"
                      validate={{
                        required: { value: true },
                      }}
                      style={{height: 150}}
                      value={props.template.body}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <div className="text-end">
                    <button type="submit" className="btn btn-success" disabled={(creatingTemplate || updatingTemplate)}>
                      {(creatingTemplate || updatingTemplate) ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : (
                        <>{props.create ? "Create" : "Update"} template</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </AvForm>
        </div>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withRouter(EditTemplate);
