export enum MenuDressingsActionTypes {
  GET_ALL_MENU_DRESSINGS = "GET_ALL_MENU_DRESSINGS",
  GET_ALL_MENU_DRESSINGS_SUCCESS = 'GET_ALL_MENU_DRESSINGS_SUCCESS',

  UPDATE_MENU_DRESSING_BY_ID = 'UPDATE_MENU_DRESSING_BY_ID',
  UPDATE_MENU_DRESSING_BY_ID_SUCCESS = 'UPDATE_MENU_DRESSING_BY_ID_SUCCESS',

  ADD_MENU_DRESSING = 'ADD_MENU_DRESSING',
  ADD_MENU_DRESSING_SUCCESS = 'ADD_MENU_DRESSING_SUCCESS',

  DELETE_MENU_DRESSING_BY_ID = 'DELETE_MENU_DRESSING_BY_ID',
  DELETE_MENU_DRESSING_BY_ID_SUCCESS = 'DELETE_MENU_DRESSING_BY_ID_SUCCESS',

  GET_MENU_DRESSING_BY_ID = 'GET_MENU_DRESSING_BY_ID',
  GET_MENU_DRESSING_BY_ID_SUCCESS = 'GET_MENU_DRESSING_BY_ID_SUCCESS',

  DRESSING_API_RETURN_ERROR = 'DRESSING_API_RETURN_ERROR'
}
