import { LoginTypes } from "./actionTypes";

export const setAuthUser = (user: any) => {
  return {
    type: LoginTypes.SET_AUTH_USER,
    payload: { user },
  };
}

export const adminLogin = (user: any, history: any) => {
  return {
    type: LoginTypes.LOGIN_ADMIN,
    payload: { user, history },
  };
};

export const loginSuccess = (user: any) => {
  return {
    type: LoginTypes.LOGIN_SUCCESS,
    payload: user,
  };
};

export const loginFail = (response: any) => {
  return {
    type: LoginTypes.LOGIN_FAIL,
    payload: response,
  };
}

export const logoutUser = (history: any) => {
  return {
    type: LoginTypes.LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = (response: any) => {
  return {
    type: LoginTypes.LOGOUT_USER_SUCCESS,
    payload: response,
  };
};

export const apiError = (error: any) => {
  return {
    type: LoginTypes.API_ERROR,
    payload: error,
  };
};

